import React from "react";
import { Avatar } from "antd";

const sizeMapping = {
  xs: 32,
  sm: 40,
  md: 50,
  xl: 110,
};

const ProfilePic = ({ size = "md", userId }) => {
  return (
    <Avatar
      src={`https://i.pravatar.cc/150?u=${userId}`}
      alt="User Avatar"
      size={sizeMapping[size] || sizeMapping["md"]}
    />
  );
};

export default ProfilePic;