import React, { useState, useEffect } from "react";
import { Tag, Button, Modal, Spin } from "antd";
import { DownloadOutlined, DeleteOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import DeleteVersionModal from "../../Services/DeleteModal/DeleteVersionModal";
import { useAuth } from "../../authcontext";

const VersionControlTab = ({ documentData, selectedVersion, onDelete, fetchVersionHistory, setDeleteRefreshTrigger }) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const { userId, vaultStatus } = useAuth();

  if (!documentData || !documentData.versions || documentData.versions.length === 0) {
    return <p className="text-[#768090]">No document data available.</p>;
  }

  if (!selectedVersion) {
    return <p className="text-[#768090]">No version selected.</p>;
  }

  const versionsWithNumbers = documentData.versions.map((version, index) => ({
    ...version,
    versionNumber: index + 1,
  }));

  const selectedVersionWithNumber = versionsWithNumbers.find(
    (version) => version.versionId === selectedVersion.versionId
  );

  const checkPermission = () => {
    if (!documentData) return false;
    if (documentData.userId === userId) return true;
    const sharedWithUser = documentData.sharedWith?.find(
      (shared) => shared.entityId === userId && shared.permissionType === "modify"
    );
    return !!sharedWithUser;
  };

  const handleDownload = async (version) => {
    setDownloading(true);
    try {
      const url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?versionId=${version.versionId}&fileName=${documentData.bucketKey}`;
      const sessionToken = localStorage.getItem("sessionToken");
      const vaultToken = localStorage.getItem("vaultToken");
      const headers = vaultStatus
        ? {
          Authorization: `Bearer ${sessionToken}`,
          vaultToken: vaultToken,
          secureVault: true,
        }
        : {
          Authorization: `Bearer ${sessionToken}`,
        };
      // const response = await fetch(url, { method: "GET", headers });
      // if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      // const blob = await response.blob();
      // const link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download = documentData.bucketKey;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      window.location.href = url;

    } catch (error) {
      console.error("Error downloading document:", error);
      Modal.error({ title: "Download Failed", content: "Error downloading the document. Please try again." });
    } finally {
      setTimeout(() => {
        setDownloading(false);
      }, 3000)
    }
  };

  const handleDeleteClick = (version) => {
    setVersionToDelete(version);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async (versionId) => {
    if (onDelete) {
      await onDelete(versionId);
      fetchVersionHistory();
      setDeleteRefreshTrigger((prev) => prev + 1);
      setDeleteModalVisible(false);
    } else {
      console.error("onDelete function is undefined");
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalVisible(false);
    setVersionToDelete(null);
  };

  const docBytes = selectedVersion.size || 0;
  const sizeInMB = docBytes / (1024 * 1024);
  const fileSize = sizeInMB < 1 ? `${(docBytes / 1024).toFixed(1)} KB` : `${sizeInMB.toFixed(1)} MB`;

  return (
    <>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]"><strong>File name:</strong> {documentData.docName}</p>
        <p className="text-[#768090]"><strong>Version No:</strong> {selectedVersionWithNumber?.versionNumber}</p>
      </div>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]"><strong>Uploaded:</strong> {new Date(selectedVersion.createdAt).toLocaleDateString()}</p>
        <p className="text-[#768090]"><strong>File Size:</strong> {fileSize}</p>
      </div>
      <hr className="my-4 border-t border-gray-300" />
      <div className="flex justify-between gap-3 w-full">
        <Button
          type="default"
          className="bg-[#D1F8F0] text-[#007768] border border-[#D1F8F0] px-4 py-2 rounded-lg hover:bg-[#eafaf4] flex items-center"
          onClick={() => handleDownload(selectedVersion)}
          disabled={downloading} // Disable button while downloading
        >
          {downloading ? (
            <Spin size="small" className="mr-2" />
          ) : (
            <CloudDownloadOutlined className="mr-2" />
          )}
          Download
        </Button>
        {checkPermission() && selectedVersionWithNumber?.versionNumber !== documentData.versions.length && (
          <button
            onClick={() => handleDeleteClick(selectedVersion)}
            className="mt-2 flex items-center h-5 p-[4px] text-[#FF412E] gap-2 bg-transparent focus:outline-none"
          >
            <DeleteOutlined style={{ fontSize: "16px" }} />
            <span className="text-[#FF412E] text-[14px] font-medium">Delete Version</span>
          </button>
        )}
      </div>
      {deleteModalVisible && (
        <DeleteVersionModal
          visible={deleteModalVisible}
          onClose={handleCloseDeleteModal}
          version={selectedVersion}
          onDelete={handleDeleteConfirm}
          docId={documentData._id}
          setDeleteRefreshTrigger={setDeleteRefreshTrigger}
        />
      )}
    </>
  );
};

export default VersionControlTab;
