

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "../../authcontext";
import DocCard from "../../Services/UI/DocCard";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import DefaultDocEmpty from '../../Services/UI/EmptyState/DefaultDocEmpty';
import DocLoading from "../../Services/UI/LoadingState/DocLoading";




const Default = ({ sortOrder, currentSortOption, filter, setFilter, refreshdocs }) => {
  const navigate = useNavigate();
  const { userId } = useAuth();
  const [activeTab, setActiveTab] = useState("receivedDocuments");
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [moreToCome, setMoreToCome] = useState(true);
  const loaderRef = useRef(null);
  const [currentStatus, setCurrentStatus] = useState({});

  // Handle tab switching
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setDocuments([]);
    setPage(0);
    setMoreToCome(true);
    
    if (setFilter) {
      setFilter(""); // Reset filter when tab changes
    }
  };
  

  // Fetch documents from the API
  const fetchDocuments = async (defaultType, currentPage) => {
    try {
      setLoading(true);
      let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/?defaultType=${defaultType}`;

      if (currentPage > 0) {
        url += `&page=${currentPage}`;
      }
      if (currentSortOption) {
        url += `&sortBy=${currentSortOption}&sortOrder=${sortOrder}`;
      }
      if ((filter === 'a' || filter === 'b' || filter === 'c') && filter) {
        url += `&filterValue=${filter}&filterOn=docSize`;
      } else if (filter) {
        url += `&filterValue=${filter}&filterOn=docType`;
      }









   // Fetch documents from the API
  //  const fetchDocuments = async (defaultType, currentPage) => {
  //   try {
  //     setLoading(true);
  //     let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/?defaultType=${defaultType}`;

  //     if (currentPage > 0) {
  //       url += `&page=${currentPage}`;
  //     }
  //     if (currentSortOption) {
  //       url += `&sortBy=${currentSortOption}&sortOrder=${sortOrder}`;
  //     }
  //     if (filter) {
  //       url += `&filterValue=${filter}&filterOn=docType`;
  //     }


      const response = await axios.get(url);
      const fetchedDocuments = response.data.data?.finalList || [];

      // Update bookmark statuses
      const bookmarkStatuses = fetchedDocuments.reduce((acc, doc) => {
        if (doc && doc._id) {
          acc[doc._id] = doc.bookmarkStatus;
        }
        return acc;
      }, {});

      // Append new documents to the existing list
      if (currentPage === 0) {
        setDocuments(fetchedDocuments);
      } else {
        setDocuments((prevDocuments) => [...prevDocuments, ...fetchedDocuments]);
      }

      setMoreToCome(response.data.data?.moreToCome || false);
      setCurrentStatus((prevStatus) => ({ ...prevStatus, ...bookmarkStatuses }));
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to load documents. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle pin toggle (bookmarking)
  const handlePinToggle = async (docId) => {
    try {
      const newStatus = !currentStatus[docId];
      setCurrentStatus((prevStatus) => ({
        ...prevStatus,
        [docId]: newStatus,
      }));

      await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}&userId=${userId}`
      );

      message.success(
        newStatus
          ? "Document has been successfully pinned!"
          : "Document has been successfully unpinned!"
      );
    } catch (error) {
      console.error("Error toggling pin status:", error);
      message.error("Failed to update the document pin status.");
    }
  };

  // Navigate to the document's versions page
  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  // Infinite scrolling logic
  useEffect(() => {
    if (!loaderRef.current || loading || !moreToCome) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && moreToCome) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [loading, moreToCome]);

  // Fetch documents on tab or sort/filter changes
  useEffect(() => {
    let defaultType = "own";
    if (activeTab === "teamSharedDocuments") {
      defaultType = "team";
    } else if (activeTab === "receivedDocuments") {
      defaultType = "org";
    }

    setDocuments([]);
    fetchDocuments(defaultType, 0);
  }, [activeTab, sortOrder, currentSortOption, filter, refreshdocs]);

  // Fetch more documents when the page changes
  useEffect(() => {
    if (page > 0) {
      let defaultType = "own";
      if (activeTab === "teamSharedDocuments") {
        defaultType = "team";
      } else if (activeTab === "receivedDocuments") {
        defaultType = "org";
      }

      fetchDocuments(defaultType, page);
    }
  }, [page]);

  return (
    <div className="flex flex-col px-4">
      {/* Tabs for navigation */}
      <div className="flex justify-start space-x-8">
        <button
          className={`text-lg font-semibold ${
            activeTab === "receivedDocuments" ? "text-teal-700" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("receivedDocuments")}
        >
          Received Documents
          {activeTab === "receivedDocuments" && (
            <div className="border-b-2 border-teal-600 mt-2"></div>
          )}
        </button>

        <button
          className={`text-lg font-semibold ${
            activeTab === "teamSharedDocuments" ? "text-teal-700" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("teamSharedDocuments")}
        >
          Team Shared Documents
          {activeTab === "teamSharedDocuments" && (
            <div className="border-b-2 border-teal-600 mt-2"></div>
          )}
        </button>

        <button
          className={`text-lg font-semibold ${
            activeTab === "myUploadedFiles" ? "text-teal-700" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("myUploadedFiles")}
        >
          My Uploaded Files
          {activeTab === "myUploadedFiles" && (
            <div className="border-b-2 border-teal-600 mt-2"></div>
          )}
        </button>
      </div>

      {/* Documents content */}
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-4">
          {loading && documents.length === 0 ? (
            <DocLoading />
          ) : documents.length > 0 ? (
            documents.map((doc) => {
              if (!doc) return null; // Skip undefined documents
              return (
                <DocCard
                  key={doc._id}
                  doc={doc}
                  handleDocumentClick={handleDocumentClick}
                  handlePinToggle={() => handlePinToggle(doc._id)}
                />
              );
            })
          ) : (
            !loading && <DefaultDocEmpty />
          )}
        </div>

        {/* Infinite scroll loader */}
        {loading && documents.length > 0 && <DocLoading />}
        {!loading && moreToCome && <div ref={loaderRef} className="loader">Loading more documents...</div>}
      </div>
    </div>
  );
};

export default Default;