
import React from "react";
import { Modal, Button, Typography, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../authcontext";

const { Text, Title } = Typography;

const DeleteVersionModal = ({ visible, onClose, version, onDelete, docId, setDeleteRefreshTrigger  }) => {
  const {userId, vaultStatus  }=useAuth();
  const sessionToken = localStorage.getItem("sessionToken");
  const vaultToken = localStorage.getItem("vaultToken");

  const headers = {
    Authorization: `Bearer ${sessionToken}`,
    "Content-Type": "multipart/form-data",
    ...(vaultStatus && { vaultToken, secureVault: true })
  };

  const handleDelete = async () => {
    try {
      const sessionToken = localStorage.getItem("sessionToken");
  
      const response = await fetch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}?versionId=${version.versionId}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json(); // Extract error response JSON
        throw new Error(errorData.error?.message || "Failed to delete version");
      }


      message.success("Version deleted successfully!");
  
      onDelete(version.versionId); // Notify parent component
      setDeleteRefreshTrigger((prev) => prev + 1);
      onClose(); // Close modal
    } catch (error) {
      console.error("Error deleting version:", error);
  
      if (error.message === "active-version-cant-be-deleted") {
        message.error("Active version cannot be deleted."); // User-friendly message
      } else {
        message.error("An error occurred while deleting the version.");
      }
    }
  };
  

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={600}
    >
      <div style={{ textAlign: "center", padding: "24px" }}>
        <DeleteOutlined style={{ fontSize: "48px", color: "#FF4D4F" }} />
           <Typography.Title level={4} style={{ margin: "16px 0", color: "#59616E" }}>
          Delete
        </Typography.Title>
        <Text>
          {/* Are you sure you want to delete version? <strong>{version.versionId}</strong>  <strong>{docId}</strong>? */}
          Are you sure you want to delete version?

          <br />
          This action will permanently delete this version of the document. Other versions will remain intact. This cannot be undone.        </Text>
        <div className="flex gap-4 mt-4">
        <Button
          onClick={onClose}
          className="
            flex 
            w-[318px] 
            h-[40px] 
            px-4 
            justify-center 
            items-center 
            gap-2 
            bg-white 
            border border-[#CAD4DD] 
           rounded-lg 
            text-[#1B2028] 
            focus:outline-none 
            focus:ring-2 
            focus:ring-gray-400
          "
        >
          Close
        </Button>

        <Button
          type="primary"
          danger
          onClick={handleDelete}
          className="
            flex 
            w-[318px] 
            h-[40px] 
            px-4 
            flex-col 
            justify-center 
            items-center 
            gap-2 
            bg-[#FF412E] 
          
            rounded-lg 
            focus:outline-none 
           
            focus:ring-2 
            focus:ring-red-300
          "
        >
          Delete
        </Button>
      </div>
      </div>
    </Modal>
  );
};

export default DeleteVersionModal;