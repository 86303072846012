import React, { useEffect, useState } from "react";
import TrashDocCard from "../../Services/UI/TrashDocCard";
import axios from "axios";
import DeleteEmptyTrashModal from "../../Services/DeleteModal/DeleteEmptyTrashModal";
import RestoreTrashDocumentModal from "../../Services/EditModal/RestoreTrashDocumentModal";
import { message, Spin } from "antd";
import DocLoading from "../../Services/UI/LoadingState/DocLoading";


const TrashAllDocs = () => {


  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trashDocumentList, setTrashDocumentList] = useState([]);
  const [moreTrashDocToCome, setMoreTrashDocToCome] = useState(false);
  const [trashPage, setTrashPage] = useState(1);
  const [selectedDocListIDs, setSelectedDocListIDs] = useState([]);
  console.log({ selectedDocListIDs });


  const [selectedDELETEDocList, setSelectedDELETEDocList] = useState([]);
  const [selectedRESTOREDocList, setSelectedRESTOREDocList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);


  const [triggerNextTrashDoc, setTriggerNextTrashDoc] = useState(0);


  useEffect(() => {
    fetchTrashDocumentList();
  }, []);


  useEffect(() => {
    fetchMoreTrashDocumentList();
  }, [triggerNextTrashDoc]);


  useEffect(() => {
    if (selectedDELETEDocList.length > 0) {
      deleteTrashDocumentList();
    }
  }, [selectedDELETEDocList]);

  useEffect(() => {
    if (selectedRESTOREDocList.length > 0) {
      restoreTrashDocumentList();
    }
  }, [selectedRESTOREDocList]);

  const fetchTrashDocumentList = async () => {
    try {
      setInitialLoading(true);
      setTrashPage(1)
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list?trash=true`
      );

      const formattedDocuments = response.data.data.finalList.map((doc) => ({
        ...doc,
        uploadedAt: new Date(doc.uploadedAt).toLocaleDateString("en-GB"),
        updatedAt: new Date(doc.updatedAt).toLocaleDateString("en-GB"),
        docSizeFormatted: formatSize(doc.docSize),
      }));

      setTrashDocumentList(formattedDocuments);
      setMoreTrashDocToCome(response.data.data.moreToCome);
    } catch (error) {
      console.error("Error fetching trash document list:", error);
    } finally {
      setInitialLoading(false);
    }
  };

  const fetchMoreTrashDocumentList = async () => {
    try {
      if (moreTrashDocToCome) {
        setMoreTrashDocToCome(false);
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list?trash=true&page=${trashPage}`
        );

        const formattedDocuments = response.data.data.finalList.map((doc) => ({
          ...doc,
          uploadedAt: new Date(doc.uploadedAt).toLocaleDateString("en-GB"),
          updatedAt: new Date(doc.updatedAt).toLocaleDateString("en-GB"),
          docSizeFormatted: formatSize(doc.docSize),
        }));

        setTrashDocumentList((prevDocs) => [...prevDocs, ...formattedDocuments]);
        setMoreTrashDocToCome(response.data.data.moreToCome);
        setTrashPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching more trash documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteTrashDocumentList = async () => {
    try {
      setLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/removeDocuments`,
        {
          data: { documentIds: selectedDELETEDocList },
        }
      );

      setTrashDocumentList((prevList) =>
        prevList.filter((doc) => !selectedDELETEDocList.includes(doc._id))
      );
      setSelectedDELETEDocList([]);
      setSelectedDocListIDs([]);

      message.success("Documents deleted successfully!");
    } catch (error) {
      console.error("Error deleting trash document list:", error);
      message.error("Failed to delete documents.");
    } finally {
      setLoading(false);
    }
  };

  const restoreTrashDocumentList = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/recoverDocuments`,
        { documentIds: selectedRESTOREDocList },
      );

      setTrashDocumentList((prevList) =>
        prevList.filter((doc) => !selectedRESTOREDocList.includes(doc._id))
      );
      setSelectedRESTOREDocList([]);
      setSelectedDocListIDs([]);

      message.success("Documents restored successfully!");
    } catch (error) {
      console.error("Error restoring trash document list:", error);
      message.error("Failed to restore documents.");
    } finally {
      setLoading(false);
    }
  };


  const formatSize = (sizeInBytes) => {
    const sizeInMb = sizeInBytes / (1024 * 1024);
    if (sizeInMb >= 1) {
      return `${sizeInMb.toFixed(2)} MB`;
    } else {
      const sizeInKb = sizeInBytes / 1024;
      return `${sizeInKb.toFixed(2)} KB`;
    }
  };

  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };

  const handleConfirmDelete = () => {
    setSelectedDELETEDocList(selectedDocListIDs);
    setIsModalVisible(false);
  };

  const handleRestoreClick = () => {
    setIsRestoreModalVisible(true);
  };

  const handleConfirmRestore = () => {
    setSelectedRESTOREDocList(selectedDocListIDs);
    setIsRestoreModalVisible(false);
  };

  return (
    <div className="p-8">
      {/* Page Title & Select All Section */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-[#192028] leading-[32px]">
          Trash
        </h1>

        {selectedDocListIDs.length > 0 && (
          <div className="flex items-center">
            <input
              type="checkbox"
              id="selectAll"
              className="w-4 h-4 text-teal-500 border-gray-300 rounded focus:ring-teal-500 cursor-pointer"
              onChange={() => setSelectedDocListIDs([])}
              checked={true}
            />
            <label
              htmlFor="selectAll"
              className="ml-2 text-[16px] font-normal leading-[24px] text-[#1B2028] cursor-pointer"
            >
              Unselect All ({selectedDocListIDs.length}/10)
            </label>
          </div>
        )}

      </div>

      {loading &&
        // <DocLoading />
        // <CircularProgress size={24} />
        // <Spin size="large" />
        <div className="fixed inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-50">
          <Spin size="large" />
        </div>
      }



      {/* Trash Cards */}

      {initialLoading ?
        <DocLoading />
        :
        <TrashDocCard
          trashDocumentList={trashDocumentList}
          setTriggerNextTrashDoc={setTriggerNextTrashDoc}
          selectedDocListIDs={selectedDocListIDs}
          setSelectedDocListIDs={setSelectedDocListIDs}
          setSelectedDELETEDocList={setSelectedDELETEDocList}
          setSelectedRESTOREDocList={setSelectedRESTOREDocList}
        />
      }


      {/* Buttons Section */}

      {trashDocumentList.length > 0 &&
        <>
          <div className="flex justify-end gap-4 mt-6">
            <button
              className="flex items-center justify-center w-[140px] h-[40px] bg-[#41EAD4] text-[#192028] rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleDeleteClick()}
              disabled={selectedDocListIDs.length === 0}
            >
              Empty Selected
            </button>

            <button
              className="flex items-center justify-center w-[140px] h-[40px] bg-white text-[#1B2028] border border-[#CAD4DD] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleRestoreClick()}
              disabled={selectedDocListIDs.length === 0}
            >
              Restore Selected
            </button>

            <DeleteEmptyTrashModal
              visible={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              onConfirmDelete={handleConfirmDelete}
            />

            <RestoreTrashDocumentModal
              visible={isRestoreModalVisible}
              onClose={() => setIsRestoreModalVisible(false)}
              onConfirmRestore={handleConfirmRestore}
            />


          </div>
        </>}

    </div>
  );
};

export default TrashAllDocs;
