import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Skeleton, Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProfileDetail from './ProfileDetail';
import LeaveTeamModal from '../../../Services/DeleteModal/LeaveTeamModal';
import Teams from '../../../Screens/TeamsReplica';
import ProfilePic from '../../../Services/UI/ProfilePic';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';


const TeamDetails = ({ team, memberIds, managerId, coManagerIds, teamId, type, teamName, onLeaveTeam }) => {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [hasLeftTeam, setHasLeftTeam] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();
  const [coManagerIdsState, setcoManagerIdsState] = useState(coManagerIds);

  console.log("coManagerIds:", coManagerIds);


  const [knowTeamData, setKnowTeamData] = useState({
    managerId: null,
    coManagerIds: [],
    teamId: null,
  });
  


  useEffect(() => {
    const fetchMembers = async () => {
      setcoManagerIdsState(coManagerIds)
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/teamMembers`,
          { memberIds: [...memberIds, ...coManagerIdsState] } // Include co-manager IDs in the request
        );
        const fetchedMembers = response.data.data.memberDetails;
        setMembers(fetchedMembers);
        setFilteredMembers(fetchedMembers);
        console.log(fetchedMembers);
        // Update KnowTeam data
        setKnowTeamData({
          managerId: managerId,
          coManagerIds: coManagerIdsState,
          teamId: teamId,
        });
      } catch (error) {
        console.error("Error fetching members:", error);
      } finally {
        setLoading(false);
      }
    };
  

    if (memberIds?.length) {
      fetchMembers();
    }
  }, [memberIds]);

 
  useEffect(() => {
    let filtered = members;

    if (filterType === 'manager') {
      filtered = members.filter((member) => member._id === managerId);
    } else if (filterType === 'coManager') {
      filtered = members.filter((member) => coManagerIds?.includes(member._id)); // Added nullish coalescing operator
    } else if (filterType === 'member') {
      filtered = members.filter(
        (member) => member._id !== managerId && !coManagerIds?.includes(member._id) // Added nullish coalescing operator
      );
    }

    if (searchQuery) {
      filtered = filtered.filter((member) =>
        `${member.name} ${member.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredMembers(filtered);
  }, [members, filterType, searchQuery, managerId, coManagerIds]);


  const handleProfileClick = (member) => {
    setSelectedMember(member);
    setShowProfile(true);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
    setSelectedMember(null);
  };

  const handleLeaveTeam = () => {
    setIsLeaveModalVisible(true);
  };

  const closeLeaveModal = () => {
    setIsLeaveModalVisible(false);
  };
  const renderBadge = (memberId) => {
    if (memberId === managerId) {
      return (
        <span className="flex items-center justify-center w-[63px] h-[20px] bg-[#D1F8F0] text-[#1B2028] text-[12px] font-semibold rounded-[6px]">
          Manager
        </span>
      );
    }
    if (coManagerIdsState.includes(memberId)) {
      return (
        <span className="flex items-center justify-center w-[85px] h-[20px] bg-[#D1F8F0] text-[#1B2028] text-[12px] font-semibold rounded-[6px]">
          Co-Manager
        </span>
      );
    }
    return null;
  };
  
  return hasLeftTeam ? (
    <Teams />
  ) : (
    <div className="relative w-full">
      {/* Header */}
      <div className="flex items-center mb-4">
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => setHasLeftTeam(true)}
          style={{ marginRight: '12px' }}
        >
          Back
        </Button>
      </div>
      <div className="flex justify-between items-center p-6 mb-6">
        <h2 className="text-xl font-semibold" style={{ fontSize: '20px', fontWeight: 600, lineHeight: '28px' }}>
          <span style={{ color: '#768090' }}>{type} Teams</span> /{' '}
          <span style={{ color: '#192028' }}>{teamName}</span>
          <br />
          <span style={{ color: '#192028', marginTop: '12px', display: 'block' }}>
            Know your Team
          </span>
          <span
            style={{
              color: '#192028',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              marginBottom: '4px',
              display: 'block',
            }}
          >
            Get to know your team's strengths and weaknesses.
          </span>
        </h2>
        {type === 'joined' && (
          <button
            className="flex items-center justify-center bg-white text-red-500 border border-red-300 py-2 px-4 rounded-md"
            onClick={handleLeaveTeam}
          >
            <span className="mr-1">—</span> Leave
          </button>
        )}
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-5 gap-6">
        {/* Left Section: Know Your Team */}
        <div className="col-span-2 w-[458px]">
          {loading ? (
            [...Array(4)].map((_, index) => (
              <div key={index} className="flex flex-col items-center gap-2">
                <Skeleton.Avatar active size={100} />
                <Skeleton.Input active size="small" style={{ width: 100 }} />
              </div>
            ))
          ) : (
            <div
              className="grid grid-cols-2 gap-6 p-4 rounded-lg bg-white shadow-md overflow-y-auto"
              style={{
                width: '100%',
                maxHeight: '400px',
                padding: '12px',
                borderRadius: '8px',
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
              }}
            >
              {filteredMembers.map((member, index) => (
  <div
    key={index}
    className="flex flex-col items-center gap-2"
    onClick={() => handleProfileClick(member)}
  >
    {member.avatar ? (
      <img
        src={member.avatar}
        alt={member.name}
        className="cursor-pointer rounded-full border-4 border-teal-300"
        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
      />
    ) : (
      <ProfilePic name={member.name} size="xl" userId={member._id}/>
    )}
    <p className="text-center text-gray-900 font-semibold">{member.name}</p>
    <span className="text-center text-gray-600 text-sm">
        {member._id === managerId
          ? 'Manager'
          : coManagerIdsState.includes(member._id)
          ? 'Co-Manager'
          : 'Member'}
      </span>
  </div>
))}

            </div>
          )}
        </div>

        {/* Right Section: Team Members */}
        <div className="col-span-3 flex flex-col max-w-[962px] h-auto p-4 bg-white rounded-lg shadow-md gap-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-[20px] font-semibold leading-[28px] text-[#192028]">Team Members</h2>
            <div className="flex items-center gap-4">
              <div className="flex items-center w-80 h-8 px-3 gap-2 rounded-md border border-gray-300 bg-white">
                <SearchOutlined style={{ color: '#AEB8CA', fontSize: '16px' }} />
                <input
                  type="text"
                  placeholder="Search for team member"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-transparent text-gray-500 outline-none placeholder-gray-400"
                />
              </div>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="all"
                      style={{
                        padding: '8px 16px',
                        backgroundColor: hoveredItem === 'all' ? '#D1F8F0' : '#FFF',
                        color: hoveredItem === 'all' ? '#1B2028' : '#192028',
                      }}
                      onMouseEnter={() => setHoveredItem('all')}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType('all')}
                    >
                      All
                    </Menu.Item>
                    <Menu.Item
                      key="manager"
                      style={{
                        padding: '8px 16px',
                        backgroundColor: hoveredItem === 'manager' ? '#D1F8F0' : '#FFF',
                        color: hoveredItem === 'manager' ? '#1B2028' : '#192028',
                      }}
                      onMouseEnter={() => setHoveredItem('manager')}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType('manager')}
                    >
                      Manager
                    </Menu.Item>
                    <Menu.Item
                      key="coManager"
                      style={{
                        padding: '8px 16px',
                        backgroundColor: hoveredItem === 'coManager' ? '#D1F8F0' : '#FFF',
                        color: hoveredItem === 'coManager' ? '#1B2028' : '#192028',
                      }}
                      onMouseEnter={() => setHoveredItem('coManager')}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType('coManager')}
                    >
                      Co-Manager
                    </Menu.Item>
                    <Menu.Item
                      key="member"
                      style={{
                        padding: '8px 16px',
                        backgroundColor: hoveredItem === 'member' ? '#D1F8F0' : '#FFF',
                        color: hoveredItem === 'member' ? '#1B2028' : '#192028',
                      }}
                      onMouseEnter={() => setHoveredItem('member')}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => setFilterType('member')}
                    >
                      Member
                    </Menu.Item>
                  </Menu>
                }
              >
                <button className="flex items-center gap-2 border px-4 py-2 rounded-lg">
                  Filter <FilterOutlined />
                </button>
              </Dropdown>
            </div>
          </div>

          <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
            {loading ? (
              [...Array(4)].map((_, index) => (
                <div key={index} className="flex items-center gap-3 py-3">
                  <Skeleton.Avatar active size={40} />
                  <Skeleton.Input active size="small" style={{ width: 100 }} />
                  <Skeleton.Input active size="small" style={{ width: 150 }} />
                </div>
              ))
            ) : (
              <table className="min-w-full bg-white">
                <thead className="bg-[#F8F9FB]">
                  <tr>
                    <th className="text-left py-3 px-4">Name</th>
                    <th className="text-left py-3 px-4">Email</th>
                    <th className="text-left py-3 px-4">Department</th>
                  </tr>
                </thead>
                <tbody>
  {filteredMembers.map((member, index) => (
    <tr key={index}>
      <td className="py-3 px-4 flex items-center gap-2">
        {member.avatar ? (
          <img
            src={member.avatar}
            alt={member.name}
            className="w-10 h-10 rounded-full cursor-pointer"
            style={{ objectFit: 'cover' }}
            onClick={() => handleProfileClick(member)}
          />
        ) : (
          <ProfilePic name={member.name} size="sm" />
        )}
        <div>
          <span
            onClick={() => handleProfileClick(member)}
            className="cursor-pointer text-[#192028] font-medium hover:underline"
          >
            {member.name}
          </span>
          {renderBadge(member._id)}
        </div>
      </td>
      <td className="py-3 px-4 text-[14px] font-normal leading-[22px] text-[#1B2028]">
        {member.email}
      </td>
      <td className="py-3 px-4">{member?.userDetails?.department || 'N/A'}</td>
    </tr>
  ))}
</tbody>


              </table>
            )}
          </div>
        </div>
      </div>

      <LeaveTeamModal
        visible={isLeaveModalVisible}
        onCancel={closeLeaveModal}
        onConfirm={async () => {
          try {
            if (onLeaveTeam) {
              await onLeaveTeam(teamId);
            }
            setHasLeftTeam(true); // Update state to render Teams component
          } catch (error) {
            console.error('Error leaving team:', error);
          }
        }}
        teamId={teamId}
      />

      {/* Profile Slide Panel */}
      {showProfile && (
        <div className="fixed inset-0 bg-black bg-opacity-25 z-10" onClick={handleCloseProfile}>
          <div className="absolute top-0 right-0 z-20 transform transition-transform duration-300 ease-in-out p-4 w-80 h-full">
            <ProfileDetail profile={selectedMember} onClose={handleCloseProfile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamDetails;