import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ArrowRightOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import ProfilePic from '../../../Services/UI/ProfilePic';

const ProfileDetail = ({ profile, onClose, teamId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [commonTeams, setCommonTeams] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);

  // Fetch Common Teams API Call
  useEffect(() => {
    const fetchCommonTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/commonTeams?peerId=${profile._id}`
        );
        setCommonTeams(response.data.data);
      } catch (error) {
        console.error('Error fetching common teams:', error);
      } finally {
        setLoadingTeams(false);
      }
    };

    if (profile._id) {
      fetchCommonTeams();
    }

    setTimeout(() => setIsVisible(true), 10);
  }, [profile._id]);

  useEffect(() => {
    let isMounted = true;
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?assignee=${profile._id}&team=${teamId}`
        );
        if (isMounted) {
          setTasks(response.data.data.tasks);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error.message);
      } finally {
        if (isMounted) {
          setLoadingTasks(false);
        }
      }
    };

    if (profile._id) {
      fetchTasks();
    }

    return () => {
      isMounted = false;
    };
  }, [teamId, profile._id]);

  return (
    <div
      className={`fixed top-16 right-0 h-[calc(100vh-64px)] transform transition-transform duration-500 z-50 ${
        isVisible ? 'translate-x-0' : 'translate-x-full'
      } flex flex-col items-center max-w-[480px] bg-[#F5FFFD] p-5 gap-6 shadow-lg overflow-y-auto`}
      style={{ height: 'calc(100vh - 64px)' }}
    >
      {/* Close and More Options */}
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="text-2xl font-semibold text-black">User Profile</h1>
        <button onClick={onClose} className="text-lg text-black">
          <ArrowRightOutlined />
        </button>
      </div>

      {/* Profile Section */}
      <div className="w-full bg-white p-6 rounded-lg shadow-md flex flex-col gap-8">
        <div className="flex flex-col items-center text-center">
          {/* Profile Picture */}
          <ProfilePic name={profile.name} size="xl" userId={profile._id}/>

          {/* User Name */}
          <h2 className="text-xl font-semibold text-[#192028]">{profile.name}</h2>
          <p className="text-gray-500">Department: {profile?.userDetails?.department}</p>

          {/* Contact Information */}
          <div className="flex justify-center items-center space-x-4 text-sm text-gray-700 mt-4">
            <div className="flex items-center gap-1">
              <MailOutlined className="text-[#4ED2BF]" />
              <span>{profile.email}</span>
            </div>
            <div className="flex items-center gap-1">
              <PhoneOutlined className="text-[#4ED2BF]" />
              <span>{profile?.userDetails?.phoneNumber}</span>
            </div>
          </div>
        </div>

        <hr className="w-full border-t border-gray-300 mt-4" />

        {/* Common Teams Section */}
        <div className="w-full">
          <h3 className="text-lg font-semibold text-[#192028]">Common Teams</h3>
          {loadingTeams ? (
            <p className="text-gray-500 mt-2">Loading...</p>
          ) : commonTeams.length > 0 ? (
            commonTeams.map((team, index) => (
              <div key={team._id}>
                <div className="flex justify-between items-center w-full py-4">
                  <div>
                    <p className="text-sm font-semibold text-[#192028]">{team.name}</p>
                    <p className="text-xs text-gray-500">Created by Sunil.S.N</p>
                  </div>
                  <div className="flex -space-x-1">
                    {team.members.slice(0, 3).map((member, idx) => (
                      <ProfilePic
                        key={idx}
                        name={member.name}
                        size="xs"
                      />
                    ))}
                    {team.members.length > 3 && (
                      <span className="bg-gray-200 text-[#192028] rounded-full w-6 h-6 flex items-center justify-center">
                        +{team.members.length - 3}
                      </span>
                    )}
                  </div>
                </div>
                {index < commonTeams.length - 1 && <hr className="border-t border-gray-200 w-full" />}
              </div>
            ))
          ) : (
            <p className="text-gray-500 mt-2">No common teams available.</p>
          )}
        </div>

        {/* Task Section */}
        <div>
          <h2 className="text-lg font-semibold text-[#192028]">Task List</h2>
          {loadingTasks ? (
            <p className="text-gray-500">Loading tasks...</p>
          ) : tasks.length > 0 ? (
            <ul className="list-disc ml-5">
              {tasks.map((task) => (
                <li key={task._id} className="text-sm text-[#192028]">{task.name}</li>
              ))}
            </ul>
          ) : (
            <div>No tasks found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
