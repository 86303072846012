
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

// const DocViewerComponent = ({ selectedVersion, documentInfo }) => {
//   const [docs, setDocs] = useState([]);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (selectedVersion && documentInfo) {
//       const fetchDocument = async () => {
//         try {
//           const response = await axios.get(
//             `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`,
//             { responseType: "blob" }
//           );
//           const file = new Blob([response.data], {
//             type: documentInfo.docType,
//           });
//           const url = URL.createObjectURL(file);
//           setDocs([{ uri: url }]);
//         } catch (error) {
//           setError(error.message);
//         }
//       };

//       fetchDocument();
//     }
//   }, [selectedVersion, documentInfo]);

//   const isOffice = (docType) => {
//     const officeFileTypes = [
//       "application/msword",
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//       "application/vnd.ms-excel",
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       "application/vnd.ms-powerpoint",
//       "application/vnd.openxmlformats-officedocument.presentationml.presentation",
//     ];
//     return officeFileTypes.includes(docType);
//   };

//   const isSVG = (docType) => {
//     return docType === "image/svg+xml";
//   };

//   return (
//     <div>
//       {documentInfo && isOffice(documentInfo.docType) ? (
//         <iframe
//           src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
//             `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`
//           )}`}
//           width="100%"
//           height="600px"
//           style={{ border: "none" }}
//           title="Office Document Viewer"
//         />
//       ) : isSVG(documentInfo.docType) ? (
//         <object
//           data={`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`}
//           type="image/svg+xml"
//           width="100%"
//           height="600px"
//         >
//           Your browser does not support SVGs.
//         </object>
//       ) : (
//         <DocViewer
//           documents={docs}
//           pluginRenderers={DocViewerRenderers}
//           prefetchMethod="GET"
//           style={{ height: 600 }}
//         />
//       )}
//       {error && <div className="text-red-600">Error: {error}</div>}
//     </div>
//   );
// };

// export default DocViewerComponent;





import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPictureViewer from "react-picture-viewer";

const DocViewerComponent = ({ selectedVersion, documentInfo }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedVersion && documentInfo) {
      const fetchDocument = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`,
            { responseType: "blob" }
          );
          const file = new Blob([response.data], { type: documentInfo.docType });
          setFileUrl(URL.createObjectURL(file));
        } catch (error) {
          setError(error.message);
        }
      };

      fetchDocument();
    }
  }, [selectedVersion, documentInfo]);

  const isOfficeFile = (docType) => {
    const officeTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    return officeTypes.includes(docType);
  };

  const isImage = (docType) => docType.startsWith("image/") && docType !== "image/svg+xml";
  const isSVG = (docType) => docType === "image/svg+xml";
  const isPDF = (docType) => docType === "application/pdf";
  const isText = (docType) => docType.startsWith("text/");

  return (
    <div>
      {error && <div className="text-red-600">Error: {error}</div>}
      {fileUrl && (
        <>
          {/* Office Files (Word, Excel, PowerPoint) */}
          {isOfficeFile(documentInfo.docType) ? (
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`
              )}`}
              width="100%"
              height="600px"
              style={{ border: "none" }}
              title="Office Document Viewer"
            />
          ) : isSVG(documentInfo.docType) ? (
            /* SVG Viewer */
            <object
              data={fileUrl}
              type="image/svg+xml"
              width="100%"
              height="600px"
            >
              Your browser does not support SVGs.
            </object>
          ) : isImage(documentInfo.docType) ? (
            /* Image Viewer */
            <div style={{ width: "100%", height: "600px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ReactPictureViewer contain center width="100%" height="100%">
                <img
                  src={fileUrl}
                  alt="Document Preview"
                  draggable="false"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </ReactPictureViewer>
            </div>
          ) : isPDF(documentInfo.docType) ? (
            /* PDF Viewer */
            <iframe
              src={fileUrl}
              width="100%"
              height="600px"
              style={{ border: "none" }}
              title="PDF Viewer"
            />
          ) : isText(documentInfo.docType) ? (
            /* Text Viewer */
            <iframe
              src={fileUrl}
              width="100%"
              height="600px"
              style={{ border: "none" }}
              title="Text Viewer"
            />
          ) : (
            /* Fallback for unsupported files */
            <p>Unsupported file type</p>
          )}
        </>
      )}
    </div>
  );
};

export default DocViewerComponent;
