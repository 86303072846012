import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import NoDataSvg from "../../../assets/SVG/RecentModified.svg";
import { useAuth } from "../../authcontext";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";

const VersionHistory = ({ onSelectVersion, refreshTrigger, deleteRefreshTrigger }) => {
  const { docId } = useParams();
  const { userId } = useAuth();
  const { vaultStatus } = useAuth();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const columns = [
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>Version History</span>,
      dataIndex: "version",
      key: "version",
    },
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>Owner</span>,
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>Date</span>,
      dataIndex: "date",
      key: "date",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>File Size</span>,
      dataIndex: "fileSize",
      key: "fileSize",
      render: (text) => {
        const sizeInMB = text / 1024 / 1024;
        return sizeInMB < 1
          ? `${(text / 1024).toFixed(1)} KB`
          : `${sizeInMB.toFixed(1)} MB`;
      },
    },
  ];

  useEffect(() => {
    const fetchVersionHistory = async () => {
      setLoading(true);
      try {
        const sessionToken = localStorage.getItem("sessionToken");
        const vaultToken = localStorage.getItem("vaultToken");

        const headers = vaultStatus
          ? {
              Authorization: `Bearer ${sessionToken}`,
              vaultToken: vaultToken,
              secureVault: true,
            }
          : {
              Authorization: `Bearer ${sessionToken}`,
            };

        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
          { headers }
        );

        const documentData = response.data.data;

        const versionHistory = documentData.versions.map((version, index) => ({
          key: version.versionId,
          version: `Version ${index + 1}`,
          owner: version.createdBy.name,
          date: version.createdAt,
          fileSize: version.size,
          versionId: version.versionId,
        })).reverse();

        setData(versionHistory);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch version history");
        setLoading(false);
      }
    };

    if (docId) {
      fetchVersionHistory();
    }
  }, [docId, userId, refreshTrigger, deleteRefreshTrigger]);

  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{
        width: "380px",
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        padding: "16px",
      }}
    >
      {error && (
        <Alert severity="error" style={{ marginBottom: "10px" }}>
          {error}
        </Alert>
      )}

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        onRow={(record) => ({
          onClick: () => onSelectVersion(record.versionId),
        })}
        scroll={{ y: 240 }}
        style={{
          width: "100%",
          border: "none",
          fontSize: "12px", // Decreased font size for table data
        }}
        locale={{
          emptyText: (
            <div className="flex flex-col justify-center items-center">
              <img src={NoDataSvg} alt="No Data" />
              <p className="text-center text-[#192028] font-semibold mt-4" style={{ fontSize: "14px" }}>
                No Version History Available
              </p>
              <p className="text-center text-[#768090]" style={{ fontSize: "12px" }}>
                Version history will appear here once the document is updated.
              </p>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default VersionHistory;