import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { useAuth } from '../../authcontext';
import FoldersSvg from '../../../assets/SVG/FolderEmptyState.svg';
import { useNavigate } from 'react-router-dom';
import AddFolderModal from '../../Services/Modal/AddFolderModal';
import DashboardFolderCard from '../../Services/UI/DashboardFolderCard'; // Importing the FolderCard component
import { RightOutlined } from '@ant-design/icons';

const Folders = ({refreshFolders}) => {
  const [folders, setFolders] = useState([]);
  const { userId } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchFolders = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/folder/?userId=${userId}&_=${new Date().getTime()}`);
        const allFolders = response.data.data.folders || [];
        const latestFolders = allFolders.slice(0, 5);
        setFolders(latestFolders);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching folders:', error);
        message.error('Failed to load folders. Please try again.');
        setLoading(false);
    }
};



  useEffect(() => {
    if (userId) {
      fetchFolders();
    }
  }, [userId], [refreshFolders]);

  const handleCreateFolderClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFolderClick = (folderId) => {
    navigate(`/documents/folders/${folderId}`);
  };

  const isEmpty = !loading && folders.length === 0;

  if (loading) {
    return (
      <div
        className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white mx-auto"
        style={{
          Width: '670px',
          height: '720px',
          background: '#FFF',
          borderRadius: '8px',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        {/* Loading State Skeleton */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm"
              style={{
                width: '300px',
                height: '198px',
                borderRadius: '8px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <div className="flex items-center justify-between mb-4">
                <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
                <div className="w-5 h-5 bg-gray-300 rounded-full"></div>
              </div>
              <div className="flex items-center justify-center">
                <img src={FoldersSvg} alt="Folder Icon" width="48" height="48" />
              </div>
              <div className="space-y-2">
                <div className="w-3/4 h-4 bg-gray-200 rounded-md"></div>
                <div className="w-2/4 h-4 bg-gray-200 rounded-md"></div>
                <div className="w-full h-4 bg-gray-200 rounded-md"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white mb-3"
      style={{
        Width: '670px',
       height: '720px',
        background: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
        // margin:'2px'
      }}
    >
      <div className="flex justify-between items-center mb-4">
      <h2 className="text-[24px] font-semibold text-[#192028] font-sf-pro-text leading-[28px] flex items-center gap-2">
  Folders
</h2>

        {!isEmpty && (
        <RightOutlined
        className="text-[#1B2028] w-[24px] h-[24px] flex-shrink-0"
        style={{ fontSize: '16px' }}
        onClick={() => navigate('/documents/folders')}
      />
      
        )}
      </div>

      {!isEmpty && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {folders.map((folder) => (
            <DashboardFolderCard
              key={folder._id}
              folder={folder}
              handleFolderClick={handleFolderClick}
            />
          ))}

          <div
            className="border border-dashed border-gray-300 rounded-lg p-4 flex flex-col justify-center items-center text-gray-400 cursor-pointer bg-white"
            onClick={handleCreateFolderClick}
            style={{
              maxwidth: '240.382px',
              maxheight: '188px',
              // margin:'20px',
            }}
          >
            <div className="relative flex justify-center items-center mb-2 opacity-60">
              <img src={FoldersSvg} alt="Folder Icon" width="48" height="48" />
            </div>
            <span
              style={{
                color: '#798897',
                fontFamily: 'SF Pro Text',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >
              Create a New Folder
            </span>
          </div>
        </div>
      )}

      {isEmpty && (
        <div
          className="flex flex-col justify-center items-center text-gray-400 relative cursor-pointer"
          style={{
            display: 'flex',
            maxWidth: '670px',
            height: '700px',
            padding: '12px',
            flexDirection: 'column',
            gap: '16px',
            borderRadius: '8px',
            border: '1px dashed #C4CBD8',
          }}
          onClick={handleCreateFolderClick}
        >
          <div className="relative flex justify-center items-center mb-2 opacity-60">
            <img src={FoldersSvg} alt="Folder Icon" />
          </div>
          <span
            style={{
              color: '#798897',
              fontFamily: 'SF Pro Text',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
            }}
          >
            Create a New Folder
          </span>
        </div>
      )}

      <AddFolderModal
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        userId={userId}
      />
    </div>
  );
};

export default Folders;