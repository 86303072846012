import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider } from "./views/authcontext";
import Home from "./views/Screens/home";
import UserProfile from "./views/Screens/profile";
import ManageUsers from "./views/Screens/manage-users";
import Register from "./views/Screens/register";
import Login from "./views/Screens/login";
import Logout from "./views/layouts/logout";
import VersionDocument from "./views/Screens/Versions";
import Projects from "./views/Screens/Projects";
import SidebarLayout from "./views/layouts/Sidebar";
import Test from "./views/Screens/test";
import Documents from "./views/Screens/Documents";
import Folders from "./views/Screens/Folders";
import FolderDocuments from "./views/Screens/FolderDocuments";
import Reset from "./views/Screens/Reset";
import Todo from "./views/Screens/Todo";
import Teams from "./views/Screens/Teams";
import TeamDetails from "./views/Screens/TeamDetails";
import Vault from "./views/Screens/Vault";
import Settings from "./views/Screens/Settings";
import Generate from "./views/Screens/Generate";
import ResetPin from "./views/Screens/ResetPin";
import ForgotPassword from "./views/Screens/ForgotPassword";
import VaultVersions from "./views/Screens/VaultVersions";
import Trash from "./views/Screens/Trash";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/logout" element={<Logout />} />
          <Route path="/auth/reset" element={<Reset />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/reset-pin" element={<ResetPin />} />
          <Route path="/app/test" element={<Test />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/generate-password" element={<Generate />} />

          <Route path="*" element={<Navigate to="/" />} />

          {/* Routes with Sidebar */}
          <Route element={<SidebarLayout />}>
            <Route path="/user/profile" element={<UserProfile />} />
            <Route
              path="/documents/:docId/versions"
              element={<VersionDocument />}
            />
            <Route
              path="/vaultdocuments/:docId/versions"
              element={<VersionDocument />}
            />
            <Route path="/admin/users" element={<ManageUsers />} />
            <Route path="/user/projects" element={<Projects />} />
            <Route path="/superadmin/users" element={<ManageUsers />} />
            <Route path="/app/dashboard" element={<Home />} />
            <Route path="/app/documents" element={<Documents />} />
            <Route
              path="/documents/folders/:folderId"
              element={<FolderDocuments />}
            />
            <Route path="/documents/folders" element={<Folders />} />
            <Route path="/user/test" element={<Test />} />
            <Route path="/app/teams" element={<Teams />} />
            <Route path="/app/todo" element={<Todo />} />
            <Route path="/app/vault" element={<Vault />} />
            <Route path="/app/settings" element={<Settings />} />
            <Route path="/app/trash" element={<Trash />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
