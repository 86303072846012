import React, { useState, useEffect, useRef } from "react";
import { Avatar, Switch, Button, message, Dropdown, Spin } from "antd";
import { FilterOutlined, UserOutlined } from "@ant-design/icons";

const UsersSuperAdmin = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState({
    all: true,
    admin: true,
    resigned: true,
  });
  const [loading, setLoading] = useState(false);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [loadingUserId, setLoadingUserId] = useState(null);
  const tableRef = useRef(null);

  // Function to fetch users from the API
  const fetchUsers = async (page, filter) => {
    if (loading || !hasMore[filter]) return;
    setLoading(true);
    try {
      const filterQuery =
        filter === "admin"
          ? "?isAdmin=true"
          : filter === "resigned"
          ? "?isResigned=true"
          : "";
      const apiUrl = `${
        process.env.REACT_APP_AUTH_COM_SERVICE_URL
      }/admin/users${filterQuery}${
        page > 0 ? `${filterQuery ? "&" : "?"}page=${page}` : ""
      }`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch users");
      }

      const data = await response.json();
      const fetchedUsers = data.data.users || [];

      if (fetchedUsers.length === 0) {
        setHasMore((prev) => ({ ...prev, [filter]: false }));
      } else if (page === 0) {
        setUsers(fetchedUsers);
        setPage(1);
      } else {
        setUsers((prevUsers) => [...prevUsers, ...fetchedUsers]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error(error.message || "Error fetching users");
    } finally {
      setLoading(false);
    }
  };

  // Function to toggle admin status
  const handleAdminToggle = async (userId, isAdmin) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/superadmin/adminStatus`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify({
            userId,
            changeTo: isAdmin ? "admin" : "regular",
          }),
        }
      );

      if (response.ok) {
        message.success(
          `User successfully ${isAdmin ? "promoted to" : "demoted from"} admin`
        );
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId
              ? { ...user, type: isAdmin ? "admin" : "user", isAdmin }
              : user
          )
        );
      } else {
        const data = await response.json();
        message.error(data.message || "Failed to update admin status");
      }
    } catch (error) {
      console.error("Error updating admin status:", error);
      message.error("Error updating admin status");
    }
  };

  // Function to remove a user
  const removeUser = async (userId) => {
    setLoadingUserId(userId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/superadmin/resignationStatus`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        message.success("User removed successfully");
        fetchUsers(0, activeFilter);
      } else {
        const data = await response.json();
        message.error(data.message || "Failed to remove user");
      }
    } catch (error) {
      console.error("Error removing user:", error);
      message.error("Error removing user");
    } finally {
      setLoadingUserId(null);
    }
  };

  // Infinite scrolling
  const handleScroll = () => {
    if (!tableRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore[activeFilter] && !loading) {
      fetchUsers(page, activeFilter);
    }
  };

  // Filter dropdown menu
  const filterMenu = (
    <div className="flex flex-col w-[152px] p-1.5 rounded-lg bg-white shadow-lg">
      {["all", "admin", "resigned"].map((filter) => (
        <div
        key={filter}
        className={`flex items-center gap-2 p-2 cursor-pointer hover:bg-[#D1F8F0] text-gray-${
          filter === "all" ? "900" : filter === "admin" ? "900" : "500"
        } rounded-md`}
        onClick={() => {
          setUsers([]); // Clear the users list
          setActiveFilter(filter); // Update the active filter
          setPage(0); // Reset page count
          setHasMore((prev) => ({
            ...prev,
            all: filter === "all" ? true : prev.all,
            admin: filter === "admin" ? true : prev.admin,
            resigned: filter === "resigned" ? true : prev.resigned,
          })); // Ensure the selected filter's hasMore is reset
          fetchUsers(0, filter); // Fetch users for the selected filter
          setFilterDropdownVisible(false); // Close the dropdown
        }}
      >
        <UserOutlined className="text-[#192028]" />
        {filter.charAt(0).toUpperCase() + filter.slice(1)}
      </div>
      
      ))}
    </div>
  );

  useEffect(() => {
    fetchUsers(0, activeFilter);
  }, []);

  return (
    <div className="flex flex-col space-y-8 min-h-screen">
      <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Users</h2>
          <Dropdown
            overlay={filterMenu}
            trigger={["click"]}
            visible={filterDropdownVisible}
            onVisibleChange={(flag) => setFilterDropdownVisible(flag)}
          >
            <Button className="flex items-center justify-between border rounded-md">
              Filter
              <FilterOutlined />
            </Button>
          </Dropdown>
        </div>
        <div
          className="overflow-auto"
          style={{ maxHeight: "552px" }}
          onScroll={handleScroll}
          ref={tableRef}
        >
          <table className="table-auto w-full border-separate" style={{ borderSpacing: "0 10px" }}>
            <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Designation</th>
                <th className="px-4 py-2">Joined Date</th>
                <th className="px-4 py-2">Email</th>
                <th className="px-4 py-2">Make Admin</th>
                <th className="px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr
                  key={user._id}
                  className={`bg-white shadow-sm rounded-lg ${
                    user.resigned ? "opacity-70 blur-[0.5px]" : ""
                  }`}
                >
                  <td className="px-4 py-2 flex items-center gap-2">
                    <Avatar
                      src={`https://i.pravatar.cc/30?u=${user._id}`}
                      alt="User Avatar"
                      size={30}
                    />
                    <span>
                      {user.resigned
                        ? "Resigned User"
                        : user.isAdmin
                        ? "Admin User"
                        : `${user.name} ${user.lastName}`}
                    </span>
                  </td>
                  <td className="px-4 py-2">
                    {user.type === "admin" ? "Administrator" : "Regular User"}
                  </td>
                  <td className="px-4 py-2">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">{user.email}</td>
                  <td className="px-4 py-2">
                    {!user.resigned && (
                      <Switch
                        checked={user.type === "admin"}
                        onChange={(checked) => handleAdminToggle(user._id, checked)}
                        style={{
                          backgroundColor:
                            user.type === "admin" ? "#41EAD4" : "#59616E",
                        }}
                      />
                    )}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      className={`px-4 py-1 w-32 rounded-lg flex items-center justify-center ${
                        user.resigned
                          ? "bg-[#D9DEE7] text-[#59616E]"
                          : "bg-red-500 text-white"
                      }`}
                      onClick={() => (user.resigned ? null : removeUser(user._id))}
                      disabled={user.resigned || loadingUserId === user._id}
                    >
                      {loadingUserId === user._id ? <Spin size="small" /> : "Remove User"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersSuperAdmin;
