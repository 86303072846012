

// import React, { useEffect, useState, useRef, useCallback } from 'react';
// import { message } from 'antd';
// import axios from 'axios';
// import { useAuth } from '../../authcontext';
// import { useNavigate } from 'react-router-dom';
// import PinnedSvg from '../../../assets/SVG/PinnedDocumnet.svg';
// import DocCard from '../../Services/UI/DocCard';
// import DocLoading from '../../Services/UI/LoadingState/DocLoading';

// const PinnedDocuments = ({ sortOrder, currentSortOption, filter }) => {
//   const { userId } = useAuth();
//   const navigate = useNavigate();
//   const [documents, setDocuments] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [page, setPage] = useState(0);
//   const [moreToCome, setMoreToCome] = useState(true);
//   const loaderRef = useRef(null);
//   const [currentStatus, setCurrentStatus] = useState({});

//   const fetchPinnedDocuments = async (pageNumber) => {
//     if (loading) return; // Prevent multiple calls when already loading
  
//     try {
//       setLoading(true);
//       let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/?defaultType=pinned`;
  
//       if (pageNumber > 0) {
//         url += `&page=${pageNumber}`;
//       }
//       if (currentSortOption) {
//         url += `&sortBy=${currentSortOption}&sortOrder=${sortOrder}`;
//       }
//       if (filter === 'a' || filter === 'b' || filter === 'c') {
//         url += `&filterValue=${filter}&filterOn=docSize`;
//       } else if (filter) {
//         url += `&filterValue=${filter}&filterOn=docType`;
//       }
  
//       const response = await axios.get(url);
//       const fetchedDocuments = response.data.data?.finalList || [];
  
//       if (pageNumber === 0) {
//         setDocuments(fetchedDocuments);
//       } else {
//         setDocuments((prevDocuments) => [...prevDocuments, ...fetchedDocuments]);
//       }
  
//       setMoreToCome(response.data.data?.moreToCome || false);
//     } catch (error) {
//       console.error('Error fetching pinned documents:', error);
//       message.error('Failed to load pinned documents. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   // Fetch documents when filters or sorting change
//   useEffect(() => {
//     if (userId) {
//       setDocuments([]); // Reset documents
//       setPage(0); // Reset pagination
//       setMoreToCome(true); 
      
//       fetchPinnedDocuments(0); 
//     }
//   }, [userId, sortOrder, currentSortOption, filter]); 
  
  

//     // Fetch more documents when page changes
//     useEffect(() => {
//       if (page > 0) {
//         fetchPinnedDocuments(page);
//       }
//     }, [page]);
  

//   // Infinite Scroll Logic
//   useEffect(() => {
//     if (!loaderRef.current || loading || !moreToCome) return;
  
//     const observer = new IntersectionObserver(
//       (entries) => {
//         if (entries[0].isIntersecting && moreToCome) {
//           setPage((prevPage) => prevPage + 1); 
//         }
//       },
//       { threshold: 1.0 }
//     );
  
//     const currentLoader = loaderRef.current;
//     observer.observe(currentLoader);
  
//     return () => {
//       if (currentLoader) observer.unobserve(currentLoader);
//     };
//   }, [loading, moreToCome]);
  



//   // Handle pin toggle (unpinning a document)
//   const handlePinToggle = async (documentId) => {
//     try {
//       // Optimistically remove document
//       setDocuments((prevDocuments) =>
//         prevDocuments.filter((doc) => doc._id !== documentId)
//       );

//       await axios.patch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentId}/bookmark?status=false&userId=${userId}`
//       );

//       message.success('Document has been successfully unpinned!');
//     } catch (error) {
//       console.error('Error unpinning document:', error);
//       message.error('Failed to unpin the document. Please try again.');
//     }
//   };

//   // Navigate to document versions
//   const handleDocumentClick = (docId) => {
//     navigate(`/documents/${docId}/versions`);
//   };

//   return (
//     <div className="flex flex-col mx-auto max-w-full gap-5 rounded-lg">
//       {/* Loading state for first-time load */}
//       {loading && page === 0 ? (
//         <DocLoading />
//       ) : documents.length === 0 ? (
//         <div className="flex flex-col justify-center items-center gap-2 min-h-[158px]">
//           <img src={PinnedSvg} alt="No Pinned Documents" className="max-w-[100px]" />
//           <h3 className="text-lg font-semibold text-gray-900 mb-0">Pin key documents</h3>
//           <p className="text-sm text-gray-500 mb-0">Keep your important things safe by pinning them.</p>
//         </div>
//       ) : (
//         <div className="flex flex-col gap-4">
//           {documents.map((doc) => (
//             <DocCard
//               key={doc._id}
//               doc={doc}
//               handleDocumentClick={handleDocumentClick}
//               handlePinToggle={() => handlePinToggle(doc._id)}
//             />
//           ))}
//         </div>
//       )}

//       {/* Infinite scroll loader */}
//       {loading && documents.length > 0 && <DocLoading />}
//       {!loading && moreToCome && <div ref={loaderRef} className="loader">Loading more documents...</div>}
//     </div>
//   );
// };

// export default PinnedDocuments;
















import React, { useEffect, useState, useRef, useCallback } from 'react';
import { message, Modal } from 'antd';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import { useNavigate } from 'react-router-dom';
import PinnedSvg from '../../../assets/SVG/PinnedDocumnet.svg';
import DocCard from '../../Services/UI/DocCard';
import DocLoading from '../../Services/UI/LoadingState/DocLoading';

const PinnedDocuments = ({ sortOrder, currentSortOption, filter }) => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [moreToCome, setMoreToCome] = useState(true);
  const loaderRef = useRef(null);
  const [currentStatus, setCurrentStatus] = useState({});

  const fetchPinnedDocuments = async (pageNumber) => {
    if (loading) return;

    try {
      setLoading(true);
      let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/?defaultType=pinned`;

      if (pageNumber > 0) {
        url += `&page=${pageNumber}`;
      }
      if (currentSortOption) {
        url += `&sortBy=${currentSortOption}&sortOrder=${sortOrder}`;
      }
      if (filter === 'a' || filter === 'b' || filter === 'c') {
        url += `&filterValue=${filter}&filterOn=docSize`;
      } else if (filter) {
        url += `&filterValue=${filter}&filterOn=docType`;
      }

      const response = await axios.get(url);
      const fetchedDocuments = response.data.data?.finalList || [];

      if (pageNumber === 0) {
        setDocuments(fetchedDocuments);
      } else {
        setDocuments((prevDocuments) => [...prevDocuments, ...fetchedDocuments]);
      }

      setMoreToCome(response.data.data?.moreToCome || false);
    } catch (error) {
      console.error('Error fetching pinned documents:', error);
      message.error('Failed to load pinned documents. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      setDocuments([]);
      setPage(0);
      setMoreToCome(true);
      fetchPinnedDocuments(0);
    }
  }, [userId, sortOrder, currentSortOption, filter]);

  useEffect(() => {
    if (page > 0) {
      fetchPinnedDocuments(page);
    }
  }, [page]);

  useEffect(() => {
    if (!loaderRef.current || loading || !moreToCome) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && moreToCome) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    const currentLoader = loaderRef.current;
    observer.observe(currentLoader);

    return () => {
      if (currentLoader) observer.unobserve(currentLoader);
    };
  }, [loading, moreToCome]);

  const handlePinToggle = async (documentId) => {
    Modal.confirm({
      title: 'Unpin Document',
      content: 'Are you sure you want to unpin this document?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.patch(
            `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentId}/bookmark?status=false&userId=${userId}`
          );

          message.success('Document has been successfully unpinned!');
          fetchPinnedDocuments(0);
        } catch (error) {
          console.error('Error unpinning document:', error);
          message.error('Failed to unpin the document. Please try again.');
        }
      },
    });
  };




  const refreshDocuments = () => {
    setDocuments([]); // Clear the current list
    setPage(0);       // Reset to the first page
    setMoreToCome(true);
    fetchPinnedDocuments(0); // Fetch the updated list
  };
  

  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  return (
    <div className="flex flex-col mx-auto max-w-full gap-5 rounded-lg">
      {loading && page === 0 ? (
        <DocLoading />
      ) : documents.length === 0 ? (
        <div className="flex flex-col justify-center items-center gap-2 min-h-[158px]">
          <img src={PinnedSvg} alt="No Pinned Documents" className="max-w-[100px]" />
          <h3 className="text-lg font-semibold text-gray-900 mb-0">Pin key documents</h3>
          <p className="text-sm text-gray-500 mb-0">Keep your important things safe by pinning them.</p>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {documents.map((doc) => (
         <DocCard
         key={doc._id}
         doc={doc}
         handleDocumentClick={handleDocumentClick}
         handlePinToggle={() => handlePinToggle(doc._id)}
         onUnpinSuccess={refreshDocuments} // Pass the refresh function
       />
       
          ))}
        </div>
      )}
      {loading && documents.length > 0 && <DocLoading />}
      {!loading && moreToCome && <div ref={loaderRef} className="loader">Loading more documents...</div>}
    </div>
  );
};

export default PinnedDocuments;




