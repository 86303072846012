



// Folder.jsx// Screen







import React, { useState } from 'react';
import FolderHeader from '../Components/Folders/FolderHeader';
import FolderGrid from '../Components/Folders/Folders';
import Shared from '../Components/Folders/Shared';
import Pinned from '../Components/Folders/Pinned';
import Default from '../Components/Folders/Default';
import { 
  FilePdfOutlined, 
  FileImageOutlined, 
  FileOutlined, 
  FileExcelOutlined, 
  FilePptOutlined,
  CalendarOutlined, 
  FontColorsOutlined, 
  PieChartOutlined,
  DownOutlined,
  FileUnknownOutlined 
} from "@ant-design/icons";

const Folders = () => {
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedOption, setSelectedOption] = useState('allFolders');
  const [sortOrder, setSortOrder] = useState(-1);
  const [filter, setFilter] = useState(null);
  const [currentSortOption, setCurrentSortOption] = useState('createdAt');
  const [refreshdocs, setRefreshDocs] = useState(false);

  const handleSortOrderChange = (order, option) => {
    setSortOrder(order);
    setCurrentSortOption(option);
  };

  const componentMap = {
    allFolders: FolderGrid,
    sharedWithMe: Shared,
    pinnedDocument: Pinned,
    default: Default,
  };

  const sortOptionsMap = {
    allFolders: [
      { label: 'Name', value: 'name' },
      { label: 'Created at', value: 'createdAt' },
      { label: 'Modified on', value: 'modifiedAt' },
      { label: 'Number of Documents', value: 'items' },
    ],
    sharedWithMe: [
      { label: 'Name', value: 'name' },
      { label: 'Created at', value: 'createdAt' },
      { label: 'Modified on', value: 'modifiedAt' },
      { label: 'Number of Documents', value: 'items' },
    ],
    pinnedDocument: [
      { label: <><CalendarOutlined /> Upload Date</>, value: "uploadedAt" },
      { label: <><FontColorsOutlined /> Name</>, value: "docName" },
      { label: <><PieChartOutlined /> File Size</>, value: "docSize" },
    ],
    default: [
      { label: <><CalendarOutlined /> Upload Date</>, value: "uploadedAt" },
      { label: <><FontColorsOutlined /> Name</>, value: "docName" },
      { label: <><PieChartOutlined /> File Size</>, value: "docSize" },
    ],
  };

  const filterOptionsMap = {
    allFolders: [
      { label: 'Self Uploaded', value: 'self' },
      { label: 'Org-wide', value: 'org' },
      { label: 'Team', value: 'team' },
    ],
    sharedWithMe: [
      { label: 'Org-wide', value: 'org' },
      { label: 'Team', value: 'team' },
    ],
    // pinnedDocument: [
    //   { label: 'PDF', value: 'pdf' },
    //   { label: 'Images', value: 'images' },
    //   { label: 'Docs', value: 'docs' },
    //   { label: 'Excel', value: 'excel' },
    //   { label: 'PPT', value: 'ppt' },
    //   { label: 'Miscellaneous', value: 'miscellanous' },
    //   { label: '1 MB - 5 MB', value: 'a' },
    //   { label: '1 MB - 5 MB', value: 'b' },
    //   { label: 'More than 5 MB', value: 'c' },
  // ],



  pinnedDocument: [
    { 
      label: (
        <div className="flex items-center gap-[8px] font-semibold cursor-default">
          <FileOutlined /> File Type <DownOutlined />
        </div>
      ), 
      value: null, 
      disabled: true, 
    },     
    { label: <><FilePdfOutlined /> PDF</>, value: "pdf" },
    { label: <><FileImageOutlined /> Images</>, value: "images" },
    { label: <><FileOutlined /> Docs</>, value: "docs" },
    { label: <><FileExcelOutlined /> Excel</>, value: "excel" },
    { label: <><FilePptOutlined /> PPT</>, value: "ppt" },
    { label: <><FileUnknownOutlined /> Miscellaneous</>, value: "misc" },
    { 
      label: (
        <div className="flex items-center gap-[8px] font-semibold cursor-default">
          <PieChartOutlined /> File Size <DownOutlined />
        </div>
      ), 
      value: null, 
      disabled: true, 
    },     
    { label: <> <FileOutlined /> Less than 1 MB</>, value: "a" },
    { label: <> <FileOutlined /> 1 MB - 5 MB</>, value: "b" },
    { label: <> <FileOutlined /> More than 5 MB</>, value: "c" },
  ],
  



    default: [
      { 
        label: (
          <div className="flex items-center gap-[8px] font-semibold cursor-default">
            <FileOutlined /> File Type <DownOutlined />
          </div>
        ), 
        value: null, 
        disabled: true, 
      },     
      { label: <><FilePdfOutlined /> PDF</>, value: "pdf" },
      { label: <><FileImageOutlined /> Images</>, value: "images" },
      { label: <><FileOutlined /> Docs</>, value: "docs" },
      { label: <><FileExcelOutlined /> Excel</>, value: "excel" },
      { label: <><FilePptOutlined /> PPT</>, value: "ppt" },
      { label: <><FileUnknownOutlined /> Misc</>, value: "misc" },
      { 
        label: (
          <div className="flex items-center gap-[8px] font-semibold cursor-default">
            <PieChartOutlined /> File Size <DownOutlined />
          </div>
        ), 
        value: null, 
        disabled: true, 
      },     
      { label: <> <FileOutlined /> Less than 1 MB</>, value: "a" },
      { label: <> <FileOutlined /> 1 MB - 5 MB</>, value: "b" },
      { label: <> <FileOutlined /> More than 5 MB</>, value: "c" },
    ]
    
    
  
  };



  
  const renderSelectedComponent = () => {
    const SelectedComponent = componentMap[selectedOption] || componentMap.default;

    if (selectedOption === "default") {
      return (
        <Default
        sortOrder={sortOrder || -1}
        currentSortOption={currentSortOption === "createdAt" ? "uploadedAt" : currentSortOption || "uploadedAt"}
        filter={filter}
        setFilter={setFilter} // Pass setFilter to Default component
        refreshdocs={refreshdocs}
      />
      
      );
    }

    if (selectedOption === "pinnedDocument") {
      return (
        <Pinned
        sortOrder={sortOrder || -1}
        currentSortOption={currentSortOption === "createdAt" ? "uploadedAt" : currentSortOption || "uploadedAt"}
        filter={filter}
        setFilter={setFilter} 
        />
      );
    }

    return (
      <SelectedComponent
        selectedFolderId={selectedFolderId}
        setSelectedFolderId={setSelectedFolderId}
        sortOrder={sortOrder}
        currentSortOption={currentSortOption}
        filter={filter}
      />
    );
  };

  return (
    <div>
      <FolderHeader
        setSelectedOption={setSelectedOption}
        setSortOrder={handleSortOrderChange}
        setFilter={setFilter}
        filterOptions={filterOptionsMap[selectedOption] || filterOptionsMap.default}
        sortOptions={sortOptionsMap[selectedOption] || sortOptionsMap.default}
        currentSortOption={currentSortOption}
        selectedOption={selectedOption}
        setRefreshDocs={setRefreshDocs}
      />
      {renderSelectedComponent()}
    </div>
  );
};

export default Folders;





