
import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import axios from "axios";
import DocViewerComponent from "../Components/Versions/DocViewer";
import VersionCrud from "../Components/Versions/VersionCrud";
import VersionHistory from "../Components/Versions/VersionHistory";
import { useParams } from "react-router-dom";
import { useAuth } from "../authcontext";
import JsonEditor  from "react-json-pretty";
import "react-json-pretty/themes/monikai.css"; 


// Define supported file types
const CADFileExtensions = [
  "image/vnd.dwg",
  "image/vnd.dxf",
  "image/vnd.dwf",
  "image/vnd.ifc",
  "application/x-ifc",
];
const RVTFileExtension = "application/vnd.autodesk.revit";
const JSONFileExtensions = ["application/json"];

const VersionPage = () => {
  const { docId } = useParams();
  const { userId, vaultStatus } = useAuth();

  const [documentInfo, setDocumentInfo] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [error, setError] = useState(null);
  const [isCADFile, setIsCADFile] = useState(false);
  const [isRVTFile, setIsRVTFile] = useState(false);
  const [isJSONFile, setIsJSONFile] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [cadReloadKey, setCadReloadKey] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Track version updates
  const [deleteRefreshTrigger, setDeleteRefreshTrigger] = useState(0);
  const sessionToken = localStorage.getItem("sessionToken");
  const vaultToken = localStorage.getItem("vaultToken");

  // Headers for API requests
  const headers = vaultStatus
    ? {
        Authorization: `Bearer ${sessionToken}`,
        vaultToken: vaultToken,
        secureVault: true,
      }
    : {
        Authorization: `Bearer ${sessionToken}`,
      };

  // Fetch document details and set state
  useEffect(() => {
    const fetchDocumentInfo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
          { headers }
        );

        const data = response.data.data;
        setDocumentInfo(data);
        setSelectedVersion(data.currentVersion);

        // Check document type
        setIsCADFile(CADFileExtensions.includes(data.docType));
        setIsRVTFile(data.docType === RVTFileExtension);
        setIsJSONFile(JSONFileExtensions.includes(data.docType));

        // Fetch JSON data if applicable
        if (JSONFileExtensions.includes(data.docType)) {
          const jsonResponse = await axios.get(
            `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download`,
            {
              params: {
                fileName: data.bucketKey,
                versionId: data.currentVersion,
              },
              headers,
            }
          );
          setJsonData(jsonResponse.data);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    if (docId) {
      fetchDocumentInfo();
    }
  }, [docId, userId, refreshTrigger, deleteRefreshTrigger]);

  // Auto-reload CAD viewer every 3 minutes
  useEffect(() => {
    let intervalId;
    if (isCADFile) {
      intervalId = setInterval(() => {
        setCadReloadKey((prevKey) => prevKey + 1);
      }, 180000);
    }
    return () => clearInterval(intervalId);
  }, [isCADFile]);

  return (
    <div style={{ padding: "40px" }}>
      <Row gutter={16}>
        {/* Document Viewer Section */}
        <Col span={16}>
          {documentInfo &&
            (isCADFile ? (
              <iframe
                key={cadReloadKey}
                src={`//sharecad.org/cadframe/load?url=${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`}
                scrolling="no"
                width="100%"
                height="105%"
                style={{ border: "none" }}
                title="CAD Viewer"
              ></iframe>
            ) : isRVTFile ? (
              <iframe
                key={cadReloadKey}
                scrolling="no"
                src={`//sharecad.org/cadframe/load?url=${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${documentInfo.bucketKey}&versionId=${selectedVersion}&view=true`}
                width="100%"
                height="105%"
                style={{ border: "none" }}
                title="RVT Viewer"
              ></iframe>
            ) : isJSONFile ? (
              <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                {jsonData && (
                  <JsonEditor
                    data={jsonData}
                    setData={setJsonData}
                    rootName="JSON Data"
                    theme={{ rootBackgroundColor: "#f0f2f5" }}
                  />
                )}
              </div>
            ) : (
              <DocViewerComponent
                selectedVersion={selectedVersion}
                documentInfo={documentInfo}
                refreshTrigger={refreshTrigger}
                deleteRefreshTrigger={deleteRefreshTrigger}
              />
            ))}
        </Col>

        {/* Version Management Section */}
        <Col span={8}>
          <Row gutter={[0, 16]}>
            {/* Upload & Manage Versions */}
            <Col span={24}>
              {documentInfo && (
               <VersionCrud
               documentInfo={documentInfo}
               selectedVersion={selectedVersion} 
               setRefreshTrigger={setRefreshTrigger} // ✅ Pass down refresh trigger
               setDeleteRefreshTrigger={setDeleteRefreshTrigger}
               deleteRefreshTrigger={deleteRefreshTrigger}
             />
             
              )}
            </Col>

            {/* Version History */}
            <Col span={24}>
              {documentInfo && (
               <VersionHistory
               documentInfo={documentInfo}
               onSelectVersion={(versionId) => setSelectedVersion(versionId)}
               refreshTrigger={refreshTrigger} // Auto-refresh when new version is uploaded
               deleteRefreshTrigger={deleteRefreshTrigger}
             />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Error Message Display */}
      {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}
    </div>
  );
};

export default VersionPage;




