import React, { useState, useEffect } from "react";
import { Select, Spin, Switch } from "antd";
import useDebounceFetch from "../../Services/Utils/Debouncing";

const { Option } = Select;

const TeamOrMemberSearchSelect = ({ members, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isTeam, setIsTeam] = useState(false);

  // Custom hook for debounced member or team search
  const { debouncedTerm, results, loading, fetchMore, moreToCome } = useDebounceFetch(
    searchTerm,
    page,
    setPage,
    500,
    isTeam ? "teams" : "generic"
  );

  useEffect(() => {
    // Filter out already selected members from the search results
    const filtered = results.filter((user) => !members.includes(user._id));
    setFilteredResults(filtered);
  }, [results, members]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreToCome) {
      fetchMore();
    }
  };

  const handleChange = (selectedIds) => {
    const selectedEntities = filteredResults.filter(user => selectedIds.includes(user._id));
    
    // Ensure each selected entity has entityType
    const enrichedEntities = selectedEntities.map(entity => ({
      ...entity,
      entityType: entity.entityType || "User", // Default to User if not present
    }));
  
    onChange(enrichedEntities); // Pass updated selection with entityType
  };
  


  const toggleSwitch = (checked) => {
    setIsTeam(checked);
    // Clear the search term and results when switching
    setSearchTerm("");
    setFilteredResults([]);
    setPage(0); // Reset pagination when switching
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="flex-1">
      <Select
    mode="multiple"
    allowClear
    style={{ width: "100%" }}
    placeholder={`Search for ${isTeam ? "teams" : "individuals"}...`}
    value={members.map(m => m._id)} // Ensure only IDs are used for selection
    onSearch={handleSearch}
    onChange={handleChange} // Use updated handler
    onPopupScroll={handlePopupScroll}
    notFoundContent={loading ? <Spin /> : (searchTerm && filteredResults.length === 0 && "No results")}
    filterOption={false}
>
    {filteredResults.map(user => (
        <Option key={user._id} value={user._id}>
            {user.name}
        </Option>
    ))}
</Select>

      </div>
      <div className="flex items-center space-x-2">
        <span className={!isTeam ? "text-gray-700" : "text-gray-400"}>Individual</span>
        <Switch checked={isTeam} onChange={toggleSwitch} />
        <span className={isTeam ? "text-gray-700" : "text-gray-400"}>Team</span>
      </div>
    </div>
  );
};

export default TeamOrMemberSearchSelect;
