// import React, { useState, useEffect } from 'react';
// import { FlagOutlined, PlusOutlined, BellOutlined, MoreOutlined, CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
// import { message, Dropdown, Menu, Skeleton, Modal } from 'antd';
// import axios from 'axios';
// import { useAuth } from '../../authcontext';
// import TodoSvg from '../../../assets/SVG/ToDo.svg';
// import AddTaskModal from '../../Services/Modal/AddTaskModal';
// import EditTaskModal from '../../Services/EditModal/EditTaskModal';
// import DeleteTaskModal from '../../Services/DeleteModal/DeleteTaskModal';

// const ToDoList = () => {
//   const { userId } = useAuth();
//   const [tasks, setTasks] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isEditModalVisible, setIsEditModalVisible] = useState(false);
//   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
//   const [selectedTask, setSelectedTask] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchTasks = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?userId=${userId}&forDashboard=true`);
//         const allTasks = response.data?.data?.tasks || [];

//         setTimeout(() => {
//           setTasks(allTasks);
//           setIsLoading(false);
//         }, 500); // 500ms delay for loading state to show
//       } catch (error) {
//         console.error('Error fetching tasks:', error);
//         message.error('Failed to fetch tasks.');
//         setIsLoading(false);
//       }
//     };

//     fetchTasks();
//   }, [userId]);

//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const handleTaskCreated = (newTask) => {
//     setTasks((prevTasks) => [newTask, ...prevTasks]);
//     setIsModalVisible(false);
//   };

//   const toggleTaskCompletion = (taskId) => {
//     setTasks((prevTasks) =>
//       prevTasks.map((task) =>
//         task._id === taskId ? { ...task, completed: !task.completed } : task
//       )
//     );
//   };

//   const handleEdit = (taskId) => {
//     const taskToEdit = tasks.find(task => task._id === taskId);
//     if (taskToEdit) {
//       setSelectedTask(taskToEdit);
//       setIsEditModalVisible(true);
//     }
//   };

//   const handleDelete = (taskId) => {
//     const taskToDelete = tasks.find(task => task._id === taskId);
//     if (taskToDelete) {
//       setSelectedTask(taskToDelete);
//       setIsDeleteModalVisible(true);
//     }
//   };

//   const handleEditCancel = () => {
//     setIsEditModalVisible(false);
//     setSelectedTask(null);
//   };

//   const handleDeleteCancel = () => {
//     setIsDeleteModalVisible(false);
//     setSelectedTask(null);
//   };

//   const getMenu = (taskId) => (
//     <Menu
//       style={{
//         display: 'flex',
//         width: '199px',
//         padding: '4px',
//         flexDirection: 'column',
//         background: '#FFF',
//         boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
//         borderRadius: '8px',
//       }}
//     >
//       <Menu.Item
//         key="1"
//         onClick={() => handleEdit(taskId)}
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           backgroundColor: '#D1F8F0',
//           borderRadius: '4px',
//           color: '#192028',
//           margin: '4px 0',
//         }}
//       >
//         <EditOutlined style={{ marginRight: '8px', color: '#192028', width: '16px', height: '16px' }} />
//         Modify Task
//       </Menu.Item>
//       <Menu.Item
//         key="2"
//         onClick={() => handleDelete(taskId)}
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           backgroundColor: '#FFF',
//           borderRadius: '4px',
//           color: '#192028',
//           margin: '4px 0',
//         }}
//       >
//         <DeleteOutlined style={{ marginRight: '8px', color: '#798897', width: '16px', height: '16px' }} />
//         Delete Task
//       </Menu.Item>
//     </Menu>
//   );

//   if (isLoading) {
//     // Loading state skeleton layout
//     return (
//       <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white" style={{ maxWidth: '440px', minHeight: '196px' }}>
//         <div className="flex justify-between items-center mb-4">
//           <Skeleton.Button active style={{ width: '150px', height: '20px' }} />
//           <Skeleton.Button active style={{ width: '80px', height: '20px' }} />
//         </div>
//         {[1, 2].map((_, index) => (
//           <div key={index} className="flex items-center gap-4 mb-3">
//             <Skeleton.Avatar active shape="square" size="large" />
//             <div style={{ flex: 1 }}>
//               <Skeleton.Button active style={{ width: '80%', height: '12px', marginBottom: '4px' }} />
//               <Skeleton.Button active style={{ width: '60%', height: '12px' }} />
//             </div>
//             <div className="flex gap-2">
//               <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
//               <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
//               <Skeleton.Button active shape="circle" style={{ width: '20px', height: '20px' }} />
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }

//   return (
//     <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white" style={{ maxWidth: '440px', minHeight: '196px' }}>
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-lg font-semibold text-gray-900">To Do List</h2>
//         {tasks.length > 0 && (
//           <a href="#" className="text-teal-500 underline flex items-center space-x-1">
//             <FlagOutlined className="text-gray-500" />
//             <span>View All</span>
//           </a>
//         )}
//       </div>

//       {tasks.length > 0 ? (
//         <div className="space-y-2">
//           {tasks.map((task) => (
//             <div
//               key={task._id}
//               className="relative flex items-center justify-between p-2 rounded-lg shadow-sm"
//               style={{
//                 backgroundColor: '#F0F9F9',
//                 borderRadius: '8px',
//                 borderLeft: '4px solid #00BFA5',
//               }}
//             >
//               <div className="flex items-center space-x-2 flex-1">
//                 <button
//                   className="w-5 h-5 rounded-full border-2 border-[#00BFA5] cursor-pointer flex items-center justify-center"
//                   style={{ backgroundColor: task.completed ? '#00BFA5' : 'transparent' }}
//                   onClick={() => toggleTaskCompletion(task._id)}
//                 >
//                   {task.completed && <CheckOutlined style={{ color: '#fff', fontSize: '12px' }} />}
//                 </button>
//                 <h3 className="text-sm font-semibold text-gray-900">{task.name}</h3>
//                 <span className="text-xs text-red-500 border border-red-500 rounded-full px-2 py-0.5 ml-4" style={{ whiteSpace: 'nowrap', fontSize: '10px' }}>
//                   {new Date(task.dueDate).toLocaleString()}
//                 </span>
//               </div>

//               {/* Fixed icons container with consistent spacing */}
//               <div className="flex items-center space-x-3 absolute right-2">
//                 <FlagOutlined className="text-blue-500" style={{ fontSize: '16px' }} />
//                 <BellOutlined className="text-gray-500" style={{ fontSize: '16px' }} />
//                 <Dropdown overlay={getMenu(task._id)} trigger={['click']}>
//                   <MoreOutlined className="text-gray-500 cursor-pointer" style={{ fontSize: '16px' }} />
//                 </Dropdown>
//               </div>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className="flex flex-col justify-center items-center" style={{ minHeight: '196px' }}>
//           <img src={TodoSvg} alt="todo svg" style={{ width: '100px' }} />
//           <h3 style={{ fontSize: '16px', fontWeight: 600, color: '#192028' }}>You're All Caught Up!</h3>
//           <p style={{ fontSize: '12px', color: '#59616E' }}>Create your first task to keep the momentum going.</p>
//         </div>
//       )}

//       <div className="mt-4 flex items-center text-teal-500 cursor-pointer" onClick={showModal}>
//         <div
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             width: '24px',
//             height: '24px',
//             borderRadius: '4px',
//             border: '1px solid #00BFA5',
//             backgroundColor: '#FFFFFF',
//             marginRight: '8px',
//           }}
//         >
//           <PlusOutlined style={{ color: '#00BFA5' }} />
//         </div>
//         <span className="ml-2 text-sm" style={{ color: '#59616E' }}>Add Task</span>
//       </div>

//       <AddTaskModal
//         isVisible={isModalVisible}
//         handleCancel={handleCancel}
//         userId={userId}
//         onTaskCreated={handleTaskCreated}
//       />

//       {selectedTask && (
//         <EditTaskModal
//           isVisible={isEditModalVisible}
//           task={selectedTask}
//           handleCancel={handleEditCancel}
//         />
//       )}

//       {selectedTask && (
//         <DeleteTaskModal
//           isVisible={isDeleteModalVisible}
//           task={selectedTask}
//           handleCancel={handleDeleteCancel}
//         />
//       )}
//     </div>
//   );
// };

// export default ToDoList;

















// import React, { useState, useEffect } from 'react';
// import { FlagOutlined, PlusOutlined, BellOutlined, MoreOutlined, CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
// import { message, Dropdown, Menu, Skeleton } from 'antd';
// import axios from 'axios';
// import { useAuth } from '../../authcontext';
// import TodoSvg from '../../../assets/SVG/ToDo.svg';
// import AddTaskModal from '../../Services/Modal/AddTaskModal';
// import axiosDocInstance from '../../ApiServices/AxiosDocInstance';

// const ToDoList = () => {
//   const { userId } = useAuth();
//   const [tasks, setTasks] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const [tags, setTags] = useState([]);


//   useEffect(() => {
//     const fetchTasks = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axiosDocInstance.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?forDashboard=true`);
//         const allTasks = response.data?.data?.tasks || [];
        
//         // Introduce an artificial delay to ensure loading state is visible
//         setTimeout(() => {
//           setTasks(allTasks);
//           setIsLoading(false);
//         }, 500); // 500ms delay for loading state to show
//       } catch (error) {
//         console.error('Error fetching tasks:', error);
//         message.error('Failed to fetch tasks.');
//         setIsLoading(false);
//       }
//     };

//     fetchTasks();
//   }, [userId]);

//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const handleTaskCreated = (newTask) => {
//     setTasks((prevTasks) => [newTask, ...prevTasks]);
//     setIsModalVisible(false);
//   };

//   const toggleTaskCompletion = (taskId) => {
//     setTasks((prevTasks) =>
//       prevTasks.map((task) =>
//         task._id === taskId ? { ...task, completed: !task.completed } : task
//       )
//     );
//   };

//   const handleEdit = (taskId) => {
//     console.log('Edit task with id:', taskId);
//   };

//   const handleDelete = (taskId) => {
//     console.log('Delete task with id:', taskId);
//   };

//   const getMenu = (taskId) => (
//     <Menu
//       style={{
//         display: 'flex',
//         width: '199px',
//         padding: '4px',
//         flexDirection: 'column',
//         background: '#FFF',
//         boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
//         borderRadius: '8px',
//       }}
//     >
//       <Menu.Item
//         key="1"
//         onClick={() => handleEdit(taskId)}
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           backgroundColor: '#D1F8F0',
//           borderRadius: '4px',
//           color: '#192028',
//           margin: '4px 0',
//         }}
//       >
//         <EditOutlined style={{ marginRight: '8px', color: '#192028', width: '16px', height: '16px' }} />
//         Modify Task
//       </Menu.Item>
//       <Menu.Item
//         key="2"
//         onClick={() => handleDelete(taskId)}
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           backgroundColor: '#FFF',
//           borderRadius: '4px',
//           color: '#192028',
//           margin: '4px 0',
//         }}
//       >
//         <DeleteOutlined style={{ marginRight: '8px', color: '#798897', width: '16px', height: '16px' }} />
//         Delete Task
//       </Menu.Item>
//     </Menu>
//   );

//   if (isLoading) {
//     // Loading state skeleton layout as per the provided PNG image
//     return (
//       <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white" style={{ maxWidth: '440px', minHeight: '196px' }}>
//         <div className="flex justify-between items-center mb-4">
//           <Skeleton.Button active style={{ width: '150px', height: '20px' }} />
//           <Skeleton.Button active style={{ width: '80px', height: '20px' }} />
//         </div>
//         {[1, 2].map((_, index) => (
//           <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '12px' }}>
//             <Skeleton.Avatar active shape="square" size="large" />
//             <div style={{ flex: 1 }}>
//               <Skeleton.Button active style={{ width: '80%', height: '12px', marginBottom: '4px' }} />
//               <Skeleton.Button active style={{ width: '60%', height: '12px' }} />
//             </div>
//             <div style={{ display: 'flex', gap: '8px' }}>
//               <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
//               <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
//               <Skeleton.Button active shape="circle" style={{ width: '20px', height: '20px' }} />
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }

//   return (
//     <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white" style={{ maxWidth: '440px', minHeight: '196px' }}>
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-lg font-semibold text-gray-900">To Do List</h2>
//         {!isLoading && tasks.length > 0 && (
//           <a href="#" className="text-teal-500 underline flex items-center space-x-1">
//             <FlagOutlined className="text-gray-500" />
//             <span>View All</span>
//           </a>
//         )}
//       </div>

//       {!isLoading && tasks.length > 0 ? (
//         <div className="space-y-2" style={{ flexGrow: 1, overflowY: 'auto' }}>
//           {tasks.map((task) => (
//             <div
//               key={task._id}
//               className="flex items-center justify-between p-2 rounded-lg shadow-sm"
//               style={{
//                 backgroundColor: '#F0F9F9',
//                 borderRadius: '8px',
//                 display: 'flex',
//                 alignItems: 'center',
//                 gap: '12px',
//                 borderLeft: '4px solid #00BFA5',
//               }}
//             >
//               <div className="flex items-center space-x-2 flex-1">
//                 <button
//                   className="w-5 h-5 rounded-full border-2 border-[#00BFA5] cursor-pointer flex items-center justify-center"
//                   style={{ backgroundColor: task.completed ? '#00BFA5' : 'transparent' }}
//                   onClick={() => toggleTaskCompletion(task._id)}
//                 >
//                   {task.completed && <CheckOutlined style={{ color: '#fff', fontSize: '12px' }} />}
//                 </button>
//                 <h3 className="text-sm font-semibold text-gray-900">{task.name}</h3>
//                 <span className="text-xs text-red-500 border border-red-500 rounded-full px-2 py-0.5 ml-4" style={{ whiteSpace: 'nowrap', fontSize: '10px' }}>
//                   {new Date(task.dueDate).toLocaleString()}
//                 </span>
//               </div>

//               <div className="flex items-center space-x-4">
//                 <FlagOutlined className="text-blue-500" />
//                 <BellOutlined className="text-gray-500" />
//                 <Dropdown overlay={getMenu(task._id)} trigger={['click']}>
//                   <MoreOutlined className="text-gray-500 cursor-pointer" />
//                 </Dropdown>
//               </div>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div className="flex flex-col justify-center items-center" style={{ minHeight: '196px' }}>
//           <img src={TodoSvg} alt="todo svg" style={{ width: '100px' }} />
//           <h3 style={{ fontSize: '16px', fontWeight: 600, color: '#192028' }}>You're All Caught Up!</h3>
//           <p style={{ fontSize: '12px', color: '#59616E' }}>Create your first task to keep the momentum going.</p>
//         </div>
//       )}

//       <div className="mt-4 flex items-center text-teal-500 cursor-pointer" onClick={showModal}>
//         <div
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             width: '24px',
//             height: '24px',
//             borderRadius: '4px',
//             border: '1px solid #00BFA5',
//             backgroundColor: '#FFFFFF',
//             marginRight: '8px',
//           }}
//         >
//           <PlusOutlined style={{ color: '#00BFA5' }} />
//         </div>
//         <span className="ml-2 text-sm" style={{ color: '#59616E' }}>Add Task</span>
//       </div>

//       <AddTaskModal
//         isVisible={isModalVisible}
//         handleCancel={handleCancel}
//         userId={userId}
//         onTaskCreated={handleTaskCreated}
//       />
//     </div>
//   );
// };

// export default ToDoList;



import React, { useState, useEffect } from 'react';
import { FlagOutlined, PlusOutlined, BellOutlined, MoreOutlined, CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { message, Dropdown, Menu, Skeleton } from 'antd';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import TodoSvg from '../../../assets/SVG/ToDo.svg';
import AddTaskModal from '../../Services/Modal/AddTaskModal';
import EditTaskModal from '../../Services/EditModal/EditTaskModal';
import DeleteTaskModal from '../../Services/DeleteModal/DeleteTaskModal';
import axiosDocInstance from '../../ApiServices/AxiosDocInstance';

const ToDoList = () => {
  const { userId } = useAuth();
  const [tasks, setTasks] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [deleteTaskModalVisible, setDeleteTaskModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
    const [taskToDelete, setTaskToDelete] = useState(null);
      const [dropdownVisible, setDropdownVisible] = useState(null);
        const [taskToEdit, setTaskToEdit] = useState(null);
      
    
  


  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axiosDocInstance.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?forDashboard=true`
        );
        const allTasks = response.data?.data?.tasks || [];
        setTasks(allTasks.map((task) => task || {})); // Fallback for undefined tasks
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        message.error("Failed to fetch tasks.");
        setIsLoading(false);
      }
    };
    
    fetchTasks();
  }, [userId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTaskCreated = (newTask) => {
    setTasks((prevTasks) => [newTask, ...prevTasks]);
    setIsModalVisible(false);
  };

  const toggleTaskCompletion = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task._id === taskId ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const handleEditTask = (taskId) => {
    const task = tasks.find((t) => t._id === taskId);
    if (!task) {
      console.error("Task not found:", taskId);
      return;
    }
    setTaskToEdit(task);
    setEditTaskModalVisible(true);
    setDropdownVisible(null); // Close the dropdown when Edit modal opens
  };
  

  const handleEditModalClose = () => setEditTaskModalVisible(false);

  const handleDeleteTask = (task) => {
    setTaskToDelete(task);
    setDeleteTaskModalVisible(true);
    setDropdownVisible(null); // Close the dropdown when Delete modal opens
  };
  const handleDeleteModalClose = () => setDeleteTaskModalVisible(false);

  
  const confirmDeleteTask = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskToDelete._id}`
      );
      if (response.status === 200) {
        message.success(`Task deleted: ${taskToDelete.name}`);
        setTasks((prevTasks) =>
          prevTasks.filter((task) => task._id !== taskToDelete._id)
        );
        setDeleteTaskModalVisible(false);
      }
    } catch (error) {
      message.error("Failed to delete task");
    }
  };

  
  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  

  const getMenu = (taskId) => (
    <Menu
    className="flex flex-col p-1 w-[199px] bg-white shadow-md rounded-lg"
    style={{
      boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    }}
  >
    {/* Modify Task Menu Item */}
    <Menu.Item
      key="modifyTask"
      onClick={() => handleEditTask(taskId)}
      style={{
        padding: '8px 16px',
        borderRadius: '4px',
        backgroundColor: hoveredItem === 'modifyTask' ? '#D1F8F0' : '#FFF',
        color: hoveredItem === 'modifyTask' ? '#1B2028' : '#192028',
      }}
      onMouseEnter={() => setHoveredItem('modifyTask')}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <EditOutlined
        style={{
          marginRight: '8px',
          color: hoveredItem === 'modifyTask' ? '#1B2028' : '#192028',
          width: '16px',
          height: '16px',
        }}
      />
      Modify Task
    </Menu.Item>

    {/* Delete Task Menu Item */}
    <Menu.Item
      key="deleteTask"
      onClick={() => handleDeleteTask(taskId)}
      style={{
        padding: '8px 16px',
        borderRadius: '4px',
        backgroundColor: hoveredItem === 'deleteTask' ? '#D1F8F0' : '#FFF',
        color: hoveredItem === 'deleteTask' ? '#1B2028' : '#192028',
      }}
      onMouseEnter={() => setHoveredItem('deleteTask')}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <DeleteOutlined
        style={{
          marginRight: '8px',
          color: hoveredItem === 'deleteTask' ? '#798897' : '#192028',
          width: '16px',
          height: '16px',
        }}
      />
      Delete Task
    </Menu.Item>
  </Menu>



  
  );

  if (isLoading) {
    // Loading state skeleton layout as per the provided PNG image
    return (
      <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white mb-3" style={{ maxwidth: '416px',height: '288px', flexShrink: 0 }}>
        <div className="flex justify-between items-center mb-4">
          <Skeleton.Button active style={{ width: '150px', height: '0px' }} />
          <Skeleton.Button active style={{ width: '80px', height: '20px' }} />
        </div>
        {[1, 2].map((_, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '12px' }}>
            <Skeleton.Avatar active shape="square" size="large" />
            <div style={{ flex: 1 }}>
              <Skeleton.Button active style={{ width: '80%', height: '12px', marginBottom: '4px' }} />
              <Skeleton.Button active style={{ width: '60%', height: '12px' }} />
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
              <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
              <Skeleton.Button active shape="circle" style={{ width: '20px', height: '20px' }} />
            </div>
          </div>
        ))}
      </div>
    );
  }
  

  return (
    <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white mb-3" style={{ Width: '416px', minHeight: '288px', flexShrink: 0 }}>
    <div className="flex justify-between items-center mb-4">
      <h2 
        className="text-lg font-semibold" 
        style={{
          color: '#1B2028',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '28px',
        }}
      >
        To Do List
      </h2>
      {tasks.length > 0 && (
        <a
          href="#"
          className="text-[#007768] underline font-bold flex items-center justify-center px-1 py-0 gap-2 no-underline hover:no-underline focus:no-underline"
          style={{ textDecoration: "underline", color: "#007768" }}
        >
          <FlagOutlined className="text-gray-500" />
          View All
        </a>
      )}
    </div>
  
    {isLoading ? (
      <Skeleton active paragraph={{ rows: 4 }} />
    ) : tasks.length > 0 ? (
      <div className="space-y-2" style={{ flexGrow: 1, overflowY: 'auto' }}>
        {tasks.map((task) => (
          <div
            key={task._id}
            className="flex items-center justify-between p-2 rounded-lg shadow-sm"
            style={{
              display: 'flex',
              height: '68px',
              padding: '8px 12px',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              gap: '12px',
              flexShrink: 0,
              alignSelf: 'stretch',
              borderRadius: '8px',
              borderLeft: '4px solid #4ED2BF',
              background: '#FFF',
              boxShadow: '0px 4px 10px rgba(253, 252, 252, 0.94)', // Add box-shadow here
            }}
          >
            <div className="flex items-center space-x-2 flex-1">
              <button
                className="w-5 h-5 rounded-full border-2 border-[#00BFA5] cursor-pointer flex items-center justify-center"
                style={{ backgroundColor: task.completed ? '#00BFA5' : 'transparent' }}
                onClick={() => toggleTaskCompletion(task._id)}
              >
                {task.completed && <CheckOutlined style={{ color: '#fff', fontSize: '12px' }} />}
              </button>           
                <div className="flex items-start flex-col space-y-1 flex-1">
                  <h3 className="text-[#192028] font-sans text-[16px] font-semibold leading-6">{task.name}</h3>
                  <span 
                    className="flex h-4 px-1.5 flex-col justify-center items-center gap-2 rounded-full bg-[rgba(255,65,46,0.20)] text-[#FF412E] whitespace-nowrap text-[10px] font-bold"
                  >
                    {new Date(task.dueDate).toLocaleString()}
                  </span>
                </div>
              </div>

              <div className="flex items-center space-x-12 mt-4">
                <FlagOutlined 
                  className="text-blue-500 bg-white rounded-full bg shadow-[0_0_4px_0_rgba(0,0,0,0.10),_0_2px_0px_0px_#E1E5EC]" 
                  style={{ color: '#FF412E', width: '24px', height: '24px' , justifyContent:'center' }} 
                />
                <BellOutlined 
                  className="text-gray-500 bg-white rounded-full bg shadow-[0_0_4px_0_rgba(0,0,0,0.10),_0_2px_0px_0px_#E1E5EC]" 
                  style={{ color: '#1B2028', width: '24px', height: '24px', justifyContent:'center'  }} 
                />                
               <Dropdown
  overlay={getMenu(task._id)}
  trigger={['click']}
  onVisibleChange={(visible) => setDropdownVisible(visible)}
>
  <MoreOutlined className="text-gray-500 cursor-pointer" />
</Dropdown>

              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center" style={{ minHeight: '196px' }}>
          <img src={TodoSvg} alt="todo svg" style={{ width: '100px' }} />
          <h3 style={{ fontSize: '16px', fontWeight: 600, color: '#192028' }}>You're All Caught Up!</h3>
          <p style={{ fontSize: '12px', color: '#59616E' }}>Create your first task to keep the momentum going.</p>
        </div>
      )}

      <div className="mt-4 flex items-center text-teal-500 cursor-pointer" onClick={showModal}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            borderRadius: '4px',
            border: '1px solid #00BFA5',
            backgroundColor: '#FFFFFF',
            marginRight: '8px',
          }}
        >
          <PlusOutlined style={{ color: '#00BFA5' }} />
        </div>
        <span className="ml-2 text-[14px] leading-[22px] font-medium text-[#59616E] ">
  Add Task
</span>
      </div>

          {/* Add Task Modal */}
      {isModalVisible && (
        <AddTaskModal
          isVisible={isModalVisible}
          handleCancel={handleCancel}
          userId={userId}
          onTaskCreated={handleTaskCreated}
        />
      )}

{editTaskModalVisible && taskToEdit && (
  <EditTaskModal
    visible={editTaskModalVisible}
    onClose={handleEditModalClose}
    task={taskToEdit}
    onTaskUpdated={(updatedTask) => {
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === updatedTask._id ? updatedTask : task
        )
      );
    }}
  />
)}

      {/* Delete Task Modal */}
      {deleteTaskModalVisible && (
        <DeleteTaskModal
          isVisible={deleteTaskModalVisible}
          onClose={handleDeleteModalClose}
          onDelete={confirmDeleteTask}
          task={taskToDelete}
        />
      )}
    </div>
  );
};

export default ToDoList;



