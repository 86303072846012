// import React, { useState } from 'react';
// import { Col, Row } from 'antd';
// import RecentlyModified from '../Components/LandingPage/RecentlyModified';
// import PinnedDocuments from '../Components/LandingPage/PinnedDocument';
// import ToDoList from '../Components/LandingPage/TodoList';
// import MyTeams from '../Components/LandingPage/MyTeams';
// import QuickActions from '../Components/LandingPage/QuickActions';
// import Folders from '../Components/LandingPage/Folders';

// const Dashboard = () => {
//   const [isEmptyState, setIsEmptyState] = useState(false);
//   const [refreshDocs, setRefreshDocs] = useState(false); // State to track refresh

//   const handleRefreshDocs = () => {
//     setRefreshDocs((prev) => !prev); // Toggle to trigger a refresh
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <Row gutter={[16, 16]}>
//         <Col xs={24} md={16}>
//           <Row gutter={[16, 16]}>
//             <Col xs={24}>
//               <div style={{ marginBottom: '20px' }}>
//                 {/* Pass the refresh function to QuickActions */}
//                 <QuickActions onUploadSuccess={handleRefreshDocs} />
//               </div>
//             </Col>
//             <Col xs={24}>
//               <Folders isEmptyState={isEmptyState} />
//             </Col>
//           </Row>
//         </Col>

//         <Col xs={24} md={8}>
//           <Row gutter={[16, 16]}>
//             <Col xs={24}>
//               {/* Placeholder to maintain space for the removed Buttons component */}
//               <div style={{ height: '78px' }}></div>
//             </Col>
//             <Col xs={24}>
//               <PinnedDocuments isEmptyState={isEmptyState} />
//             </Col>
//             <Col xs={24}>
//               <ToDoList isEmptyState={isEmptyState} />
//             </Col>
//             <Col xs={24}>
//               <MyTeams isEmptyState={isEmptyState} />
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
//         <Col xs={24}>
//           {/* Pass refreshDocs state to RecentlyModified */}
//           <RecentlyModified refreshDocs={refreshDocs} />
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default Dashboard;






import React, { useState } from 'react';
import { Col, Row } from 'antd';
import RecentlyModified from '../Components/LandingPage/RecentlyModified';
import PinnedDocuments from '../Components/LandingPage/PinnedDocument';
import ToDoList from '../Components/LandingPage/TodoList';
import MyTeams from '../Components/LandingPage/MyTeams';
import QuickActions from '../Components/LandingPage/QuickActions';
import Folders from '../Components/LandingPage/Folders';

const Dashboard = () => {
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [refreshDocs, setRefreshDocs] = useState(false); // State to track refresh
  const [refreshFolders, setRefreshFolders] = useState(false); 

    const handleRefreshDocs = () => {
    setRefreshDocs((prev) => !prev); // Toggle to trigger a refresh
  };

  const handleRefreshFolders = () => {
    setRefreshFolders((prev) => !prev); // This should force re-render
  };
  
  
  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 16]}>
        {/* Left Side: 60% */}
        <Col xs={24} md={14} lg={14}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
            <QuickActions onUploadSuccess={handleRefreshDocs} onUploadFolderSuccess={handleRefreshFolders} />

            </Col>
            <Col xs={24}>
            <Folders key={refreshFolders} refreshFolders={refreshFolders} />

            </Col>
          </Row>
        </Col>

        {/* Right Side: 40% */}
        <Col xs={24} md={10} lg={10}>
          <Row gutter={[16, 16]}style={{ marginTop: '95px' }}>
            <Col xs={24}>
              <PinnedDocuments isEmptyState={isEmptyState} />
            </Col>
            <Col xs={24}>
              <ToDoList isEmptyState={isEmptyState} />
            </Col>
            <Col xs={24}>
              <MyTeams isEmptyState={isEmptyState} />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Bottom Section */}
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col xs={24}>
        <RecentlyModified refreshDocs={refreshDocs} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;