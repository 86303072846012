

// import React, { useState } from "react";
// import {
//   UploadOutlined,
//   FilterOutlined,
//   BarsOutlined,
//   ArrowUpOutlined,
//   ArrowDownOutlined,
//   FilePdfOutlined,
//   FileImageOutlined,
//   FileExcelOutlined,
//   FilePptOutlined,
//   FileOutlined,
//   CalendarOutlined,
//   FontColorsOutlined,
//   PieChartOutlined,
// } from "@ant-design/icons";
// import { Menu, Dropdown, Button } from "antd";
// import AddDocInsideFolderModal from "../../Services/Modal/AddDocInsideFolderModal";
// import { useAuth } from "../../authcontext";

// const DocHeader = ({
//   filterDocuments = () => {}, // Default function if not passed
//   sortDocuments = () => {},   // Default function if not passed
//   currentSortOption,
//   sortOrder,
//   folderId,
//   onUploadSuccess,
// }) => {
//   const [filterOn, setFilterOn] = useState("");
//   const [filterValue, setFilterValue] = useState("");
//   const [sortOrderState, setSortOrderState] = useState(sortOrder || 1);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [hoveredItem, setHoveredItem] = useState(null);
//   const { userId } = useAuth();

//   const sortOptions = [
//     { label: "Upload Date", value: "uploadedAt", icon: <CalendarOutlined /> },
//     { label: "Name", value: "docName", icon: <FontColorsOutlined /> },
//     { label: "File Size", value: "docSize", icon: <PieChartOutlined /> },
//   ];

//   // Handles sorting by document properties with ascending/descending order
//   const handleSortOrderChange = (newOrder, sortOption) => {
//     setSortOrderState(newOrder);
//     sortDocuments(newOrder, sortOption); // Call the passed sortDocuments function
//   };

//   const handleFilterClick = ({ key }) => {
//     if (key.startsWith("docType_")) {
//       const value = key.split("_")[1]; // Extract document type (e.g., pdf, excel)
//       setFilterOn("docType");
//       setFilterValue(value);
//       filterDocuments("docType", value);
//     } else if (key.startsWith("docSize_")) {
//       const sizeMapping = { 0: "a", 1: "b", 2: "c" }; // Mapping index to letters
//       const index = parseInt(key.split("_")[1], 10);
//       const value = sizeMapping[index] || "a"; // Default to "a" if something goes wrong
  
//       setFilterOn("docSize");
//       setFilterValue(value);
//       filterDocuments("docSize", value);
//     }
//   };

//   const handleUploadSuccess = () => {
//     setIsModalVisible(false); // Close modal
//     if (onUploadSuccess) {
//       onUploadSuccess(); // Call the function to refresh documents
//     }
//   };
  

//   const filterMenu = (
//     <Menu onClick={handleFilterClick}>
//       <Menu.ItemGroup title="File Type">
//         {["pdf", "images", "docs", "excel", "ppt"].map((type) => (
//           <Menu.Item
//             key={`docType_${type}`}
//             style={{
//               ...(hoveredItem === `docType_${type}` ? { backgroundColor: "#D1F8F0" } : {}),
//             }}
//             onMouseEnter={() => setHoveredItem(`docType_${type}`)}
//             onMouseLeave={() => setHoveredItem(null)}
//           >
//             {type === "pdf" && <FilePdfOutlined />}
//             {type === "images" && <FileImageOutlined />}
//             {type === "docs" && <FileOutlined />}
//             {type === "excel" && <FileExcelOutlined />}
//             {type === "ppt" && <FilePptOutlined />}
//             <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
//           </Menu.Item>
//         ))}
//       </Menu.ItemGroup>

//       <Menu.Divider />

//       <Menu.ItemGroup title="File Size">
//         {["Less than 1 MB", "1 MB - 5 MB", "More than 5 MB"].map((size, index) => (
//           <Menu.Item
//             key={`docSize_${index}`}
//             style={{
//               ...(hoveredItem === `docSize_${index}` ? { backgroundColor: "#D1F8F0" } : {}),
//             }}
//             onMouseEnter={() => setHoveredItem(`docSize_${index}`)}
//             onMouseLeave={() => setHoveredItem(null)}
//           >
//             <FileOutlined />
//             <span>{size}</span>
//           </Menu.Item>
//         ))}
//       </Menu.ItemGroup>
//     </Menu>
//   );

//   const sortMenu = (
//     <Menu>
//       {sortOptions.map((option, index) => (
//         <Menu.Item
//           key={option.value}
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             borderRadius: "4px",
//             margin: "4px 0",
//             padding: "8px 12px",
//             color: hoveredItem === index ? "#1B2028" : "#798897",
//             backgroundColor: hoveredItem === index ? "#D1F8F0" : "transparent",
//             transition: "background-color 0.3s ease, color 0.3s ease",
//           }}
//           onMouseEnter={() => setHoveredItem(index)}
//           onMouseLeave={() => setHoveredItem(null)}
//         >
//           <div className="flex items-center justify-between w-full">
//             <div className="flex items-center">
//               {option.icon}
//               <span style={{ marginLeft: "8px" }}>{option.label}</span>
//             </div>
//             <div className="flex items-center">
//               <ArrowUpOutlined
//                 style={{
//                   color:
//                     currentSortOption === option.value && sortOrderState === 1
//                       ? "#41EAD4"
//                       : "#798897",
//                   marginRight: "4px",
//                   fontSize: "12px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleSortOrderChange(1, option.value)}
//               />
//               <ArrowDownOutlined
//                 style={{
//                   color:
//                     currentSortOption === option.value && sortOrderState === -1
//                       ? "#41EAD4"
//                       : "#798897",
//                   fontSize: "12px",
//                   cursor: "pointer",
//                 }}
//                 onClick={() => handleSortOrderChange(-1, option.value)}
//               />
//             </div>
//           </div>
//         </Menu.Item>
//       ))}
//     </Menu>
//   );

//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   return (
//     <div className="flex justify-end items-center h-20 box-border ">
//       {/* Right Section: Filter, Sort By, and Upload Buttons */}
//       <div className="flex items-center gap-6">
//         {/* Filter Button */}
//         <Dropdown overlay={filterMenu} trigger={["click"]}>
//           <Button
//             className="flex items-center px-4 py-2 rounded-lg"
//             style={{
//               width: "98px",
//               justifyContent: "space-between",
//               color: "#000000E0",
//               backgroundColor: "#FFF",
//               borderColor: "#D9D9D9",
//               borderRadius: "8px",
//               padding: "0",
//               height: "40px",
//             }}
//           >
//             <span
//               style={{
//                 flexGrow: 1,
//                 textAlign: "left",
//                 paddingLeft: "16px",
//               }}
//             >
//               Filter
//             </span>
//             <span
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 border: "1px solid #41EAD4",
//                 borderRadius: "4px",
//                 height: "40px",
//                 width: "40px",
//               }}
//             >
//               <FilterOutlined
//                 style={{
//                   color: "#41EAD4",
//                   fontSize: "16px",
//                 }}
//               />
//             </span>
//           </Button>
//         </Dropdown>

//         {/* Sort By Button */}
//         <Dropdown overlay={sortMenu} trigger={["click"]}>
//           <Button
//             className="flex items-center px-4 py-2 rounded-lg"
//             style={{
//               width: "104px",
//               justifyContent: "space-between",
//               color: "#000000E0",
//               backgroundColor: "#FFF",
//               borderColor: "#D9D9D9",
//               borderRadius: "8px",
//               padding: "0",
//               height: "40px",
//             }}
//           >
//             <span
//               style={{
//                 flexGrow: 1,
//                 textAlign: "left",
//                 paddingLeft: "16px",
//               }}
//             >
//               Sort By
//             </span>
//             <span
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 border: "1px solid #41EAD4",
//                 borderRadius: "4px",
//                 height: "40px",
//                 width: "40px",
//               }}
//             >
//               <BarsOutlined
//                 style={{
//                   color: "#41EAD4",
//                   fontSize: "16px",
//                 }}
//               />
//             </span>
//           </Button>
//         </Dropdown>

//         {/* Upload Button */}
//         <Button
//           icon={<UploadOutlined style={{ color: "#FFFFFF" }} />}
//           className="flex items-center justify-center px-4 py-2 rounded-lg"
//           style={{
//             backgroundColor: "#FF6F61",
//             borderColor: "#FF6F61",
//             color: "#FFFFFF",
//             padding: "0px 16px",
//             height: "40px",
//             display: "inline-flex",
//             justifyContent: "center",
//             alignItems: "center",
//             boxShadow: "0px 2px 0px 0px rgba(5, 145, 255, 0.10)",
//           }}
//           onClick={showModal}
//         >
//           Upload Document
//         </Button>
//       </div>

//       {/* AddDocInsideFolderModal Component */}
//       <AddDocInsideFolderModal
//         isVisible={isModalVisible}
//         handleCancel={handleCancel}
//         userId={userId}
//         folderId={folderId}
//         onDocumentAdded={handleUploadSuccess}
//       />
//     </div>
//   );
// };

// export default DocHeader;





















import React, { useState } from "react";
import {
  UploadOutlined,
  FilterOutlined,
  BarsOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileOutlined,
  CalendarOutlined,
  FontColorsOutlined,
  PieChartOutlined,
  DownOutlined,
  FileUnknownOutlined
  
} from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";
import AddDocInsideFolderModal from "../../Services/Modal/AddDocInsideFolderModal";
import { useAuth } from "../../authcontext";

const DocHeader = ({
  filterDocuments = () => {}, // Default function if not passed
  sortDocuments = () => {},   // Default function if not passed
  currentSortOption,
  sortOrder,
  folderId,
  onUploadSuccess,
}) => {
  const [filterOn, setFilterOn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [sortOrderState, setSortOrderState] = useState(sortOrder || 1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { userId } = useAuth();

  const sortOptions = [
    { label: "Upload Date", value: "uploadedAt", icon: <CalendarOutlined /> },
    { label: "Name", value: "docName", icon: <FontColorsOutlined /> },
    { label: "File Size", value: "docSize", icon: <PieChartOutlined /> },
  ];

  // Handles sorting by document properties with ascending/descending order
  const handleSortOrderChange = (newOrder, sortOption) => {
    setSortOrderState(newOrder);
    sortDocuments(newOrder, sortOption); // Call the passed sortDocuments function
  };

  const handleFilterClick = ({ key }) => {
    if (key.startsWith("docType_")) {
      const value = key.split("_")[1]; // Extract document type (e.g., pdf, excel)
      setFilterOn("docType");
      setFilterValue(value);
      filterDocuments("docType", value);
    } else if (key.startsWith("docSize_")) {
      const sizeMapping = { 0: "a", 1: "b", 2: "c" }; // Mapping index to letters
      const index = parseInt(key.split("_")[1], 10);
      const value = sizeMapping[index] || "a"; // Default to "a" if something goes wrong
  
      setFilterOn("docSize");
      setFilterValue(value);
      filterDocuments("docSize", value);
    }
  };

  const handleUploadSuccess = () => {
    setIsModalVisible(false); // Close modal
    if (onUploadSuccess) {
      onUploadSuccess(); // Call the function to refresh documents
    }
  };
  


  const filterMenu = (
    <Menu onClick={handleFilterClick}>
  <Menu.ItemGroup 
     title={
      <>
        <FileOutlined style={{ marginRight: 8, fontWeight: 'bold' }} />
        <span style={{ marginRight: 8, fontWeight: 'bold' }}>File Type</span>
        <DownOutlined style={{ marginLeft: 'auto', fontWeight: 'bold' }} />
      </>
    }
    >
{["pdf", "images", "docs", "excel", "ppt", "misc"].map((type) => (
  <Menu.Item 
    key={`docType_${type}`}
    style={{
      ...(hoveredItem === `docType_${type}` ? { backgroundColor: "#D1F8F0" } : {}),
    }}
    onMouseEnter={() => setHoveredItem(`docType_${type}`)}
    onMouseLeave={() => setHoveredItem(null)}
  >
    {type === "pdf" && <FilePdfOutlined style={{ marginRight: 8 }} />}
    {type === "images" && <FileImageOutlined style={{ marginRight: 8 }} />}
    {type === "docs" && <FileOutlined style={{ marginRight: 8 }} />}
    {type === "excel" && <FileExcelOutlined style={{ marginRight: 8 }} />}
    {type === "ppt" && <FilePptOutlined style={{ marginRight: 8 }} />}
    {type === "misc" && <FileUnknownOutlined style={{ marginRight: 8 }} />}
    
    <span>
      {type === "misc" ? "Miscellaneous" : type.charAt(0).toUpperCase() + type.slice(1)}
    </span>
  </Menu.Item>
))}

      </Menu.ItemGroup>
  
      <Menu.Divider />
  
      <Menu.ItemGroup 
      title={
        <>
          <PieChartOutlined style={{ marginRight: 8,fontWeight: 'bold' }} />
          <span style={{ marginRight: 8, fontWeight: 'bold' }}>File Size</span>
          <DownOutlined style={{ marginLeft: 'auto', fontWeight: 'bold' }} />
        </>
      }
    >
      {["Less than 1 MB", "1 MB - 5 MB", "More than 5 MB"].map((size, index) => (
        <Menu.Item
          key={`docSize_${index}`}
          style={{
            ...(hoveredItem === `docSize_${index}` ? { backgroundColor: "#D1F8F0" } : {}),
          }}
          onMouseEnter={() => setHoveredItem(`docSize_${index}`)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <FileOutlined style={{ marginRight: 8 }} />
          <span>{size}</span>
        </Menu.Item>
      ))}
    </Menu.ItemGroup>
    </Menu>
  );
  

  const sortMenu = (
    <Menu>
      {sortOptions.map((option, index) => (
        <Menu.Item
          key={option.value}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "4px",
            margin: "4px 0",
            padding: "8px 12px",
            color: hoveredItem === index ? "#1B2028" : "#798897",
            backgroundColor: hoveredItem === index ? "#D1F8F0" : "transparent",
            transition: "background-color 0.3s ease, color 0.3s ease",
          }}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              {option.icon}
              <span style={{ marginLeft: "8px" }}>{option.label}</span>
            </div>
            <div className="flex items-center">
              <ArrowUpOutlined
                style={{
                  color:
                    currentSortOption === option.value && sortOrderState === 1
                      ? "#41EAD4"
                      : "#798897",
                  marginRight: "4px",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleSortOrderChange(1, option.value)}
              />
              <ArrowDownOutlined
                style={{
                  color:
                    currentSortOption === option.value && sortOrderState === -1
                      ? "#41EAD4"
                      : "#798897",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleSortOrderChange(-1, option.value)}
              />
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex justify-end items-center h-20 box-border ">
      {/* Right Section: Filter, Sort By, and Upload Buttons */}
      <div className="flex items-center gap-6">
        {/* Filter Button */}
        <Dropdown overlay={filterMenu} trigger={["click"]}>
          <Button
            className="flex items-center px-4 py-2 rounded-lg"
            style={{
              width: "98px",
              justifyContent: "space-between",
              color: "#000000E0",
              backgroundColor: "#FFF",
              borderColor: "#D9D9D9",
              borderRadius: "8px",
              padding: "0",
              height: "40px",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                textAlign: "left",
                paddingLeft: "16px",
              }}
            >
              Filter
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #41EAD4",
                borderRadius: "4px",
                height: "40px",
                width: "40px",
              }}
            >
              <FilterOutlined
                style={{
                  color: "#41EAD4",
                  fontSize: "16px",
                }}
              />
            </span>
          </Button>
        </Dropdown>

        {/* Sort By Button */}
        <Dropdown overlay={sortMenu} trigger={["click"]}>
          <Button
            className="flex items-center px-4 py-2 rounded-lg"
            style={{
              width: "104px",
              justifyContent: "space-between",
              color: "#000000E0",
              backgroundColor: "#FFF",
              borderColor: "#D9D9D9",
              borderRadius: "8px",
              padding: "0",
              height: "40px",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                textAlign: "left",
                paddingLeft: "16px",
              }}
            >
              Sort By
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #41EAD4",
                borderRadius: "4px",
                height: "40px",
                width: "40px",
              }}
            >
              <BarsOutlined
                style={{
                  color: "#41EAD4",
                  fontSize: "16px",
                }}
              />
            </span>
          </Button>
        </Dropdown>

        {/* Upload Button */}
        <Button
          icon={<UploadOutlined style={{ color: "#FFFFFF" }} />}
          className="flex items-center justify-center px-4 py-2 rounded-lg"
          style={{
            backgroundColor: "#FF6F61",
            borderColor: "#FF6F61",
            color: "#FFFFFF",
            padding: "0px 16px",
            height: "40px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 2px 0px 0px rgba(5, 145, 255, 0.10)",
          }}
          onClick={showModal}
        >
          Upload Document
        </Button>
      </div>

      {/* AddDocInsideFolderModal Component */}
      <AddDocInsideFolderModal
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        userId={userId}
        folderId={folderId}
        onDocumentAdded={handleUploadSuccess}
      />
    </div>
  );
};

export default DocHeader;