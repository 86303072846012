

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { Table, Typography, Space, Spin } from "antd";
// import { useAuth } from "../../authcontext";
// import { useNavigate } from "react-router-dom";

// // Importing SVG icons for different file types
// import Image from '../../../assets/SVG/JPG.svg';
// import Png from '../../../assets/SVG/PNG.svg';
// import Docs from '../../../assets/SVG/Docs.svg';
// import Pdf from '../../../assets/SVG/Pdf.svg';
// import Html from '../../../assets/SVG/Html.svg';
// import Excel from '../../../assets/SVG/XLSX.svg';
// import Ppt from '../../../assets/SVG/PPT.svg';
// import Misc from '../../../assets/SVG/MiscIcon.svg';
// import NoDataSvg from "../../../assets/SVG/RecentModified.svg";

// // File type mapping
// const fileTypeMapping = {
//   "jpg": Image,
//   "jpeg": Image,
//   "png": Png,
//   "pdf": Pdf,
//   "xls": Excel,
//   "xlsx": Excel,
//   "doc": Docs,
//   "docx": Docs,
//   "ppt": Ppt,
//   "pptx": Ppt,
//   "txt": Html,
//   "csv": Excel,
// };

// const getDocumentIcon = (docType) => {
//   return fileTypeMapping[docType.toLowerCase()] || Misc;
// };

// const RecentlyModified = ({ refreshDocs }) => {
//   const { authenticated } = useAuth();
//   const [documents, setDocuments] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);
//   const [sortBy, setSortBy] = useState("uploadedAt");
//   const [sortOrder, setSortOrder] = useState(-1);
//   const navigate = useNavigate();
//   const tableContainerRef = useRef(null);

//   // Fetch document data
//   const fetchDocumentData = async (pageNumber, reset = false) => {
//     if (!authenticated || !hasMore) return;
    
//     setLoading(true);
//     try {
//       const params = { forDashboard: true, sortBy, sortOrder };
//       if (pageNumber > 0) params.page = pageNumber;
      
//       const response = await axios.get(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/`,
//         { params }
//       );
      
//       const newDocs = response.data.data.finalList;
//       const moreToCome = response.data.data.moreToCome;

//       setDocuments((prev) => (reset ? newDocs : [...prev, ...newDocs]));
//       setHasMore(moreToCome);
//     } catch (error) {
//       console.error("Error fetching documents:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Reset page when sorting changes
//   useEffect(() => {
//     setPage(0);
//     setHasMore(true);
//     fetchDocumentData(0, true);
//   }, [authenticated, sortBy, sortOrder, refreshDocs]);
  
//   // Preserve scroll position on data update
//   useEffect(() => {
//     if (page === 0) return;
//     const tableDiv = tableContainerRef.current;
//     if (tableDiv) {
//       const previousScrollTop = tableDiv.scrollTop;
//       fetchDocumentData(page);
//       setTimeout(() => {
//         tableDiv.scrollTop = previousScrollTop;
//       }, 100);
//     }
//   }, [page]);

//   // Infinite scrolling
//   const handleScroll = () => {
//     if (!tableContainerRef.current || loading || !hasMore) return;

//     const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;

//     if (scrollTop + clientHeight >= scrollHeight - 50) {
//       setPage((prevPage) => prevPage + 1);
//     }
//   };

//   // Attach scroll event listener
//   useEffect(() => {
//     const tableDiv = tableContainerRef.current;
//     if (tableDiv) {
//       tableDiv.addEventListener("scroll", handleScroll);
//     }
//     return () => {
//       if (tableDiv) {
//         tableDiv.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [loading, hasMore]);

//   // Handle sorting
//   const handleTableChange = (_, __, sorter) => {
//     if (sorter.order) {
//       setSortBy(sorter.field);
//       setSortOrder(sorter.order === "ascend" ? 1 : -1);
//     } else {
//       setSortBy("uploadedAt");
//       setSortOrder(-1);
//     }
//   };

//   // Handle document click
//   const handleDocumentClick = (docId) => {
//     navigate(`/documents/${docId}/versions`);
//   };

//   // Table columns
//   const columns = [
//     {
//       title: "Documents",
//       dataIndex: "docName",
//       key: "docName",
//       sorter: true,
//       render: (text, record) => (
//         <Space>
//           <img src={getDocumentIcon(record.docType)} alt={record.docType} style={{ width: 24 }} />
//           <div style={{ cursor: "pointer" }} onClick={() => handleDocumentClick(record._id)}>
//             <Typography.Text>{text || "Untitled"}</Typography.Text>
//           </div>
//         </Space>
//       ),
//     },
//     {
//       title: "Owner",
//       dataIndex: "userName",
//       key: "userName",
//     },
//     {
//       title: "Upload Date",
//       dataIndex: "uploadedAt",
//       key: "uploadedAt",
//       sorter: true,
//       render: (text) => new Date(text).toLocaleDateString("en-GB"),
//     },
//     {
//       title: "Type",
//       dataIndex: "docType",
//       key: "docType",
//       render: (text) => text.toUpperCase(),
//     },
//     {
//       title: "Size",
//       dataIndex: "docSize",
//       key: "docSize",
//       sorter: true,
//       render: (size) => (size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / (1024 * 1024)).toFixed(2)} MB`),
//     },
//     {
//       title: "Last Modified",
//       dataIndex: "updatedAt",
//       key: "updatedAt",
//       render: (text) => new Date(text).toLocaleDateString("en-GB"),
//     },
//     {
//       title: "Versions",
//       dataIndex: "totalVersions",
//       key: "totalVersions",
//       render: (text) => text || 1,
//     },
//   ];

//   return (
//     <div style={{ padding: "24px", background: "#fff" }}>
//       {/* Sticky Heading */}
//       <Typography.Title level={4} style={{ position: "sticky", top: 0, zIndex: 10, background: "#fff", padding: "10px" }}>
//         Recently Uploaded
//       </Typography.Title>     

//       {/* Table Container - Only scrolls when there is data */}
//       <div 
//         ref={tableContainerRef} 
//         style={{
//           height: documents.length > 0 ? "calc(60vh)" : "auto", 
//           overflowY: documents.length > 0 ? "auto" : "hidden",
//           overflowX: "hidden"
//         }}
//       >
//         <Table
//           dataSource={documents}
//           columns={columns}
//           rowKey="_id"
//           pagination={false}
//           onChange={handleTableChange}
//           sticky={{ offsetHeader: 0 }}
//           locale={{
//             emptyText: (
//               <div style={{ textAlign: "center" }}>
//                 <img src={NoDataSvg} alt="No Data" />
//                 <Typography.Text>No Documents Available</Typography.Text>
//               </div>
//             ),
//           }}
//         />
//         {loading && hasMore && (
//           <div style={{ textAlign: "center", marginTop: 20 }}>
//             <Spin size="large" />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default RecentlyModified;












import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table, Typography, Space, Spin } from "antd";
import { useAuth } from "../../authcontext";
import { useNavigate } from "react-router-dom";

// Importing SVG icons for different file types
import Image from '../../../assets/SVG/JPG.svg';
import Png from '../../../assets/SVG/PNG.svg';
import Docs from '../../../assets/SVG/Docs.svg';
import Pdf from '../../../assets/SVG/Pdf.svg';
import Html from '../../../assets/SVG/Html.svg';
import Excel from '../../../assets/SVG/XLSX.svg';
import Ppt from '../../../assets/SVG/PPT.svg';
import Misc from '../../../assets/SVG/MiscIcon.svg';
import NoDataSvg from "../../../assets/SVG/RecentModified.svg";

// File type mapping
const fileTypeMapping = {
  "jpg": Image,
  "jpeg": Image,
  "png": Png,
  "pdf": Pdf,
  "xls": Excel,
  "xlsx": Excel,
  "doc": Docs,
  "docx": Docs,
  "ppt": Ppt,
  "pptx": Ppt,
  "txt": Html,
  "csv": Excel,
};

const getDocumentIcon = (docType) => {
  return fileTypeMapping[docType.toLowerCase()] || Misc;
};

const RecentlyModified = ({ refreshDocs }) => {
  const { authenticated } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState("uploadedAt");
  const [sortOrder, setSortOrder] = useState(-1);
  const navigate = useNavigate();
  const tableContainerRef = useRef(null);

  // Fetch document data
  const fetchDocumentData = async (pageNumber, reset = false) => {
    if (!authenticated || !hasMore) return;
    
    setLoading(true);
    try {
      const params = { forDashboard: true, sortBy, sortOrder };
      if (pageNumber > 0) params.page = pageNumber;
      
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/`,
        { params }
      );
      
      const newDocs = response.data.data.finalList;
      const moreToCome = response.data.data.moreToCome;

      setDocuments((prev) => (reset ? newDocs : [...prev, ...newDocs]));
      setHasMore(moreToCome);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  // Reset page when sorting changes
  useEffect(() => {
    setPage(0);
    setHasMore(true);
    fetchDocumentData(0, true);
  }, [authenticated, sortBy, sortOrder, refreshDocs]);
  
  // Preserve scroll position on data update
  useEffect(() => {
    if (page === 0) return;
    const tableDiv = tableContainerRef.current;
    if (tableDiv) {
      const previousScrollTop = tableDiv.scrollTop;
      fetchDocumentData(page);
      setTimeout(() => {
        tableDiv.scrollTop = previousScrollTop;
      }, 100);
    }
  }, [page]);

  // Infinite scrolling
  const handleScroll = () => {
    if (!tableContainerRef.current || loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const tableDiv = tableContainerRef.current;
    if (tableDiv) {
      tableDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableDiv) {
        tableDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  // Handle sorting
  const handleTableChange = (_, __, sorter) => {
    if (sorter.order) {
      setSortBy(sorter.field);
      setSortOrder(sorter.order === "ascend" ? 1 : -1);
    } else {
      setSortBy("uploadedAt");
      setSortOrder(-1);
    }
  };

  // Handle document click
  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  // Table columns
  const columns = [
    {
      title: "Documents",
      dataIndex: "docName",
      key: "docName",
      sorter: true,
      render: (text, record) => (
        <Space>
          <img src={getDocumentIcon(record.docType)} alt={record.docType} style={{ width: 24 }} />
          <div style={{ cursor: "pointer" }} onClick={() => handleDocumentClick(record._id)}>
            <Typography.Text>{text || "Untitled"}</Typography.Text>
          </div>
        </Space>
      ),
    },
    {
      title: "Owner",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Upload Date",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      sorter: true,
      render: (text) => new Date(text).toLocaleDateString("en-GB"),
    },
    {
      title: "Type",
      dataIndex: "docType",
      key: "docType",
      render: (text) => text.toUpperCase(),
    },
    {
      title: "Size",
      dataIndex: "docSize",
      key: "docSize",
      sorter: true,
      render: (size) => (size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / (1024 * 1024)).toFixed(2)} MB`),
    },
    {
      title: "Last Modified",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleDateString("en-GB"),
    },
    {
      title: "Versions",
      dataIndex: "totalVersions",
      key: "totalVersions",
      render: (text) => text || 1,
    },
  ];

  return (
    <div style={{ padding: "24px", background: "#fff" }}>
      {/* Sticky Heading */}
      <Typography.Title level={4} style={{ position: "sticky", top: 0, zIndex: 10, background: "#fff", padding: "10px" }}>
        Recently Uploaded
      </Typography.Title>     

      {/* Table Container - Only scrolls when there is data */}
      <div 
        ref={tableContainerRef} 
        style={{
          height: documents.length > 0 ? "calc(60vh)" : "auto", 
          overflowY: documents.length > 0 ? "auto" : "hidden",
          overflowX: "hidden"
        }}
      >
        <Table
          dataSource={documents}
          columns={columns}
          rowKey="_id"
          pagination={false}
          onChange={handleTableChange}
          sticky={{ offsetHeader: 0 }}
          locale={{
            emptyText: (
              <div style={{ textAlign: "center" }}>
                <img src={NoDataSvg} alt="No Data" />
                <Typography.Text>No Documents Available</Typography.Text>
              </div>
            ),
          }}
        />
        {loading && hasMore && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentlyModified;
