import React from "react";
import TrashAllDocs from "../Components/Trash/TrashAllDocs";

const Trash = () => {
  return (
    <div>
      <TrashAllDocs />
    </div>
  );
};

export default Trash;
