// import React, { useState, useEffect } from "react";
// import { Tag, Button, Input, message } from "antd";
// import { PlusOutlined, ShareAltOutlined, CloudDownloadOutlined, CloseOutlined } from "@ant-design/icons";
// import ShareVersionModal from "../../Services/ShareModal/ShareVersionModal";
// import { useAuth } from "../../authcontext";

// const InfoTab = ({ documentData, localUrl }) => {
//   const { userId, vaultStatus } = useAuth();
//   const [isShareModalVisible, setIsShareModalVisible] = useState(false);
//   const [tags, setTags] = useState(documentData?.tags || []);
//   const [newTag, setNewTag] = useState("");
//   const sessionToken = localStorage.getItem("sessionToken");

//   useEffect(() => {
//     setTags(documentData?.tags || []);
//   }, [documentData]);

//   const handleDownload = () => {
//     console.log("Download button clicked");
//   };

//   const handleShare = () => {
//     setIsShareModalVisible(true);
//   };

//   const handleCancelShareModal = () => {
//     setIsShareModalVisible(false);
//   };

//   const addTag = async () => {
//     if (!newTag.trim()) return;

//     try {
//       const response = await fetch(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentData?._id}/tags`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${sessionToken}`
//         },
//         body: JSON.stringify({ tags: [...tags, newTag] }),
//       });

//       if (response.ok) {
//         setTags([...tags, newTag]);
//         setNewTag("");
//         message.success("Tag added successfully!");
//       } else {
//         message.error("Failed to add tag");
//       }
//     } catch (error) {
//       message.error("Error adding tag");
//     }
//   };

//   const removeTag = async (tagToRemove) => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentData?._id}/tags`, {
//         method: "DELETE",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${sessionToken}`
//         },
//         body: JSON.stringify({ removeTags: [tagToRemove] }),
//       });

//       if (response.ok) {
//         setTags(tags.filter(tag => tag !== tagToRemove));
//         message.success("Tag removed successfully!");
//       } else {
//         message.error("Failed to remove tag");
//       }
//     } catch (error) {
//       message.error("Error removing tag");
//     }
//   };

//   return (
//     <>
//       {/* Document Information */}
//       <div className="grid grid-cols-2 w-full mb-2">
//         <p className="text-[#768090]"><strong>File name:</strong> {documentData?.docName}</p>
//         <p className="text-right text-[#768090]"><strong>Owner:</strong> {documentData?.userName}</p>
//       </div>
//       <div className="grid grid-cols-2 w-full mb-2">
//         <p className="text-[#768090]"><strong>Uploaded:</strong> {new Date(documentData?.uploadedAt).toLocaleDateString()}</p>
//         <p className="text-right text-[#768090]"><strong>Updated:</strong> {new Date(documentData?.updatedAt).toLocaleDateString()}</p>
//       </div>
//       <div className="grid grid-cols-2 w-full mb-4">
//         <p className="text-[#768090]"><strong>Folder location:</strong> {documentData?.folder}</p>
//       </div>

//       {/* Tags Section */}
//       <div className="mt-4">
//         <p className="text-[#768090] mb-1">Tags</p>
//         <div className="flex gap-2 flex-wrap mb-4">
//           {tags.map((tag, index) => (
//             <Tag
//               key={index}
//               className="bg-[#D1F8F0] text-[#007768] px-2 py-1 rounded flex items-center"
//               closable
//               onClose={() => removeTag(tag)}
//               closeIcon={<CloseOutlined className="text-[#007768]" />}
//             >
//               #{tag}
//             </Tag>
//           ))}
//         </div>
//         <div className="flex gap-2 items-center">
//           <Input
//             placeholder="Add a tag"
//             value={newTag}
//             onChange={(e) => setNewTag(e.target.value)}
//             onPressEnter={addTag}
//             className="w-[200px]"
//           />
//           <Button icon={<PlusOutlined />} onClick={addTag}>
//             Add Tag
//           </Button>
//         </div>
//         <hr className="my-4 border-t border-gray-300" />
//       </div>

//       {/* Buttons Section */}
//       <div className="flex justify-between items-center mt-4">
//         {/* Download Button */}
//         <Button
//           type="default"
//           icon={<CloudDownloadOutlined />}
//           className="bg-[#D1F8F0] text-[#007768] border border-[#D1F8F0] px-4 py-2 rounded-lg hover:bg-[#eafaf4]"
//           onClick={handleDownload}
//         >
//           Download
//         </Button>

//         {/* Share Button (Only if the user is the owner) */}
//         {userId === documentData?.userId && (
//           <Button
//             type="default"
//             icon={<ShareAltOutlined />}
//             className=" bg-[#D1F8F0] text-[#007768] border border-[#D1F8F0] px-4 py-2 rounded-lg hover:bg-[#eafaf4]"
//             onClick={handleShare}
//           >
//             Share
//           </Button>
//         )}
//       </div>

//       {/* Share Modal */}
//       <ShareVersionModal
//         isVisible={isShareModalVisible}
//         handleCancel={handleCancelShareModal}
//         docId={documentData?._id}
//       />
//     </>
//   );
// };

// export default InfoTab;


import React, { useState, useEffect } from "react";
import { Tag, Button, Input, message, Modal, Spin } from "antd";
import {
  PlusOutlined,
  ShareAltOutlined,
  CloudDownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ShareVersionModal from "../../Services/ShareModal/ShareVersionModal";
import { useAuth } from "../../authcontext";

const InfoTab = ({ documentData, selectedVersion }) => {
  const { userId, vaultStatus } = useAuth();
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [tags, setTags] = useState(documentData?.tags || []);
  const [newTag, setNewTag] = useState("");
  const sessionToken = localStorage.getItem("sessionToken");
  const vaultToken = localStorage.getItem("vaultToken");
  const [downloading, setDownloading] = useState(false);

  const headers = vaultStatus
    ? {
      Authorization: `Bearer ${sessionToken}`,
      vaultToken: vaultToken,
      secureVault: true,
      "Content-Type": "application/json",
    }
    : {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };

  useEffect(() => {
    setTags(documentData?.tags || []);
  }, [documentData]);

  const handleShare = () => {
    setIsShareModalVisible(true);
  };

  const handleCancelShareModal = () => {
    setIsShareModalVisible(false);
  };

  const checkPermission = () => {
    if (!documentData) return false;
    if (documentData.userId === userId) {
      return true; // Owner has modify permission by default
    }
  };

  const handleDownload = async (version) => {
    setDownloading(true);
    try {
      const url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?versionId=${version.versionId}&fileName=${documentData.bucketKey}`;
      const sessionToken = localStorage.getItem("sessionToken");
      const vaultToken = localStorage.getItem("vaultToken");
      const headers = vaultStatus
        ? {
          Authorization: `Bearer ${sessionToken}`,
          vaultToken: vaultToken,
          secureVault: true,
        }
        : {
          Authorization: `Bearer ${sessionToken}`,
        };
      // const response = await fetch(url, { method: "GET", headers });
      // if (!response.ok)
      //   throw new Error(`HTTP error! Status: ${response.status}`);
      // const blob = await response.blob();
      // const link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download = documentData.bucketKey;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      window.location.href = url;

    } catch (error) {
      console.error("Error downloading document:", error);
      Modal.error({
        title: "Download Failed",
        content: "Error downloading the document. Please try again.",
      });
    } finally {
      setTimeout(() => {
        setDownloading(false);
      }, 3000)
    }
  };

  const addTag = async () => {
    if (!newTag.trim()) {
      message.warning("Tag cannot be empty!");
      return;
    }
    if (newTag.length > 12) {
      message.warning("Tag cannot exceed 12 characters!");
      return;
    }
    if (tags.length >= 5) {
      message.warning("You can only add up to 5 tags!");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentData?._id}/tags`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ tags: [...tags, newTag] }),
        }
      );

      if (response.ok) {
        setTags([...tags, newTag]);
        setNewTag("");
        message.success("Tag added successfully!");
      } else {
        message.error("Failed to add tag");
      }
    } catch (error) {
      message.error("Error adding tag");
    }
  };

  const removeTag = async (tagToRemove) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentData?._id}/tags`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ removeTags: [tagToRemove] }),
        }
      );

      if (response.ok) {
        setTags(tags.filter((tag) => tag !== tagToRemove));
        message.success("Tag removed successfully!");
      } else {
        message.error("Failed to remove tag");
      }
    } catch (error) {
      message.error("Error removing tag");
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]">
          <strong>File name:</strong> {documentData?.docName}
        </p>
        <p className="text-right text-[#768090]">
          <strong>Owner:</strong> {documentData?.userName}
        </p>
      </div>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]">
          <strong>Uploaded:</strong>{" "}
          {new Date(documentData?.uploadedAt).toLocaleDateString()}
        </p>
        <p className="text-right text-[#768090]">
          <strong>Updated:</strong>{" "}
          {new Date(documentData?.updatedAt).toLocaleDateString()}
        </p>
      </div>
      <div className="grid grid-cols-2 w-full mb-4">
        <p className="text-[#768090]">
          <strong>Folder location:</strong> {documentData?.folder}
        </p>
      </div>

      {checkPermission() && (
        <div className="mt-4">
          <p className="text-[#768090] mb-1">Tags</p>
          <div className="flex gap-2 flex-wrap mb-4">
            {tags.map((tag, index) => (
              <Tag
                key={index}
                className="bg-[#D1F8F0] text-[#007768] px-2 py-1 rounded flex items-center"
                closable
                onClose={() => removeTag(tag)}
                closeIcon={<CloseOutlined className="text-[#007768]" />}
              >
                #{tag}
              </Tag>
            ))}
          </div>
          <div className="flex gap-2 items-center">
            <Input
              placeholder="Add a tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onPressEnter={addTag}
              className="w-[200px]"
            />
            <Button icon={<PlusOutlined />} onClick={addTag}>
              Add Tag
            </Button>
          </div>
          <hr className="my-4 border-t border-gray-300" />
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <Button
          type="default"
          className="bg-[#D1F8F0] text-[#007768] border border-[#D1F8F0] px-4 py-2 rounded-lg hover:bg-[#eafaf4] flex items-center"
          onClick={() => handleDownload(selectedVersion)}
          disabled={downloading} // Disable button while downloading
        >
          {downloading ? (
            <Spin size="small" className="mr-2" />
          ) : (
            <CloudDownloadOutlined className="mr-2" />
          )}
          Download
        </Button>

        {userId === documentData?.userId && (
          <Button
            type="default"
            icon={<ShareAltOutlined />}
            className=" bg-[#D1F8F0] text-[#007768] border border-[#D1F8F0] px-4 py-2 rounded-lg hover:bg-[#eafaf4]"
            onClick={handleShare}
          >
            Share
          </Button>
        )}
      </div>

      <ShareVersionModal
        isVisible={isShareModalVisible}
        handleCancel={handleCancelShareModal}
        docId={documentData?._id}
      />
    </>
  );
};

export default InfoTab;
