





import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  UploadOutlined,
  FilterOutlined,
  BarsOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileOutlined,
  CalendarOutlined,
  FontColorsOutlined,
  PieChartOutlined,
  DownOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown, Button, Tabs, message } from "antd";
import AddDocVaultModal from "../../../Services/Modal/AddDocVaultModal";
import { useAuth } from "../../../authcontext";
import DocCard from "../../../Services/UI/DocCard";
import DefaultDocEmpty from "../../../Services/UI/EmptyState/DefaultDocEmpty";
import DocLoading from "../../../Services/UI/LoadingState/DocLoading";

const { TabPane } = Tabs;

const VaultHeader = ({ folderId }) => {
  // State variables
  const [activeTab, setActiveTab] = useState("own"); // Tracks the active tab
  const [documents, setDocuments] = useState([]); // Stores the list of documents
  const [loading, setLoading] = useState(false); // Tracks loading state
  const [moreToCome, setMoreToCome] = useState(true); // Tracks if more documents are available
  const [currentPage, setCurrentPage] = useState(0); // Tracks the current page for pagination
  const [isModalVisible, setIsModalVisible] = useState(false); // Controls modal visibility
  const [currentSortOption, setCurrentSortOption] = useState("uploadedAt"); // Tracks the current sort option
  const [sortOrderState, setSortOrderState] = useState(-1); // Tracks the sort order (1 for ascending, -1 for descending)
  const loaderRef = useRef(null); // Ref for infinite scroll
  const [hoveredItem, setHoveredItem] = useState(null); // Tracks hovered menu items
  const { userId } = useAuth(); // User ID from authentication context
  const [filterOn, setFilterOn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  // const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredGroup, setHoveredGroup] = useState(null);

  // Tabs configuration
  const tabs = [
    { label: "Uploaded by you", value: "own" },
    { label: "Shared Documents", value: "org" },
    { label: "Team Documents", value: "team" },
    { label: "Pinned Documents", value: "pinned" },
  ];

  // Sort options configuration
  const sortOptions = [
    { label: "Upload Date", value: "uploadedAt", icon: <CalendarOutlined /> },
    { label: "Name", value: "docName", icon: <FontColorsOutlined /> },
    { label: "File Size", value: "docSize", icon: <PieChartOutlined /> },
  ];

  // Fetch documents from the API
  const fetchDocuments = async (type, page) => {
    try {
      setLoading(true);
      const baseUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents`;
      let url = `${baseUrl}/list/?defaultType=${type}&sortBy=${currentSortOption}&sortOrder=${sortOrderState}`;

      // Add filter parameters if a filter is active
      if (filterOn && filterValue) {
        url += `&filterOn=${filterOn}&filterValue=${filterValue}`;
      }

      // Add pagination parameter
      if (page > 0) {
        url += `&page=${page}`;
      }

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          vaultToken: localStorage.getItem("vaultToken"),
          SecureVault: true,
        },
      });

      const fetchedDocuments = response.data.data.finalList || [];

      if (page === 0) setDocuments(fetchedDocuments);
      else setDocuments((prevDocs) => [...prevDocs, ...fetchedDocuments]);

      setMoreToCome(response.data.data.moreToCome);
    } catch (error) {
      console.error("Error fetching documents:", error);
      message.error("Failed to fetch documents. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch documents when the component mounts or when dependencies change
  useEffect(() => {
    fetchDocuments(activeTab, 0);
  }, [activeTab, currentSortOption, sortOrderState, filterOn, filterValue]);

  // Infinite scroll implementation
  useEffect(() => {
    if (!loaderRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && moreToCome && !loading) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [loaderRef, moreToCome, loading]);

  // Fetch more documents when the page changes
  useEffect(() => {
    if (currentPage > 0) fetchDocuments(activeTab, currentPage);
  }, [currentPage]);

  // Handle tab change
  const handleTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(0);
    setDocuments([]);
    setFilterOn(null); // Reset filter type
    setFilterValue(null); // Reset filter value
  };

  // Handle sort order change
  const handleSortOrderChange = (newOrder, sortOption) => {
    setSortOrderState(newOrder);
    setCurrentSortOption(sortOption);
  };

  // Handle filter selection
  const handleFilterClick = ({ key }) => {
    if (key.startsWith("docType_")) {
      const value = key.split("_")[1]; // Extract document type (e.g., "pdf", "images")
      setFilterOn("docType");
      setFilterValue(value);
    } else if (key.startsWith("docSize_")) {
      const value = key.split("_")[1]; // Extract document size category (e.g., "a", "b", "c")
      setFilterOn("docSize");
      setFilterValue(value);
    }

    // Reset pagination and fetch documents with the new filter
    setCurrentPage(0);
    fetchDocuments(activeTab, 0);
  };

  const refreshVaultDocuments = () => {
    // Logic to refresh the vault documents
    console.log("Refreshing vault documents...");
  };
  

  // Filter menu configuration
  const filterMenu = (
    <Menu onClick={handleFilterClick}>
      {/* File Type Group */}
      <Menu.ItemGroup
        title={
          <div
            style={{
              backgroundColor:
                hoveredGroup === "fileType" ? "#D1F8F0" : "transparent",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
            onMouseEnter={() => setHoveredGroup("fileType")}
            onMouseLeave={() => setHoveredGroup(null)}
          >
            <FileOutlined style={{ marginRight: 8, fontWeight: "bold" }} />
            <span
              style={{ fontWeight: "500", color: "#000", marginRight: "4px" }}
            >
              File Type
            </span>
            <DownOutlined style={{ marginLeft: "4px", fontWeight: "bold" }} />
          </div>
        }
      >
        {["pdf", "images", "docs", "excel", "ppt", "misc"].map((type) => (
          <Menu.Item
            key={`docType_${type}`}
            style={{
              backgroundColor:
                hoveredItem === `docType_${type}` ? "#D1F8F0" : "transparent",
            }}
            onMouseEnter={() => setHoveredItem(`docType_${type}`)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {type === "pdf" && <FilePdfOutlined style={{ marginRight: 8 }} />}
            {type === "images" && (
              <FileImageOutlined style={{ marginRight: 8 }} />
            )}
            {type === "docs" && <FileOutlined style={{ marginRight: 8 }} />}
            {type === "excel" && (
              <FileExcelOutlined style={{ marginRight: 8 }} />
            )}
            {type === "ppt" && <FilePptOutlined style={{ marginRight: 8 }} />}
            {type === "misc" && (
              <FileUnknownOutlined style={{ marginRight: 8 }} />
            )}
            <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>

      <Menu.Divider />

      {/* File Size Group */}
      <Menu.ItemGroup
        title={
          <div
            style={{
              backgroundColor:
                hoveredGroup === "fileSize" ? "#D1F8F0" : "transparent",
              padding: "5px 8x",
              borderRadius: "5px",
            }}
            onMouseEnter={() => setHoveredGroup("fileSize")}
            onMouseLeave={() => setHoveredGroup(null)}
          >
            <PieChartOutlined style={{ marginRight: 8, fontWeight: "bold" }} />
            <span
              style={{ fontWeight: "500", color: "#000", marginRight: "4px" }}
            >
              File Size
            </span>
            <DownOutlined style={{ marginLeft: "4px", fontWeight: "bold" }} />
          </div>
        }
      >
        {["a", "b", "c"].map((size) => (
          <Menu.Item
            key={`docSize_${size}`}
            style={{
              backgroundColor:
                hoveredItem === `docSize_${size}` ? "#D1F8F0" : "transparent",
            }}
            onMouseEnter={() => setHoveredItem(`docSize_${size}`)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <FileOutlined style={{ marginRight: 8 }} />
            <span>
              {size === "a"
                ? "Less than 1 MB"
                : size === "b"
                ? "1 MB - 5 MB"
                : "More than 5 MB"}
            </span>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  // Sort menu configuration
  const sortMenu = (
    <Menu>
      {sortOptions.map((option, index) => (
        <Menu.Item
          key={option.value}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: hoveredItem === index ? "#1B2028" : "#798897",
            backgroundColor: hoveredItem === index ? "#D1F8F0" : "transparent",
          }}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              {option.icon}
              <span style={{ marginLeft: "8px" }}>{option.label}</span>
            </div>
            <div className="flex items-center">
              <ArrowUpOutlined
                style={{
                  color:
                    currentSortOption === option.value && sortOrderState === 1
                      ? "#41EAD4"
                      : "#798897",
                  marginRight: "4px",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleSortOrderChange(1, option.value)}
              />
              <ArrowDownOutlined
                style={{
                  color:
                    currentSortOption === option.value && sortOrderState === -1
                      ? "#41EAD4"
                      : "#798897",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleSortOrderChange(-1, option.value)}
              />
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  // Function to refresh documents after a successful upload
  const refreshDocuments = () => {
    fetchDocuments(activeTab, 0);
  };

  return (
    <div className="flex flex-col">
      {/* Header Section */}
      <div className="flex items-center justify-between p-4">
        <h1 className="text-[20px] font-semibold text-[#192028]">
          Vault Documents
        </h1>
        <div className="flex items-center space-x-4">
          {/* Filter Button */}
          <Dropdown overlay={filterMenu} trigger={["click"]}>
            <button className="flex items-center justify-between w-[98px] h-[40px] bg-white text-black border border-gray-300 rounded-lg p-0">
              <span className="flex-grow text-left pl-4">Filter</span>
              <span className="flex justify-center items-center border border-[#41EAD4] rounded-md h-[40px] w-[40px]">
                <FilterOutlined className="text-[#41EAD4] text-[16px]" />
              </span>
            </button>
          </Dropdown>

          {/* Sort By Button */}
          {/* Sort By Button */}
          <Dropdown overlay={sortMenu} trigger={["click"]}>
            <button className="flex items-center justify-between w-[112px] h-[40px] bg-white text-black border border-gray-300 rounded-lg p-0">
              <span className="flex-grow text-left pl-4">Sort By</span>
              <span className="flex justify-center items-center border border-[#41EAD4] rounded-md h-[40px] w-[40px]">
                <BarsOutlined className="text-[#41EAD4] text-[16px]" />
              </span>
            </button>
          </Dropdown>

          {/* Upload Button */}
          <button
            className="flex items-center px-4 py-2 rounded-lg bg-[#FF6F61] text-white  border border-transparent"
            onClick={() => setIsModalVisible(true)}
          >
            <UploadOutlined className="mr-2" />
            Upload Document
          </button>
        </div>
      </div>

      {/* Tabs Section */}
      <Tabs defaultActiveKey="own" onChange={handleTabChange} className="mt-4">
        {tabs.map((tab) => (
          <TabPane tab={tab.label} key={tab.value} />
        ))}
      </Tabs>

      {/* Content Section */}
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-4">
          {loading && documents.length === 0 ? (
            <DocLoading />
          ) : documents.length > 0 ? (
            documents.map((doc) => (
              <DocCard
              key={doc._id}
              doc={doc}
              vault="true"
              // handlePinToggle={() => handleVaultPinToggle(doc._id)}  
              onUnpinSuccess={refreshDocuments} // Pass the refresh function
              />
                       ))
          ) : (
            !loading && <DefaultDocEmpty />
          )}
        </div>
        {loading && documents.length > 0 && <DocLoading />}
        {!loading && moreToCome && (
          <div ref={loaderRef} className="loader">
            Loading...
          </div>
        )}
      </div>

      {/* Modal for Upload Document */}
      {/* <AddDocVaultModal
        isVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        userId={userId}
        onUploadSuccess={refreshDocuments} // Pass the callback function
      /> */}

      <AddDocVaultModal
        isVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        userId={userId}
        onUploadSuccess={() => {
          // message.success("File uploaded successfully!");
          setTimeout(() => {
            setIsModalVisible(false);
            refreshDocuments();
          }, 1000);
        }}
      />
    </div>
  );
};

export default VaultHeader;