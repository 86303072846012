

import React, { useState } from 'react';
import { Modal, Upload, message, Progress, Spin } from 'antd';
import {
    UploadOutlined,
    FilePdfOutlined,
    DeleteOutlined,
    CheckOutlined,
    ExclamationCircleOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import axios from 'axios';
import UploadProcess from '../../../assets/SVG/UploadProcess.png';
import UploadSuccessIcon from '../../../assets/SVG/UploadSuccessfull.svg';
import ErrorImage from '../../../assets/SVG/ErrorLoad.svg';

const AddDocInsideFolderModal = ({ isVisible, handleCancel, userId, folderId, onDocumentAdded }) => {
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    // Infers the MIME type based on file extension
    const inferFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'dwg':
                return 'image/vnd.dwg';
            case 'dxf':
                return 'image/vnd.dxf';
            case 'dwf':
                return 'image/vnd.dwf';
            case 'rvt':
                return 'application/vnd.autodesk.revit';
            case 'ifc':
                return 'application/x-ifc';
            default:
                return 'application/octet-stream';
        }
    };

    const handleFileChange = (info) => {
        const selectedFile = info.file.originFileObj;
        const maxSizeMB = 10;

        if (selectedFile) {
            if (selectedFile.size / (1024 * 1024) > maxSizeMB) {
                message.error('File size exceeds 10MB');
                return;
            }

            const inferredType = selectedFile.type || inferFileType(selectedFile.name);
            const updatedFile = new File([selectedFile], selectedFile.name, {
                type: inferredType,
                lastModified: selectedFile.lastModified,
            });

            setFile(updatedFile);
            setUploadStatus('uploading');
            setProgress(0);
            uploadFile(updatedFile);
        }
    };

    const handleCancelModal = () => {
        resetModal();
        handleCancel();
    };
    
    const resetModal = () => {
        setFile(null);
        setUploadStatus('idle');
        setProgress(0);
    };
    
    const uploadFile = async (selectedFile) => {
        if (!folderId) {
            message.error('Folder ID is missing. Please select a folder first.');
            return;
        }
    
        setLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('userId', userId);
        formData.append('folderId', folderId);
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/create`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );
    
            if (response.status === 201) {
                setUploadStatus('success');
                message.success('File uploaded successfully');
    
                // Reset state after a delay to allow the user to see success message
                setTimeout(() => {
                    resetModal();
                    handleCancel(); // Close modal
                }, 1500);
    
                if (onDocumentAdded) onDocumentAdded();
            } else {
                setUploadStatus('error');
                message.error('File upload failed');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('error');
            message.error('File upload failed');
        } finally {
            setLoading(false);
        }
    };
    

    const handleDeleteFile = () => {
        setFile(null);
        setUploadStatus('idle');
        setProgress(0);
    };

    const retryUpload = () => {
        setProgress(0);
        setUploadStatus('uploading');
        uploadFile(file);
    };

  // Dynamically renders content based on the upload status
  const renderUploadContent = () => {
            if (uploadStatus === 'idle') {
                return (
                    <div style={{ padding: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px', textAlign: 'center' }}>
                        <Upload
                            onChange={handleFileChange}
                            showUploadList={false}
                            accept=".png, .pdf, .jpeg, .jpg, .gif, .bmp, .svg, .doc, .docx, .txt, .rtf, .xls, .xlsx, .csv, .ppt, .pptx, .dwg, .dxf, .dwf, .ifc, .json" // Accept only certain file types
                        >
                            <UploadOutlined style={{ fontSize: '64px', color: '#41EAD4', cursor: 'pointer' }} />
                        </Upload>
                        <h2 style={{ color: '#59616E', fontSize: '24px', marginBottom: '0px' }}>Upload Your File</h2>
                        <p style={{ color: '#768090', marginBottom: '0px' }}>
                            Upload / Drag & Drop your file
                        </p>
                        <p style={{ color: '#768090' }}>
                            Document should be in PDF/TXT/DOCX etc. only.
                            <br />
                            Max file size 10MB
                        </p>
                    </div>
                );
            } else if (uploadStatus === 'uploading') {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        gap: '12px',
                        borderRadius: '8px',
                        width: '900px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#FFF',
                    }}>
                        <img src={UploadProcess} alt="Uploading" style={{ width: '120px', cursor: 'pointer' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '860px', padding: '0 10px' }}>
                            <h3 style={{ color: '#1B2028', fontSize: '18px', fontWeight: '600' }}>
                                {file ? file.name : 'Document Name'}
                            </h3>
                            <DeleteOutlined onClick={handleDeleteFile} style={{ fontSize: '20px', cursor: 'pointer', color: '#59616E' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: '860px', padding: '0 10px', gap: '12px' }}>
                            <FilePdfOutlined style={{ fontSize: '48px', color: '#FF4D4F' }} />
                            <div style={{ flexGrow: 1 }}>
                                <Progress
                                    percent={progress}
                                    strokeColor="#41EAD4"
                                    showInfo={false}
                                    style={{ width: '699px', height: '20px', borderRadius: '50px' }}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: '#768090', fontSize: '14px' }}>
                                    <span>{file ? `1 File, ${(file.size / (1024 * 1024)).toFixed(2)} MB` : '1 File, 8 MB'}</span>
                                    <span>{progress}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else if (uploadStatus === 'success') {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '900px',
                        padding: '10px',
                        gap: '50px',
                        borderRadius: '8px',
                    }}>
                        <img src={UploadSuccessIcon} alt="Upload Successful" style={{ width: '80px' }} />
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '12px', padding: '0 20px' }}>
                            <FilePdfOutlined style={{ fontSize: '48px', color: '#FF4D4F' }} />
                            <div style={{ flexGrow: 1 }}>
                                <h3 style={{ color: '#1B2028', fontSize: '18px', fontWeight: '600' }}>Document Name</h3>
                                <Progress percent={100} strokeColor="#007768" showInfo={false} style={{ borderRadius: '50px' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: '#59616E', fontSize: '14px' }}>
                                    <span>Upload Successfully!</span>
                                    <span>100%</span>
                                </div>
                            </div>
                            <div style={{
                                borderRadius: '50%',
                                border: '2px solid #007768',
                                width: '24px',
                                height: '24px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <CheckOutlined style={{ fontSize: '16px', color: '#007768' }} />
                            </div>
                        </div>
                    </div>
                );
            } else if (uploadStatus === 'error') {
                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '900px',
                        padding: '10px',
                        gap: '50px',
                        borderRadius: '8px',
                        background: '#FFF',
                    }}>
                        <img src={ErrorImage} alt="Upload Failed" style={{ width: '132px', height: '132px', flexShrink: 0 }} />
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '12px', padding: '0 20px' }}>
                            <FilePdfOutlined style={{ fontSize: '48px', color: '#FF4D4F' }} />
                            <div style={{ flexGrow: 1 }}>
                                <h3 style={{ color: '#1B2028', fontSize: '18px', fontWeight: '600' }}>Document Name</h3>
                                <ExclamationCircleOutlined style={{
                                    fontSize: '20px',
                                    color: '#FF6F61',
                                    position: 'absolute',
                                    top: '220px',
                                    right: '60px',
                                }} />
                                <Progress percent={50} strokeColor="#FF6F61" showInfo={false} style={{ borderRadius: '50px' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: '#59616E', fontSize: '14px' }}>
                                    <span>Upload Failed!</span>
                                    <div style={{ display: 'flex', float: 'right', gap: '8px' }}>
                                        <span style={{ color: '#59616E', fontSize: '16px' }}>Try Again</span>
                                        <ReloadOutlined onClick={() => uploadFile(file)} style={{ fontSize: '20px', cursor: 'pointer', color: '#59616E' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        };
    
        return (<>
        <div onClick={(e) => e.stopPropagation()}> 
        <Modal
    title={null}
    visible={isVisible}
    onCancel={handleCancelModal}
    footer={null}
    width={900}
    bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
>

                {renderUploadContent()}
            </Modal>
            </div>
            </>
        );
    };

export default AddDocInsideFolderModal;