import React, { useState, useEffect } from 'react';
import { Modal, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from "../../authcontext";

// MIME type and file extension mapping
const fileTypeMapping = {
  'image/jpeg': ['jpg', 'jpeg', 'png'],
  'image/png': ['png', 'jpg', 'jpeg'],
  'application/pdf': ['pdf'],
  'application/vnd.ms-excel': ['xls', 'xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xls', 'xlsx'],
  'application/msword': ['doc', 'docx'],
  'application/json': ['json'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['doc', 'docx'],
  'application/vnd.ms-powerpoint': ['ppt', 'pptx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['ppt', 'pptx'],
  'text/plain': ['txt'],
  'text/csv': ['csv'],
};

const AddVersionModal = ({ isModalVisible, handleCancel, docId, fetchDocumentInfo, docType, setRefreshTrigger, versionCount }) => {
  const { userId, vaultStatus } = useAuth();
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [error, setError] = useState(null);

  console.log(versionCount);

  // Fetch authentication tokens from localStorage
  const sessionToken = localStorage.getItem("sessionToken");
  const vaultToken = localStorage.getItem("vaultToken");

  // Reset state when modal closes
  useEffect(() => {
    if (!isModalVisible) {
      setFile(null);
      setError(null);
      setUploadStatus(null);
    }
  }, [isModalVisible]);

  // Function to extract file extension
  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
  };

  // Function to validate file type against both MIME and extension
  const isValidFileType = (fileName, mimeType) => {
    const fileExtension = getFileExtension(fileName);
    const validExtensions = fileTypeMapping[mimeType] || [];
    return validExtensions.includes(fileExtension);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setError('Please select a file.');
      return;
    }

    if (!isValidFileType(selectedFile.name, docType)) {
      setError(`Invalid file type. Please upload a valid file type for: ${docType}`);
      return;
    }

    setFile(selectedFile); // Only set file if validation passes

    // Construct FormData for file upload
    const formData = new FormData();
    formData.append('documentId', docId);
    formData.append('file', selectedFile);
    const bucketKey = `${userId}/${selectedFile.name}`;
    formData.append('bucketKey', bucketKey);

    // Define headers dynamically inside the function
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "multipart/form-data",
      ...(vaultStatus && { vaultToken, secureVault: true })
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/versions/create`,
        formData,
        { headers }
      );

      setUploadStatus('success');
      setRefreshTrigger((prev) => prev + 1);
      fetchDocumentInfo(); // Refresh document info

      setTimeout(() => {
        handleCancel(); // Close modal after successful upload
      }, 2000);
    } catch (error) {
      console.error('Error uploading new version:', error);
      setError(error.response?.data?.message || 'Error uploading file. Please try again.');
      setUploadStatus('error');
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      width={900}
    >
      <div
        className="flex flex-col items-center justify-center rounded-lg"
        style={{
          display: 'flex',
          height: '300px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          gap: '12px',
        }}
      >
        {versionCount >= 10 ? (
          <h2 style={{ color: '#6B7280', fontWeight: '500', fontSize: '24px', textAlign: 'center' }}>
            For a document, a maximum of 10 versions can be uploaded.
          </h2>
        ) : (
          <>
            {/* Hidden File Input */}
            <input
              type="file"
              id="file-input"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />

            {/* Clickable Upload Icon */}
            <UploadOutlined
              onClick={() => document.getElementById('file-input').click()} // Trigger file input click
              style={{ color: '#41EAD4', fontSize: '48px', cursor: 'pointer' }}
            />

            <h2 style={{ color: '#6B7280', fontWeight: '500', fontSize: '24px' }}>
              Upload New Version
            </h2>
            <p style={{ color: '#768090', fontSize: '14px', textAlign: 'center' }}>
              Max file size 10MB
            </p>
          </>
        )}

        {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '12px' }} />}
        {uploadStatus === 'success' && <Alert message="File uploaded successfully!" type="success" showIcon style={{ marginBottom: '12px' }} />}
        {uploadStatus === 'error' && <Alert message="Error uploading file. Please try again." type="error" showIcon style={{ marginBottom: '12px' }} />}
      </div>
    </Modal>
  );
};

export default AddVersionModal;
