
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  List,
  Input,
  Typography,
  message,
  Spin,
} from "antd";
import { FormOutlined, EyeOutlined, PaperClipOutlined } from "@ant-design/icons";
import MemberSearchSelect from "../Utils/TeamOrMemberSearchSelect";
import axiosDocInstance from "../../ApiServices/AxiosDocInstance";
import ProfilePic from "../UI/ProfilePic";  // Import the ProfilePic component
import { useAuth } from "../../authcontext";

const ShareVersionModal = ({ isVisible, handleCancel, docId }) => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const {vaultStatus} = useAuth();



  const getHeaders = () => {
    const sessionToken = localStorage.getItem("sessionToken");
    const vaultToken = localStorage.getItem("vaultToken");

    return vaultStatus
      ? {
          Authorization: `Bearer ${sessionToken}`,
          vaultToken: vaultToken,
          secureVault: true,
        }
      : {
          Authorization: `Bearer ${sessionToken}`,
        };
  };

  useEffect(() => {
    if (isVisible && docId) {
      fetchSharedUsers();
    }
    else
    {
      setSelectedMembers([]);
    }
  }, [isVisible, docId]);

  const fetchSharedUsers = async () => {
    setFetching(true);
    try {
      const sessionToken = localStorage.getItem("sessionToken");
      const vaultToken = localStorage.getItem("vaultToken");

      const headers = vaultStatus
        ? {
            Authorization: `Bearer ${sessionToken}`,
            vaultToken: vaultToken,
            secureVault: true,
          }
        : {
            Authorization: `Bearer ${sessionToken}`,
          };
          

      // const response = await axios.get(
      //   `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
      //   { headers }
      // );

      const response = await axiosDocInstance.get(`/documents/${docId}`,{headers});
      if (response?.data?.data) {
        const { sharedWith, userId, userName } = response.data.data;

        const formattedPermissions = sharedWith.map((user) => ({
          id: user.entityId,
          name: user.entityName || "Unknown User",
          role: user.permissionType,
          entityType: user.entityType,
          canModify: user.permissionType === "modify",
        }));

        // Mark the owner and place at the top
        formattedPermissions.unshift({
          id: userId,
          name: userName,
          role: "Owner",
          entityType: "User",
          canModify: false,
        });

        setPermissions(formattedPermissions);
      }
    } catch (error) {
      message.error("Failed to fetch shared users");
    } finally {
      setFetching(false);
    }
  };

  const togglePermission = async (id) => {
    setPermissions((prev) =>
      prev.map((perm) =>
        perm.id === id
          ? {
              ...perm,
              canModify: !perm.canModify,
              role: perm.canModify ? "view" : "modify",
            }
          : perm
      )
    );

    try {

      const user = permissions.find((perm) => perm.id === id);
      if (!user) throw new Error("User not found");

      await axiosDocInstance.patch(`/documents/${docId}/share`, {
        permissions: [
          {
            entityId: id,
            entityType: user.entityType || "User",
            permissionType: user.canModify ? "view" : "modify",
          },
          
        ],
        
      },   { headers: getHeaders() });

      message.success("Permission updated successfully!");
    } catch (error) {
      message.error("Failed to update permission.");
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(`http://sub.example.net/doc/${docId}`);
    message.success("Link copied to clipboard!");
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  };

  const handleShare = async () => {
    if (selectedMembers.length === 0) {
      message.warning("Please select at least one user or team to share.");
      return;
    }

    setLoading(true);
    try {
      const permissionsPayload = selectedMembers.map((user) => ({
        entityId: user._id,
        entityType: user.entityType || "User",
        permissionType: "view",
      }));

      const response = await axiosDocInstance.patch(
        `/documents/${docId}/share`,
        { permissions: permissionsPayload },
        { headers: getHeaders() }
      );

      if (response.status === 200) {
        message.success("User permissions updated successfully!");
        handleCancel();
        fetchSharedUsers();
      } else {
        throw new Error("Failed to update permissions");
      }
    } catch (error) {
      message.error(error.message || "An error occurred while updating privileges");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      title={<Typography.Title level={4} style={{ marginTop: '10px' }}>Share this file</Typography.Title>}
      width={512}
      height={562}
    >
      {fetching ? (
        <Spin size="large" className="flex justify-center" />
      ) : (
        <>
          <MemberSearchSelect
            members={selectedMembers}
            onChange={setSelectedMembers}
          />
          <div className="flex flex-col">
            {/* Header */}
            <div className="text-[#AEB8CA] font-[SF Pro Text] text-[16px] font-semibold leading-[24px] mb-2">
              Who has access
            </div>

            {/* Horizontal Line */}
            <hr className="border-t border-[#E0E4EA] mb-2" />

            {/* List Container */}
            <div className="max-h-[300px] overflow-y-auto">
              {permissions.map((user) => (
                <div key={user.id} className="flex items-center justify-between w-full py-2 border-b last:border-none">
                  <div className="flex items-center gap-2">
                    {/* Avatar */}
                    <ProfilePic name={user.name} size="xs" />  {/* Use ProfilePic here with xs size */}

                    {/* User Name */}
                    <span className="text-[#1B2028] font-[SF Pro Text] text-[16px] font-normal leading-[24px]">
                      {user.name}
                    </span>
                  </div>

                  {/* Non-owner role toggle */}
                  {user.role !== "Owner" && (
                    <div className="flex items-center gap-2">
                      {/* Custom Toggle */}
                      <div
                        onClick={() => togglePermission(user.id)}
                        className="relative w-[46.2px] h-[28.75px] cursor-pointer rounded-full border border-gray-300 bg-[var(--Teal-Light-Primary-default)] flex justify-end items-center gap-[4.95px] p-[8.25px_4.125px] flex-shrink-0"
                      >
                        <div
                          className={`absolute top-0 left-0 w-full h-full rounded-full transition-colors duration-200 ${
                            user.canModify ? "bg-[#41EAD4]" : "bg-[#AEB8CA]"
                          }`}
                        ></div>
                        <div
                          className={`absolute top-[3px] left-[1px] w-[21px] h-[21px] bg-white rounded-full shadow-md flex items-center justify-center transition-transform duration-200 ${
                            user.canModify ? "translate-x-[23px]" : "translate-x-0"
                          }`}
                        >
                          {user.canModify ? (
                            <FormOutlined className="text-teal-500 text-xs" />
                          ) : (
                            <EyeOutlined className="text-gray-500 text-xs" />
                          )}
                        </div>
                      </div>

                      {/* Dynamic Text */}
                      <span className=" p-2 text-[#59616E] text-center font-[SF Pro Text] text-[11.55px] font-semibold leading-[18.15px]">
                        {user.canModify ? "Can Modify" : "Can View"}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="mt-8 flex items-center justify-between space-x-3 w-full">
            <input
              value={`http://sub.example.net/doc/${docId}`}
              readOnly
              className="w-[261px] h-[41px] px-3 py-2 border border-[#D9DEE7] rounded-[12px] focus:outline-none"
            />

            <button
              onClick={copyLink}
              className="flex items-center justify-center gap-1 px-2 py-1 w-auto h-auto bg-transparent border-none hover:bg-transparent"
            >
              <PaperClipOutlined className="text-[#1677FF] w-[22px] h-[22px]" />
              <span className="text-[#1677FF] font-[SF Pro Text] text-[14px] font-normal leading-[22px]">
                {linkCopied ? "Copied!" : "Copy Link"}
              </span>
            </button>
            <button
              onClick={handleShare}
              disabled={selectedMembers.length === 0 || loading}
              className={`flex h-[40px] px-[16px] flex-col justify-center items-center gap-[8px] rounded-[8px] bg-[#41EAD4] shadow-[0px_2px_0px_0px_rgba(0,0,0,0.05)] text-[#192028] ${
                selectedMembers.length === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? "Loading..." : "Share"}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ShareVersionModal;
