import React, { useState } from 'react';
import { Modal, Input, Button, Tag, message, Select } from 'antd';
import { EditOutlined, EyeOutlined, CheckCircleOutlined, FrownOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const AddFolderModal = ({ isVisible, handleCancel, userId, onUploadFolderSuccess }) => {
  const [formData, setFormData] = useState({
    name: 'Create New Folder',
    description: '',
    team: '',
    permission: '',
  });
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isFolderCreated, setIsFolderCreated] = useState(false);
  const [isError, setIsError] = useState(false);

  // New states for validation
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddTag = (e) => {
    const value = e.target.value.trim();
    if (e.key === 'Enter' && value && !tags.includes(value)) {
      setTags([...tags, value]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (removedTag) => {
    setTags(tags.filter((tag) => tag !== removedTag));
  };

  const handleCreateFolder = async () => {
    // Validation checks for folder name and description
    if (formData.name.trim() === '') {
      setNameError(true);
      return;
    } else if (formData.name.length > 20) {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }
  
    if (formData.description.length > 50) {
      setDescriptionError(true);
      return;
    } else {
      setDescriptionError(false);
    }
  
    setLoading(true);
  
    try {
      // Filter out fields with null or empty string values
      const filteredFormData = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== null && value !== '')
      );
  
      // Include tags and userId
      const payload = {
        ...filteredFormData,
        tags,
        userId,
      };
  
      await axios.post(`${process.env.REACT_APP_DOC_SERVICE_URL}/folder/create`, payload);
message.success('Folder created successfully');
if(onUploadFolderSuccess)
{
  onUploadFolderSuccess(); 
}
// This triggers the refresh
setIsFolderCreated(true);

    } catch (error) {
      setIsError(true);
      message.error('Failed to create folder');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      width={isFolderCreated || isError ? 539 : 900}
      bodyStyle={{
        display: 'flex',
        height: isFolderCreated || isError ? '368px' : '450px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '60px',
        gap: '24px',
        borderRadius: '8px',
        background: '#FFF',
      }}
    >
      {!isFolderCreated && !isError ? (
        <>
          {/* Modal Title Section */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', }}>
          
          {isEditingTitle ? (
  <Input
    value={formData.name}
    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
    onBlur={() => setIsEditingTitle(false)}
    autoFocus
    suffix={<EditOutlined />}
    maxLength={20} 
    style={{
      color: '#59616E',
      fontSize: '30px',
      fontWeight: '600',
      lineHeight: '38px',
      padding: '0',
      border: 'none',
    }}
  />
) : (
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    <span
      style={{
        color: '#59616E',
        fontFamily: '"SF Pro Text"',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
      }}
      onClick={() => setIsEditingTitle(true)}
    >
      {formData.name}
    </span>
    <EditOutlined
      style={{
        color: '#59616E',
        width: '32px',
        height: '32px',
      }}
    />
  </div>
)}



            {/* Display error for name validation */}
            {nameError && <div style={{ color: 'red' }}>Folder name is required and must be under 20 characters.</div>}

            {/* Character Count Text */}
            <span
              style={{
                color: '#768090',
                fontFamily: '"SF Pro Text"',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
              }}
            >
              {formData.name.length}/20
            </span>
          </div>

          {/* Description Input with Character Counter */}
          <div style={{ width: '100%', marginBottom: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <label style={{ color: '#999' }}>Description (Optional)</label>
              <span
                style={{
                  color: '#768090',
                  fontFamily: '"SF Pro Text"',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px',
                }}
              >
                {formData.description.length}/50
              </span>
            </div>
            <Input
              placeholder="Enter folder description"
              value={formData.description}
              onChange={handleInputChange}
              name="description"
              maxLength={50}
              style={{ width: '100%' }}
            />
            {/* Display error for description validation */}
            {descriptionError && <div style={{ color: 'red' }}>Description must be under 50 characters.</div>}
          </div>

          {/* Assign User/Team & Permission Inputs */}
          <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
            <div style={{ flex: 1 }}>
              <label style={{ color: '#999' }}>Assign User/Team (Optional)</label>
              <Input
                placeholder="Enter team or user"
                value={formData.team}
                onChange={handleInputChange}
                name="team"
                style={{ width: '100%' }}
              />
            </div>

            <div style={{ flex: 1 }}>
              <label style={{ color: '#999' }}>Permission (Optional)</label>
              <Select
                placeholder="Select permission"
                value={formData.permission}
                onChange={(value) => setFormData({ ...formData, permission: value })}
                style={{ width: '100%' }}
              >
                <Option value="view">
                  <EyeOutlined style={{ color: '#1B2028', marginRight: '8px' }} />
                  <span style={{ color: '#1B2028' }}>Can View</span>
                </Option>

                <Option value="edit">
                  <EditOutlined style={{ color: '#768090', marginRight: '8px' }} />
                  <span style={{ color: '#768090' }}>Can Edit</span>
                </Option>
              </Select>
            </div>
          </div>

          {/* Add Tags Section */}
          <div style={{ width: '100%' }}>
            <label style={{ color: '#999' }}>Add Tags</label>
            <Input
              placeholder="Enter tag and press Enter"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyPress={handleAddTag}
              style={{ width: '100%', marginBottom: '16px' }}
            />
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {tags.map((tag, index) => (
                <Tag
                  key={index}
                  closable
                  onClose={() => handleRemoveTag(tag)}
                  style={{ color: '#1B2028', backgroundColor: '#D1F8F0' }} // Text color and background
                >
                  {tag}
                </Tag>
              ))}
            </div>
          </div>

          {/* Footer Buttons */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '16px',
              width: '100%',
              marginTop: '24px',
            }}
          >
            <Button
              key="back"
              onClick={handleCancel}
              style={{
                backgroundColor: '#E1E5EC',
                color: '#59616E',
                border: 'none',
              }}
            >
              Close
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleCreateFolder}
              style={{
                backgroundColor: '#41EAD4',
                color: '#192028',
                border: 'none',
              }}
            >
              Create
            </Button>
          </div>
        </>
      ) : isFolderCreated ? (
        // Success Screen after Folder Creation
        <div
          style={{
            display: 'flex',
            maxWidth: '539px',
            maxHeight: '368px',
            padding: '60px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            borderRadius: '8px',
            background: '#FFF',
          }}
        >
          {/* Success Icon */}
          <CheckCircleOutlined style={{ fontSize: '100px', color: '#41EAD4' }} />

          {/* Success Text */}
          <span
            style={{
              fontWeight: 600,
              fontSize: '20px',
              color: '#59616E',
            }}
          >
            Folder Created Successfully
          </span>

          {/* Go to Document Button */}
          <Button
            type="primary"
            onClick={handleCancel}
            style={{
              backgroundColor: '#41EAD4',
              color: '#192028',
              border: 'none',
              borderRadius: '8px',
              padding: '8px 24px',
              fontFamily: '"SF Pro Text"',
              fontSize: '16px',
            }}
          >
            Go to Document
          </Button>
        </div>
      ) : (
        // Error Screen after Folder Creation Failure
        <div
          style={{
            display: 'flex',
            width: '539px',
            height: '368px',
            padding: '60px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            gap: '24px',
            borderRadius: '8px',
            background: '#FFF',
          }}
        >
          {/* Error Icon */}
          <FrownOutlined style={{ fontSize: '100px', color: '#A0A0A0' }} />

          {/* Error Text */}
          <span
            style={{
              fontWeight: 600,
              fontSize: '20px',
              color: '#59616E',
            }}
          >
            Folder upload failed. Please check your connection or file permissions and try again.
          </span>

          {/* Try Again Button */}
          <Button
            type="primary"
            onClick={() => setIsError(false)}
            style={{
              backgroundColor: '#41EAD4',
              color: '#192028',
              border: 'none',
              borderRadius: '8px',
              padding: '8px 24px',
              fontFamily: '"SF Pro Text"',
              fontSize: '16px',
            }}
          >
            Try again
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default AddFolderModal;
