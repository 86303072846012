// import React from 'react';
// import { Modal, Button, Typography } from 'antd';

// const { Text } = Typography;

// const DeleteVersionModal = ({ visible, onClose, onDelete, docId }) => {
//   const handleDelete = async () => {
//     try {
//       const sessionToken = localStorage.getItem("sessionToken");
   
      
//       const response = await fetch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
//         {
//           method: 'DELETE',
//           headers: { 'Content-Type': 'application/json' ,
//             "Authorization": `Bearer ${sessionToken}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Failed to delete version');
//       }

//       onClose(); // Close modal
//     } catch (error) {
//       console.error('Error deleting version:', error);
//       Modal.error({
//         title: 'Deletion Failed',
//         content: 'Error deleting the version. Please try again.',
//       });
//     }
//   };

//   return (
//     <Modal
//       title="Delete Version"
//       visible={visible}
//       onCancel={onClose}
//       footer={[
//         <Button key="cancel" onClick={onClose}>
//           Cancel
//         </Button>,
//         <Button key="delete" type="primary" danger onClick={handleDelete}>
//           Delete
//         </Button>,
//       ]}
//     >
//       <Text>
//         <Text strong> {docId}</Text>? This action cannot be undone.
//       </Text>
//     </Modal>
//   );
// };

// export default DeleteVersionModal;
















// import React from 'react';
// import { Modal, Button, Typography } from 'antd';

// const { Text } = Typography;

// const DeleteVersionModal = ({ visible, onClose, onDelete, docId }) => {
//   const handleDelete = async () => {
//     try {
//       const sessionToken = localStorage.getItem("sessionToken");
   
      
//       const response = await fetch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
//         {
//           method: 'DELETE',
//           headers: { 'Content-Type': 'application/json' ,
//             "Authorization": `Bearer ${sessionToken}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Failed to delete version');
//       }

//       onClose(); // Close modal
//     } catch (error) {
//       console.error('Error deleting version:', error);
//       Modal.error({
//         title: 'Deletion Failed',
//         content: 'Error deleting the version. Please try again.',
//       });
//     }
//   };

//   return (
//     <Modal
//       title="Delete Version"
//       visible={visible}
//       onCancel={onClose}
//       footer={[
//         <Button key="cancel" onClick={onClose}>
//           Cancel
//         </Button>,
//         <Button key="delete" type="primary" danger onClick={handleDelete}>
//           Delete
//         </Button>,
//       ]}
//     >
//       <Text>
//         <Text strong> {docId}</Text>? This action cannot be undone.
//       </Text>
//     </Modal>
//   );
// };

// export default DeleteVersionModal;









import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Typography, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useAuth } from "../../authcontext";

const { Text } = Typography;

const DeleteDocumentModal = ({ visible, onClose, onDelete, docId }) => {

  const navigate = useNavigate();
  const {userId, vaultStatus  }=useAuth();
  const sessionToken = localStorage.getItem("sessionToken");
  const vaultToken = localStorage.getItem("vaultToken");

  const headers = {
    Authorization: `Bearer ${sessionToken}`,
    "Content-Type": "multipart/form-data",
    ...(vaultStatus && { vaultToken, secureVault: true })
  };

  const handleDelete = async () => {
    try {
      const sessionToken = localStorage.getItem("sessionToken");
      const response = await fetch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
        {
          method: "DELETE",
          headers: headers
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Extract error response JSON
        throw new Error(errorData.error?.message || "Failed to delete version");
      }

      message.success("Entire Document deleted successfully!");
      onClose();
      navigate(-1); // Navigate back to the previous window
    } catch (error) {
      console.error("Error deleting version:", error);
      if (error.message === "active-version-cant-be-deleted") {
        message.error("Active version cannot be deleted."); // User-friendly message
      } else {
        message.error("An error occurred while deleting the version.");
      }
    }
  };

  return (
    <Modal visible={visible} onCancel={onClose} footer={null} centered width={600}>
      <div style={{ textAlign: "center", padding: "24px" }}>
        <DeleteOutlined style={{ fontSize: "48px", color: "#FF4D4F" }} />
        <Typography.Title level={4} style={{ margin: "16px 0", color: "#59616E" }}>
          Delete
        </Typography.Title>

        <Text className="text-[14px] font-normal leading-[22px] text-[#768090]">
          Are you sure you want to delete this document?  
          <br />
          This action will move the document and all its versions to the trash.
        </Text>
        <div className="flex gap-4 mt-4">
          <Button
            onClick={onClose}
            className="
              flex 
              w-[318px] 
              h-[40px] 
              px-4 
              justify-center 
              items-center 
              gap-2 
              rounded-lg 
              text-[#1B2028] 
              focus:outline-none 
              focus:ring-2 
              focus:ring-gray-400
            "
          >
            Close
          </Button>

          <Button
            type="primary"
            danger
            onClick={handleDelete}
            className="
              flex 
              w-[318px] 
              h-[40px] 
              px-4 
              flex-col 
              justify-center 
              items-center 
              gap-2 
              bg-[#FF412E] 
              rounded-lg 
              focus:outline-none 
              focus:ring-2 
              focus:ring-red-300
            "
          >
         {vaultStatus?"Shred":"Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;
