import React, { useState, useEffect } from "react";
import { Layout, Menu, Button, Avatar, Badge, Input, Grid } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  FileOutlined,
  TeamOutlined,
  FileProtectOutlined,
  FolderOpenOutlined,
  SettingOutlined,
  BellOutlined,
  SnippetsOutlined,
  UndoOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../authcontext";
import Logo from "../../assets/PNG/Brix-Logo.png";
import SmallLogo from "../../assets/PNG/smalllogo.png";

import { Search as SearchIcon } from "@material-ui/icons";

const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const InitialAvatar = ({ name }) => {
  const initials = name
    ? name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase()
    : "?";

  return (
    <Avatar style={{ backgroundColor: "#319795", color: "#fff" }}>
      {initials}
    </Avatar>
  );
};

const SidebarLayout = () => {
  const { userRole, logout, userName } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1");
  const screens = useBreakpoint();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/app/dashboard")) {
      setSelectedKey("1");
    } else if (path.includes("/documents/folders")) {
      setSelectedKey("2");
    } else if (path.includes("/app/vault")) {
      setSelectedKey("3");
    } else if (path.includes("/app/teams")) {
      setSelectedKey("4");
    } else if (path.includes(`/${userRole}/users`)) {
      setSelectedKey("5");
    } else if (path.includes("/app/trash")) {  
      setSelectedKey("6");
    } else if (path.includes("/app/settings")) {
      setSelectedKey("7");
    } else if (path.includes("/app/todo")) {
      setSelectedKey("8");
    }
  }, [location, userRole]);

  const handleLogout = async () => {
    const sessionToken = localStorage.getItem("sessionToken");

    // Function to remove all tokens from localStorage
    const deleteAllTokens = () => {
      localStorage.clear(); // This clears all keys in localStorage
    };

    if (!sessionToken) {
      console.error("No session token found");
      deleteAllTokens();
      logout();
      return;
    }

    try {
      const response = await fetch(
        "https://auth-comm-service.onrender.com/api/v1/auth/logout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: sessionToken }),
        }
      );

      if (response.ok) {
        deleteAllTokens();
        logout();
      } else {
        console.error("Failed to log out:", response.status, response.statusText);
        deleteAllTokens(); // Ensure tokens are removed even on failure
      }
    } catch (error) {
      console.error("Logout Error:", error);
      deleteAllTokens(); // Ensure tokens are removed in case of error
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
     <Sider
  trigger={null}
  collapsible
  collapsed={collapsed}
  maxwidth={250}
  style={{
    background: "#fff",
    borderRight: "1px solid #e0e0e0",
  }}
  breakpoint="md"
  collapsedWidth="80"
  onBreakpoint={(broken) => {
    if (broken) setCollapsed(true);
  }}
>
  <div className="logo" style={{ padding: 16, textAlign: "center" }}>
    {collapsed ? (
      <img
        src={SmallLogo} // Add the path to the small logo here
        alt="Small Logo"
        style={{ width: "40px" }} // Adjust the width for the small logo
      />
    ) : (
      <img
        src={Logo} // Add the path to the full logo here
        alt="Brix Logo"
        style={{ width: "60%" }}
      />
    )}
  </div>

  {/* Menu items */}
  <Menu
    mode="inline"
    selectedKeys={[selectedKey]}
    style={{
      background: "#fff",
      fontWeight: "500",
      marginTop: "20px",
      transition: "all 0.3s ease",
      ...(collapsed ? { paddingLeft: "10px" } : {}),
    }}
  >
     {/* Dashboard Menu Item */}
          <Menu.Item
            key="1"
            icon={<HomeOutlined style={{ color: selectedKey === "1" ? "#38B2AC" : "#718096" }} />}
            style={{
              marginBottom: "15px",
              height:'50px',
              ...(selectedKey === "1"
                ? { backgroundColor: "#E6FFFA", color: "#319795" }
                : {}),
            }}
          >
            <Link to="/app/dashboard">Dashboard</Link>
          </Menu.Item>

          {/* Documents Menu Item */}
          <Menu.Item
            key="2"
            icon={<FileOutlined style={{ color: selectedKey === "2" ? "#38B2AC" : "#718096" }} />}
            style={{
              marginBottom: "15px",
              height:'50px',
              ...(selectedKey === "2"
                ? { backgroundColor: "#E6FFFA", color: "#319795" }
                : {}),
            }}
          >
            <Link to="/documents/folders">Documents</Link>
          </Menu.Item>

          {/* Vault Menu Item */}
          <Menu.Item
            key="3"
            icon={<FileProtectOutlined style={{ color: selectedKey === "3" ? "#38B2AC" : "#718096" }} />}
            style={{
              marginBottom: "15px",
              height:'50px',
              ...(selectedKey === "3"
                ? { backgroundColor: "#E6FFFA", color: "#319795" }
                : {}),
            }}
          >
            <Link to="/app/vault">Vault</Link>
          </Menu.Item>

          {/* Teams Menu Item */}
          <Menu.Item
            key="4"
            icon={<TeamOutlined style={{ color: selectedKey === "4" ? "#38B2AC" : "#718096" }} />}
            style={{
              marginBottom: "15px",
              height:'50px',
              ...(selectedKey === "4"
                ? { backgroundColor: "#E6FFFA", color: "#319795" }
                : {}),
            }}
          >
            <Link to="/app/teams">Teams</Link>
          </Menu.Item>

          {/* Tasks Menu Item */}
          <Menu.Item
            key="8"
            icon={<SnippetsOutlined style={{ color: selectedKey === "8" ? "#38B2AC" : "#718096" }} />}
            style={{
              marginBottom: "15px",
              height:'50px',
              ...(selectedKey === "8"
                ? { backgroundColor: "#E6FFFA", color: "#319795" }
                : {}),
            }}
          >
            <Link to="/app/todo">Tasks</Link>
          </Menu.Item>

          {/* Settings Menu Item */}
          {userRole && (
            <Menu.Item
              key="7"
              icon={<SettingOutlined style={{ color: selectedKey === "7" ? "#38B2AC" : "#718096" }} />}
              style={{
                marginBottom: "15px",
                height:'50px',
                ...(selectedKey === "7"
                  ? { backgroundColor: "#E6FFFA", color: "#319795" }
                  : {}),
              }}
            >
              <Link to="/app/settings">Settings</Link>
            </Menu.Item>
          )}


 {/* Trash Menu Item */}
 <Menu.Item
            key="6"
            icon={<DeleteOutlined style={{ color: selectedKey === "6" ? "#38B2AC" : "#718096" }} />}
            style={{
              marginBottom: "15px",
              height:'50px',
              ...(selectedKey === "6"
                ? { backgroundColor: "#E6FFFA", color: "#319795" }
                : {}),
            }}
          >
            <Link to="/app/trash">Trash</Link>
          </Menu.Item>




        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            background: "#fff",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "8px", flexShrink: 0 }}>
            <Input
              placeholder="Search for folders by name or tags"
              style={{
                width: "512px",
                height: "36px",
                padding: "0px 8px",
                borderRadius: "6px",
                border: "1px solid #CAD4DD",
                background: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              prefix={<SearchIcon style={{ color: "#768090" }} />}
            />
          </div>
          <div className="flex items-center space-x-6">
           
              <UndoOutlined  style={{ fontSize: 24, color: "#718096" }} />
            



           
              <BellOutlined style={{ fontSize: 24, color: "#718096" }} />
            
            <InitialAvatar name={userName} />
            <span style={{ marginLeft: 8, fontWeight: 500, color: "#4A5568" }}>
              {userName}
            </span>
            <Button
              onClick={handleLogout}
              type="link"
              style={{ color: "#319795" }}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Content
          style={{
            marginTop: 10,
            marginLeft: collapsed ? 10 : 10,
            backgroundColor: "#F4F5F7",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              paddingBottom: "100px",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;