import React, { useState, useEffect } from "react";
import { UploadOutlined, FolderAddOutlined, TeamOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { useAuth } from "../../authcontext";
import AddDocModal from "../../Services/Modal/AddDocModal";
import AddFolderModal from "../../Services/Modal/AddFolderModal";
import AddTeamModal from "../../Services/Modal/AddTeamModal";

const QuickActions = ({ onUploadSuccess, onUploadFolderSuccess }) => {  
  const { userId, userName } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log(userId);
  const actions = [
    {
      icon: <UploadOutlined style={{ fontSize: "24px", color: "#41EAD4" }} />,
      title: "Drop file to Upload",
      action: "upload",
    },
    {
      icon: <FolderAddOutlined style={{ fontSize: "24px", color: "#41EAD4" }} />,
      title: "Create A New Folder",
      action: "createFolder",
    },
    {
      icon: <TeamOutlined style={{ fontSize: "24px", color: "#41EAD4" }} />,
      title: "Add New Team",
      action: "createTeam",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleActionClick = (action) => {
    setCurrentAction(action);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentAction(null); // Reset current action on modal close
  };

  if (loading) {
    return (
      <div className="mb-6">
        <Skeleton.Input style={{ width: 200 }} active />
        <Skeleton.Input style={{ width: 150, margin: "16px 0" }} active />
        <div className="flex flex-wrap justify-between gap-4 md:flex-nowrap">
          {actions.map((action, index) => (
            <div
              key={index}
              className="border rounded-lg p-4 shadow-sm flex items-center w-full md:w-80 bg-white"
              style={{
                height: "80px",
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid #99EDDE",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Skeleton.Avatar active size="large" shape="circle" />
              <div style={{ marginLeft: "16px" }}>
                <Skeleton.Input style={{ width: 120 }} active />
                <Skeleton.Input style={{ width: 80, marginTop: "8px" }} active />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6">
     <div className="text-[24px] font-sf-pro-text font-semibold text-[#192028] leading-[32px] mb-4">
  Welcome Back, {userName}
</div>

<h2 className="text-[24px] font-sf-pro-text font-semibold text-[#192028] leading-[28px] mb-4">
  Quick Actions
</h2>

      <div
        className="flex flex-wrap gap-2"
        style={{
          maxwidth: '670px',
          justifyContent: 'space-between', // Ensures even spacing between cards
        }}
      >
        {actions.map((action, index) => (
          <div
            key={index}
            className="mt-2 border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300 flex items-center bg-white"
            onClick={() => handleActionClick(action.action)}
            style={{
              width: '210px', // Fixed card width
              height: '92px',
              // padding: '16px',
              borderRadius: '8px',
              border: '1px solid #99EDDE',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              cursor: 'pointer',
              flexShrink: 0, // Prevent cards from shrinking
            }}
          >
            <div
              className="flex items-center justify-center rounded-lg"
              style={{
                width: '40px',
                height: '40px',
              }}
            >
              {action.icon}
            </div>
            <div style={{ marginLeft: '16px' }}>
              <h4 className="text-[16px] font-semibold leading-[24px] text-[var(--Teal-Light-Charcoal-Default,#1B2028)] font-sans whitespace-pre-wrap">
                {action.title}
              </h4>
            </div>
          </div>
        ))}
      </div>

      {/* Modals */}
      {currentAction === 'upload' && (
        <AddDocModal isVisible={isModalVisible} handleCancel={handleCancel} userId={userId} onUploadSuccess={onUploadSuccess} />
      )}
      {currentAction === 'createFolder' && (
        <AddFolderModal 
        isVisible={isModalVisible} 
        handleCancel={handleCancel} 
        userId={userId} 
        onUploadFolderSuccess={onUploadFolderSuccess} 
      />
      
      )}
      {currentAction === 'createTeam' && (
        <AddTeamModal isModalVisible={isModalVisible} handleCancel={handleCancel} userId={userId} />
      )}
    </div>
  );
};

export default QuickActions;