


import React, { useEffect, useState } from "react";
import { Tabs, Button, message,  Modal  } from "antd";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import AddVersionModal from "../../Services/Modal/AddNewVersionModal";
import DeleteDocumentModal from "../../Services/DeleteModal/DeleteDocumentModal";
import ShareVersionModal from "../../Services/ShareModal/ShareVersionModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import InfoTab from "./Info";
import VersionControlTab from "./VersionControl";
import AccessControl from "./AccessControl";
import { useAuth } from "../../authcontext";

const { TabPane } = Tabs;

const VersionCrud = ({
  documentInfo,
  selectedVersion,
  onVersionUploaded,
  setRefreshTrigger,
  setDeleteRefreshTrigger,
  deleteRefreshTrigger
}) => {
  const { docId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedPermission, setSelectedPermission] = useState("Can Modify");
  const [documentData, setDocumentData] = useState(null);
  const [selectedVersionData, setSelectedVersionData] = useState(0);
  const { vaultStatus, userId } = useAuth();
  const [versionCount, setVersionCount] = useState(null);
  const [versionDetails , setVersionDetails] = useState(null);
 

  const sessionToken = localStorage.getItem("sessionToken");
  const vaultToken = localStorage.getItem("vaultToken");
  console.log(vaultStatus);

  useEffect(() => {
    if (documentInfo && selectedVersion) {
      const versionData = documentInfo.versions.find(
        (v) => v.versionId === selectedVersion
      );
      setSelectedVersionData(versionData);
    }
  }, [documentInfo, selectedVersion,  deleteRefreshTrigger]);

  const fetchVersionHistory = async () => {
    try {
      const headers = vaultStatus
        ? {
            Authorization: `Bearer ${sessionToken}`,
            vaultToken: vaultToken,
            secureVault: true,
          }
        : {
            Authorization: `Bearer ${sessionToken}`,
          };
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`,
        { headers }
      );
      setVersionDetails(response.data.data.versions);
      setDocumentData(response.data.data);
      setVersionCount(response.data.data.versions.length);
      const currentVersion = response.data.data.versions.find(
        (v) => v.versionId === response.data.data.currentVersion
      );
      setSelectedVersionData(currentVersion);
    } catch (error) {
      console.error("Error Fetching Version History:", error);
    }
  };

  useEffect(() => {
    if (docId) {
      fetchVersionHistory();
    }
  }, [docId, deleteRefreshTrigger, activeTab]);

  const getHeaders = () => {
    const sessionToken = localStorage.getItem("sessionToken");
    const vaultToken = localStorage.getItem("vaultToken");
    return vaultStatus
      ? {
          Authorization: `Bearer ${sessionToken}`,
          vaultToken: vaultToken,
          secureVault: true,
        }
      : {
          Authorization: `Bearer ${sessionToken}`,
        };
  };

  const checkPermission = () => {
    if (!documentData) return false;
    if (documentData.userId === userId) {
      return true; // Owner has modify permission by default
    }
    const sharedWithUser = documentData.sharedWith.find(
      (shared) => shared.entityId === userId && shared.permissionType === "modify"
    );
    return !!sharedWithUser;
  };

  const checkPermission1 = () => {
    if (!documentData) return false;
    return documentData.userId === userId;
  };
  
  

  const renderTabContent = () => {
    switch (activeTab) {
      case "1":
        return <InfoTab documentData={documentData} selectedVersion={selectedVersionData}/>;
      case "2":
        return (
          <VersionControlTab
            documentData={documentData}
            selectedVersion={selectedVersionData}
            setDeleteRefreshTrigger={setDeleteRefreshTrigger}
            fetchVersionHistory={fetchVersionHistory}
            versionDetails={versionDetails}
          />
        );
      case "3":
        return (
          <AccessControl
            selectedPermission={selectedPermission}
            setSelectedPermission={setSelectedPermission}
            docId={docId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative">
    {/* Delete Button Floating on Top Right */}
    {documentData?.userId === userId && (
      <button
        onClick={() => setIsDeleteModalVisible(true)}
        className="
          absolute right-4 top-0 flex items-center justify-center 
          p-2 border border-[#FCB3A9] bg-[#FCF9F9] rounded-lg gap-2 
          shadow-[0px_2px_0px_0px_rgba(5,145,255,0.1)] 
          hover:outline-none focus:outline-none whitespace-nowrap
          transform -translate-y-10
        "
      >
        <DeleteOutlined style={{ color: "#FF412E", fontSize: "16px" }} />
        <span className="text-[#FF412E] text-[14px] font-medium">
         {vaultStatus?"Shred":"Delete Document"} 
        </span>
      </button>
    )}

      {/* Main Content */}
      <div className="bg-white rounded-lg p-6 shadow-md" style={{ width: "380px", minHeight: "376px" }}>
        <div className="flex justify-between items-center mb-4 space-x-4">
          {checkPermission() && (
            <button
              onClick={() => setIsModalVisible(true)}
              className="
                flex h-8 w-[375px] px-4 items-center justify-center gap-2 
                rounded-lg bg-[#007768] text-white 
                shadow-[0px_2px_0px_0px_rgba(5,145,255,0.1)] 
                hover:outline-none focus:outline-none
              "
            >
              <CloudUploadOutlined style={{ fontSize: "16px" }} />
              <span className="text-[14px] font-medium">New Version</span>
            </button>
          )}
        </div>

        <Tabs activeKey={activeTab} onChange={setActiveTab} tabBarGutter={24}>
          <TabPane tab="Info" key="1" />
          <TabPane tab="Version Control" key="2" />
          <TabPane tab="Access Control" key="3" />
        </Tabs>

        <div className="mt-4">{renderTabContent()}</div>

        <AddVersionModal
          isModalVisible={isModalVisible}
          handleCancel={() => setIsModalVisible(false)}
          docId={docId}
          docType={documentData?.docType}
          fetchDocumentInfo={fetchVersionHistory}
          setRefreshTrigger={setRefreshTrigger}
        />

        <ShareVersionModal
          isVisible={isShareModalVisible}
          handleCancel={() => setIsShareModalVisible(false)}
        />

        {isDeleteModalVisible && (
          <DeleteDocumentModal
            visible={isDeleteModalVisible}
            onClose={() => setIsDeleteModalVisible(false)}
            docId={documentData?._id}
          />
        )}
      </div>
    </div>
  );
};

export default VersionCrud;