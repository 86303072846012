import React from 'react';
import VaultHeader from '../Components/Vault/Data/VaultHeader';

const VaultDoc = () => {
  return (
    <div className="p-4">
      {/* Header component */}
      <VaultHeader />

      {/* Placeholder for additional Vault Document content */}
      <div className="mt-6">
      </div>
    </div>
  );
};

export default VaultDoc;