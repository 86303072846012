// import React, { useState } from 'react';
// import { Modal, Form, Input, Button, Select, notification } from 'antd';
// import axios from 'axios';

// const { Option } = Select;

// const EditPermissions = ({ folder, isPermissionModalVisible, setPermissionModalVisible }) => {
//     const [assignUser, setAssignUser] = useState('');
//     const [removeUser, setRemoveUser] = useState('');
//     const [permission, setPermission] = useState('');

//     const folderPermissionsUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folder._id}/permissions`;

//     const handleSavePermissions = async () => {
//         try {
//             const newPermissions = {
//                 assign: assignUser ? [{ entityId: assignUser, permissionType: permission }] : [],
//                 remove: removeUser ? [removeUser] : [],
//             };
//             await axios.patch(folderPermissionsUrl, newPermissions);
//             notification.success({ message: 'Permissions updated successfully' });
//             setPermissionModalVisible(false);
//         } catch (error) {
//             notification.error({ message: 'Failed to update permissions' });
//         }
//     };

//     return (
//         <Modal
//             title={<div style={{ textAlign: 'center' }}>Edit Permissions</div>}
//             visible={isPermissionModalVisible}
//             onCancel={() => setPermissionModalVisible(false)}
//             footer={null}
//             centered
//             width={600}
//         >
//             <div onClick={(e) => e.stopPropagation()}> 
//             <div style={{ textAlign: 'center', marginBottom: '20px', color: '#768090' }}>
//                 {folder.name}
//             </div>
//             <Form layout="vertical" onFinish={handleSavePermissions}>
//                 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                     <Form.Item label="Assign User/ Team (Optional)" style={{ width: '48%' }}>
//                         <Input
//                             placeholder="User/Team"
//                             value={assignUser}
//                             onChange={(e) => setAssignUser(e.target.value)}
//                             style={{ borderRadius: '8px' }}
//                         />
//                     </Form.Item>
//                     <Form.Item label="Remove User/ Team (Optional)" style={{ width: '48%' }}>
//                         <Input
//                             placeholder="User/Team"
//                             value={removeUser}
//                             onChange={(e) => setRemoveUser(e.target.value)}
//                             style={{ borderRadius: '8px' }}
//                         />
//                     </Form.Item>
//                 </div>
//                 <Form.Item label="Permission (Optional)">
//                     <Select
//                         placeholder="Select Permission"
//                         value={permission}
//                         onChange={(value) => setPermission(value)}
//                         style={{ borderRadius: '8px' }}
//                     >
//                         <Option value="view">View</Option>
//                         <Option value="modify">Modify</Option>
//                     </Select>
//                 </Form.Item>
//                 <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
//                     <Button
//                         onClick={() => setPermissionModalVisible(false)}
//                         style={{
//                             backgroundColor: '#D0D5DD',
//                             color: '#667085',
//                             padding: '8px 16px',
//                             marginRight: '12px',
//                             borderRadius: '8px',
//                         }}
//                     >
//                         Close
//                     </Button>
//                     <Button
//                         type="primary"
//                         htmlType="submit"
//                         style={{
//                             backgroundColor: '#12B76A',
//                             color: '#FFFFFF',
//                             padding: '8px 16px',
//                             borderRadius: '8px',
//                         }}
//                     >
//                         Save
//                     </Button>
//                 </div>
//             </Form>
//             </div>
//         </Modal>
//     );
// };

// export default EditPermissions;













import React, { useState } from 'react';
import { Modal, Form, Button, notification, message } from 'antd';
import { EyeOutlined, FormOutlined } from '@ant-design/icons';
import axios from 'axios';
import MemberSearchSelect from "../Utils/TeamOrMemberSearchSelect";

const EditPermissions = ({ folder, isPermissionModalVisible, setPermissionModalVisible }) => {
    const [assignUser, setAssignUser] = useState(null); // Selected user/team
    const [permission, setPermission] = useState('view'); // Default permission "view"

    const folderPermissionsUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folder._id}/permissions`;

    const handleSavePermissions = async () => {
        try {
            if (!assignUser) {
                message.warning('Please select a user or team to assign permissions' );
                return;
            }

            const newPermissions = {
                assign: [{ entityId: assignUser._id, permissionType: permission }],
            };

            await axios.patch(folderPermissionsUrl, newPermissions);
            message.success('Permissions updated successfully' );
            setPermissionModalVisible(false);
        } catch (error) {
            message.error( 'Failed to update permissions' );
        }
    };

    return (
        <div onClick={(e) => e.stopPropagation()}> 
        <Modal
            title={<div style={{ textAlign: 'center' }}>Edit Permissions</div>}
            visible={isPermissionModalVisible}
            onCancel={() => setPermissionModalVisible(false)}
            footer={null}
            centered
            width={600}
        >
          
                <div style={{ textAlign: 'center', marginBottom: '20px', color: '#768090' }}>
                    {folder.name}
                </div>
                <Form layout="vertical" onFinish={handleSavePermissions}>
                    {/* Assign User/Team with Debounced Search */}
                    <Form.Item label="Assign User/Team" required>
                        <MemberSearchSelect
                            members={assignUser ? [assignUser] : []}
                            onChange={(selected) => setAssignUser(selected[0] || null)}
                        />
                    </Form.Item>

                    {/* Permission Toggle */}
                    {assignUser && (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                            <span style={{ marginRight: '10px', fontWeight: 500, color: '#667085' }}>
                                Permission:
                            </span>
                            <div
                                onClick={() => setPermission(permission === 'view' ? 'modify' : 'view')}
                                className="relative w-[60px] h-[28px] cursor-pointer rounded-full border border-gray-300 flex items-center transition-all duration-200"
                                style={{
                                    backgroundColor: permission === 'modify' ? '#41EAD4' : '#AEB8CA',
                                    justifyContent: permission === 'modify' ? 'flex-end' : 'flex-start',
                                    padding: '4px',
                                    display: 'flex',
                                }}
                            >
                                <div
                                    className="w-[24px] h-[24px] bg-white rounded-full shadow-md flex items-center justify-center transition-transform duration-200"
                                    style={{
                                        transform: permission === 'modify' ? 'translateX(32px)' : 'translateX(0)',
                                    }}
                                >
                                    {permission === 'modify' ? (
                                        <FormOutlined className="text-teal-500 text-xs" />
                                    ) : (
                                        <EyeOutlined className="text-gray-500 text-xs" />
                                    )}
                                </div>
                            </div>
                            <span
                                style={{
                                    marginLeft: '10px',
                                    fontWeight: 500,
                                    color: permission === 'modify' ? '#41EAD4' : '#AEB8CA',
                                }}
                            >
                                {permission === 'modify' ? 'Can Modify' : 'Can View'}
                            </span>
                        </div>
                    )}

                    {/* Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button
                            onClick={() => setPermissionModalVisible(false)}
                            style={{
                                backgroundColor: '#D0D5DD',
                                color: '#667085',
                                padding: '8px 16px',
                                marginRight: '12px',
                                borderRadius: '8px',
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!assignUser} // Prevent empty submissions
                            style={{
                                backgroundColor: assignUser ? '#12B76A' : '#D0D5DD',
                                color: assignUser ? '#FFFFFF' : '#667085',
                                padding: '8px 16px',
                                borderRadius: '8px',
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
        
        </Modal>
        </div>
    );
};

export default EditPermissions;
