import React, { useState, useRef, useEffect } from "react";
import { Avatar, message } from "antd";
import { MoreOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";
// import { ReactComponent as PdfIcon } from "../../../assets/SVG/PDFSVG.svg";
import DeleteEmptyTrashModal from "../DeleteModal/DeleteEmptyTrashModal"; // Updated import
import RestoreTrashDocumentModal from "../EditModal/RestoreTrashDocumentModal"; // Updated import

import { ReactComponent as PdfIcon } from "../../../assets/SVG/PDFSVG.svg";
import { ReactComponent as ImageIcon } from "../../../assets/SVG/JPG.svg";
import { ReactComponent as PngIcon } from "../../../assets/SVG/PNG.svg";
import { ReactComponent as DocsIcon } from "../../../assets/SVG/Docs.svg";
import { ReactComponent as HtmlIcon } from "../../../assets/SVG/Html.svg";
import { ReactComponent as ExcelIcon } from "../../../assets/SVG/XLSX.svg";
import { ReactComponent as PptIcon } from "../../../assets/SVG/PPT.svg";
import { ReactComponent as MiscIcon } from "../../../assets/SVG/MiscIcon.svg";

import trash from "../../../assets/PNG/trash.png"



const TrashDocCard = ({
  trashDocumentList,
  setTriggerNextTrashDoc,
  selectedDocListIDs,
  setSelectedDocListIDs,
  setSelectedDELETEDocList,
  setSelectedRESTOREDocList,
}) => {


  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setDocuments(trashDocumentList)
  }, [trashDocumentList]);



  // console.log({ documents });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDeleteClick = (doc) => {
    setSelectedDoc(doc);
    setIsModalVisible(true);
    setSelectedDocListIDs([])
  };

  const handleConfirmDelete = () => {
    console.log("Document deleted:", selectedDoc);
    setIsModalVisible(false);
    // setSelectedDoc(null);
    setSelectedDELETEDocList([selectedDoc._id])
  };

  const handleRestoreClick = (doc) => {
    setSelectedDoc(doc);
    setIsRestoreModalVisible(true);
    setSelectedDocListIDs([])
  };

  const handleConfirmRestore = () => {
    console.log("Document restored:", selectedDoc);
    setIsRestoreModalVisible(false);
    // setSelectedDoc(null);
    setSelectedRESTOREDocList([selectedDoc._id])
  };


  const handleCheckboxChange = (docId) => {
    setSelectedDocListIDs((prevSelected) => {
      if (prevSelected.includes(docId)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== docId);
      } else {
        if (prevSelected.length >= 10) {
          message.error("You can only select up to 10 documents.");
          return prevSelected; // Do not add a new one if limit reached
        }
        // Otherwise, add the new ID
        return [...prevSelected, docId];
      }
    });
  };


  const handleScroll = () => {
    const container = listRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      // Check if scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setTriggerNextTrashDoc((prev) => prev + 1); // Increment the trigger
      }
    }
  };
  const getDocumentIcon = (docType) => {
    switch (docType) {
      case "pdf":
        return <PdfIcon className="w-10 h-10 text-red-600" />;
      case "jpg":
      case "jpeg":
        return <ImageIcon className="w-10 h-10 text-gray-600" />;
      case "png":
        return <PngIcon className="w-10 h-10 text-gray-600" />;
      case "doc":
      case "docx":
        return <DocsIcon className="w-10 h-10 text-blue-600" />;
      case "html":
        return <HtmlIcon className="w-10 h-10 text-orange-600" />;
      case "xlsx":
        return <ExcelIcon className="w-10 h-10 text-green-600" />;
      case "xls":
        return <ExcelIcon className="w-10 h-10 text-green-600" />;
      case "ppt":
        return <PptIcon className="w-10 h-10 text-purple-600" />;
      default:
        return <MiscIcon className="w-10 h-10 text-gray-500" />; // Fallback icon
    }
  };


  return (
    <div
      className="flex flex-col items-end gap-4"
      style={{ overflowY: "auto", maxHeight: "550px", padding: "8px" }}
      onScroll={handleScroll}
      ref={listRef}
    >
      {documents.map((doc, index) => (
        <div
          key={index}
          className="flex justify-between items-center w-full p-4 border rounded-lg shadow-sm bg-white gap-4 min-w-[900px]"
        >
          {/* Checkbox and Icon */}
          <div className="p-2 flex items-center gap-4 w-[5%] mr-4">
            <input
              type="checkbox"
              className="w-4 h-4 flex justify-center items-center shrink-0 text-[#59616E]"
              checked={selectedDocListIDs.includes(doc._id)}
              onChange={() => handleCheckboxChange(doc._id)}

            />
            {/* <div className="flex justify-center items-center w-[49px] h-[47px] px-[1.623px] rounded-md">
              <PdfIcon className="w-5 h-5 text-red-600" />
            </div> */}

            <div className="flex justify-center items-center w-[49px] h-[47px] px-[1.623px] rounded-md">
              {getDocumentIcon(doc.docType)}
            </div>


          </div>

          {/* Document Info */}
          <div className="flex flex-col w-[25%]">
            <h3 className="self-stretch text-[16px] font-semibold leading-[24px] text-[#1B2028] truncate">
              {doc.docName}
            </h3>
            <p className="text-[14px] font-normal leading-[22px] text-[#525F6B]">
              Deleted on {doc.updatedAt}
            </p>
          </div>

          {/* Type and Version Info */}
          <div className="flex flex-col w-[20%]">
            <p className="text-sm font-medium text-gray-700">
              Type: <span className="font-normal">{doc.docType}</span>
            </p>
            <p className="text-sm font-medium text-gray-700">
              Version: <span className="font-normal">{doc.totalVersions}</span>
            </p>
          </div>

          {/* File Size and Uploader Info */}
          <div className="flex flex-col w-[20%]">
            <p className="text-sm font-medium text-gray-700">
              File size: <span className="font-normal">{doc.docSizeFormatted}</span>
            </p>
            <div className="flex items-center gap-2">
              <p className="text-[14px] font-semibold leading-[22px] text-[#525F6B]">
                Uploaded by: <span className="font-normal">{doc.userName}</span>
              </p>
              {/* <Avatar src={doc.userName} size={24} /> */}
            </div>
          </div>

          {/* Tags Section */}
          <div className="flex flex-col w-[30%] gap-2 relative">
            <h3 className="self-stretch text-[16px] font-semibold leading-[24px] text-[#1B2028]">
              Tags
            </h3>

            {/* MoreOutlined Icon in Top-Right */}
            <div className="absolute top-5 right-0">
              <MoreOutlined
                className="text-gray-500 cursor-pointer text-2xl"
                onClick={() => setActiveDropdown(activeDropdown === index ? null : index)}
              />
              {/* Dropdown Menu */}
              {activeDropdown === index && (
                <div
                  ref={dropdownRef}
                  className="absolute top-full right-0 mt-0 bg-white shadow-lg rounded-lg w-48 p-2 z-0"
                >
                  <button
                    className="flex items-center gap-2 w-full px-2 py-2 text-left text-[#1B2028] hover:bg-[#D1F8F0] rounded-md"
                    onClick={() => handleRestoreClick(doc)}
                  >
                    <UndoOutlined className="text-[#192028]" />
                    <span>Restore Document</span>
                  </button>

                  <button
                    className="flex items-center gap-2 w-full px-2 py-2 text-left text-[#1B2028] hover:bg-[#D1F8F0] rounded-md"
                    onClick={() => handleDeleteClick(doc)}
                  >
                    <DeleteOutlined className="text-[#192028]" />
                    <span>Permanently Delete</span>
                  </button>

                </div>
              )}
            </div>

            <div className="flex items-center gap-2 flex-wrap max-w-full">
              {doc.tags.length > 0 ? (
                doc.tags.map((tag, i) => (
                  <span
                    key={i}
                    className="bg-teal-100 text-teal-600 px-2 py-1 rounded-md text-sm"
                  >
                    #{tag}
                  </span>
                ))
              ) : (
                <p className="text-[14px] font-normal leading-[22px] text-[#525F6B]">
                  No Tags
                </p>
              )}
            </div>



          </div>


        </div>
      ))}
      {documents.length === 0 && (
        <div className="flex flex-col justify-center items-center w-full min-h-[300px]">
          <img
            src={trash}
            alt="Trash"
            className="max-w-[300px] max-h-[300px] mb-4"
          />
          <h1 className="text-xl font-bold mb-2">Trash is Empty</h1>
          <p className="text-gray-600 text-sm">Deleted documents will appear here.</p>
        </div>
      )}

      {/* Modal Integration */}
      <DeleteEmptyTrashModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onConfirmDelete={handleConfirmDelete}
      />

      <RestoreTrashDocumentModal
        visible={isRestoreModalVisible}
        onClose={() => setIsRestoreModalVisible(false)}
        onConfirmRestore={handleConfirmRestore}
      />
    </div>
  );
};

export default TrashDocCard;




