
import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
  BarsOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
  FileOutlined,
  SortAscendingOutlined,
  DiffOutlined,
} from '@ant-design/icons';

const sortOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Created at', value: 'createdAt' },
  { label: 'Modified on', value: 'modifiedAt' },
  { label: 'Number of Documents', value: 'numberOfDocuments' },
];

const NestedFolderHeader = ({
  showAddFolderModal,      // Function to show Add Folder modal
  description,             // Folder description
  setSortOrder,            // Function to set the sorting order and trigger API call
  currentSortOption,       // Current sort option (Name, Date, Modified, etc.)
  folderName,              // Folder name
}) => {
  const [sortOrder, setSortOrderState] = useState(1); // Ascending/descending sorting order
  const [hoveredItem, setHoveredItem] = useState(null); // Track which sort option is hovered

  // Function to handle sort order change
  const handleSortOrderChange = (newOrder, sortOption) => {
    setSortOrderState(newOrder);      // Update local state
    setSortOrder(newOrder, sortOption); // Call the prop to trigger the API call
  };

   // Sort dropdown menu
   const sortMenu = (
    <Menu
      style={{
        display: 'flex',
        width: '250px',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow:
          '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
    >
      {sortOptions.map((option, index) => (
        <Menu.Item
          key={option.value}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderRadius: '4px',
            margin: '4px 0',
            padding: '8px 12px',
            color: hoveredItem === index ? '#1B2028' : '#798897',
            backgroundColor: hoveredItem === index ? '#D1F8F0' : 'transparent',
            transition: 'background-color 0.3s ease, color 0.3s ease',
          }}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              {option.label === 'Name' && <SortAscendingOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label === 'Created at' && <CalendarOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label === 'Modified on' && <FileOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label === 'Number of Documents' && <DiffOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label}
            </div>
            <div className="flex items-center">
              <ArrowUpOutlined
                style={{
                  color: currentSortOption === option.value && sortOrder === 1 ? '#1B2028' : '#798897',
                  marginRight: '4px',
                  cursor: 'pointer',
                }}
                className={currentSortOption === option.value && sortOrder === 1 ? 'text-teal-600' : ''}
                onClick={() => handleSortOrderChange(1, option.value)}
              />
              <ArrowDownOutlined
                style={{
                  color: currentSortOption === option.value && sortOrder === -1 ? '#1B2028' : '#798897',
                  cursor: 'pointer',
                }}
                className={currentSortOption === option.value && sortOrder === -1 ? 'text-teal-600' : ''}
                onClick={() => handleSortOrderChange(-1, option.value)}
              />
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="flex justify-between items-center px-6 py-4 w-full h-20 box-border min-w-[1000px]">
      {/* Left Section: Folder name and description */}
      <div>
        <h3
          style={{
            color: 'var(--Light-Charcoal-Default, #192028)',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '28px',
          }}
        >
          {folderName}
        </h3>
        <p
          style={{
            color: '#59616E',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '22px',
          }}
        >
          {description || 'No description provided.'}
        </p>
      </div>

      {/* Right Section: Buttons for sort and add folder */}
      <div className="flex items-center gap-6">
        {/* Sort By Button */}
        <Dropdown overlay={sortMenu} trigger={['click']}>
          <Button
            className="flex items-center px-4 py-2 rounded-lg"
            style={{
              width: '104px',
              justifyContent: 'space-between',
              color: '#000000E0',
              backgroundColor: '#FFF',
              borderColor: '#D9D9D9',
              borderRadius: '8px',
              padding: '0',
              height: '40px',
            }}
          >
            <span
              style={{
                flexGrow: 1,
                textAlign: 'left',
                paddingLeft: '16px',
              }}
            >
              Sort By
            </span>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #41EAD4',
                borderRadius: '4px',
                height: '40px',
                width: '40px',
              }}
            >
              <BarsOutlined
                style={{
                  color: '#41EAD4',
                  fontSize: '16px',
                }}
              />
            </span>
          </Button>
        </Dropdown>

        {/* Add Folder Button */}
        <Button
          icon={<PlusOutlined style={{ color: '#FF6F61' }} />}
          onClick={showAddFolderModal}
          className="flex items-center justify-center px-4 py-2 rounded-lg"
          style={{
            borderColor: '#FF6F61',
            color: '#FF6F61',
            padding: '0px 16px',
            height: '40px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 0px 0px rgba(5, 145, 255, 0.10)',
          }}
        >
          <span style={{ color: '#FF6F61' }}>Folder</span>
        </Button>
      </div>
    </div>
  );
};

export default NestedFolderHeader;
