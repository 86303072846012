




import React, { useState } from 'react';
import { EyeOutlined, MoreOutlined, EditOutlined, FileAddOutlined, FormOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import FoldersSvgicon from '../../../assets/SVG/Folders.svg'; // Folder icon import
import { useAuth } from '../../authcontext';
import EditDetails from '../EditModal/EditFolderDetails';
import EditPermissions from '../EditModal/EditPermissionsFolderCardModal';
import AddDocInsideFolderModal from '../Modal/AddDocInsideFolderModal';
import ArrowPointer from '../../../assets/SVG/Polygon.svg'; // Import the arrow icon


const FolderCard = ({ folder, handleFolderClick }) => {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isPermissionModalVisible, setPermissionModalVisible] = useState(false);
  const [isAddDocumentModalVisible, setAddDocumentModalVisible] = useState(false);
  const { userId } = useAuth();
  const [showTagPopup, setShowTagPopup] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
  


  const getMenu = () => (
    <Menu
      style={{
        display: 'flex',
        width: '199px',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
    >
   <Menu.Item
  key="1"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '4px',
    margin: '4px 0',
    padding: '8px 12px',
    color: hoveredItem === 1 ? '#1B2028' : '#798897',
    backgroundColor: hoveredItem === 1 ? '#D1F8F0' : 'transparent',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  }}
  onMouseEnter={() => setHoveredItem(1)}
  onMouseLeave={() => setHoveredItem(null)}
  onClick={(info) => {
    if (info?.domEvent) {
      info.domEvent.stopPropagation(); // Prevent folder click event
    }
    setEditModalVisible(true); // Open the Edit Details modal
  }}
>
  <EditOutlined
    style={{
      marginRight: '8px',
      color: hoveredItem === 1 ? '#1B2028' : '#798897',
      width: '16px',
      height: '16px',
    }}
  />
  Edit Details
</Menu.Item>

<Menu.Item
  key="2"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '4px',
    padding: '8px 12px',
    color: hoveredItem === 2 ? '#1B2028' : '#798897',
    margin: '4px 0',
    backgroundColor: hoveredItem === 2 ? '#D1F8F0' : 'transparent',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  }}
  onMouseEnter={() => setHoveredItem(2)}
  onMouseLeave={() => setHoveredItem(null)}
  onClick={(info) => {
    if (info?.domEvent) {
      info.domEvent.stopPropagation(); // Prevent folder click event
    }
    setPermissionModalVisible(true); // Open the Edit Permissions modal
  }}
>
  <FormOutlined
    style={{
      marginRight: '8px',
      color: hoveredItem === 2 ? '#1B2028' : '#798897',
      width: '16px',
      height: '16px',
    }}
  />
  Edit Permission
</Menu.Item>

<Menu.Item
  key="3"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '4px',
    padding: '8px 12px',
    color: hoveredItem === 3 ? '#1B2028' : '#798897',
    margin: '4px 0',
    backgroundColor: hoveredItem === 3 ? '#D1F8F0' : 'transparent',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  }}
  onMouseEnter={() => setHoveredItem(3)}
  onMouseLeave={() => setHoveredItem(null)}
  onClick={(info) => {
    if (info?.domEvent) {
      info.domEvent.stopPropagation(); // Prevent folder click event
    }
    setAddDocumentModalVisible(true); // Open the Add Document modal
  }}
>
  <FileAddOutlined
    style={{
      marginRight: '8px',
      color: hoveredItem === 3 ? '#1B2028' : '#798897',
      width: '16px',
      height: '16px',
    }}
  />
  Add Document
</Menu.Item>

    </Menu>
  );



  return (
    <div
      key={folder._id}
      className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm relative cursor-pointer"
      style={{
        maxWidth: '320px',
        maxHeight: '240px',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onClick={() => handleFolderClick(folder._id)}
    >
      {/* Top-left Folder Icon */}
      <div className="absolute top-3 left-3 w-[25.337px] h-[25.337px] flex-shrink-0">
        <img src={FoldersSvgicon} alt="Folder Icon" className="w-full h-full" />
      </div>

      {/* Top-right Organization Tag and More Options Button */}
      <div
        style={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px', // Ensure spacing between tag and More icon
        }}
      >
        <span
          className="flex items-center gap-[4px] px-[8px] py-[1px] border rounded-[4px] text-[12px] font-semibold"
          style={{
            borderColor:
              folder.type === 'org'
                ? '#723CD1'
                : folder.type === 'team'
                ? '#0077CF'
                : '#4ED2BF',
            backgroundColor:
              folder.type === 'org'
                ? '#F3EFFF'
                : folder.type === 'team'
                ? '#F4FAFF'
                : '#F8FEFD',
            color:
              folder.type === 'org'
                ? '#723CD1'
                : folder.type === 'team'
                ? '#0077CF'
                : '#007768',
            whiteSpace: 'nowrap', // Prevent tag from wrapping
          }}
        >
          {folder.type === 'org' ? 'Org' : folder.type === 'team' ? 'Team' : 'Self'}
        </span>

        <Dropdown overlay={getMenu()} trigger={['click']}>
          <MoreOutlined
            style={{
              height: '8px',
              fontSize: '22px',
              color: '#768090',
              cursor: 'pointer',
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event propagation
          />
        </Dropdown>
      </div>

      {/* Folder Name */}
      <h3
        className="text-[#101828] font-medium text-[12px] leading-[18px] font-sf-pro-text truncate max-w-[80px] ml-[40px]"
        title={folder.name} // Show full name on hover
      >
        {folder.name}
      </h3>

      {/* Last Modified Section (Left Aligned) */}
      <p className="text-[#667085] font-medium text-[12px] leading-[20px] font-sf-pro-text ml-[40px]">
        {`Modified by ${folder.modifiedBy}`} <br />
        {new Date(folder.modifiedAt).toLocaleTimeString()} on {new Date(folder.modifiedAt).toLocaleDateString()}
      </p>

{/* Tag Section */}
<div
  className="relative mb-1 ml-8"
  style={{ width: '215px', display: 'flex', alignItems: 'flex-end' }}
>
  {/* Tag Box */}
  <div
    className="flex items-center px-3 py-1 bg-[#F4FEFC] border border-[#D9DEE7] rounded-lg text-xs cursor-pointer"
    onMouseEnter={() => setShowTagPopup(true)}
    onMouseLeave={() => setShowTagPopup(false)}
    style={{
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
      zIndex: 1,
    }}
  >
    <span className="text-[#59616E] font-medium">#tags</span>
    <InfoCircleOutlined
      style={{
        fontSize: '12px',
        color: '#798897',
        marginLeft: '4px',
      }}
    />
  </div>

  {/* Static Arrow Pointer */}
  <img
    src={ArrowPointer}
    alt="Arrow Pointer"
    style={{
      position: 'absolute',
      left: 'calc(30% + 5px)', // Static position near the main tag
      top: '50%',
      transform: 'translateY(-50%)',
      maxwidth: '16px',
      height: '12px',
      zIndex: 0,
      fill: '#99EDDE', // Applies the teal color
    }}
  />

  {showTagPopup && (
        <>
          <div
            className="absolute flex flex-wrap gap-2 p-1 w-[110px] bg-[#FFFFFF] shadow-lg rounded-lg"
            style={{
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
              left: '38%',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 10,
            }}
          >
            {folder.tags?.length > 0 ? (
              folder.tags.map((tag, index) => (
                <span key={index} className="px-2 py-1 bg-[#E8F7F3] rounded-md text-xs text-[#59616E]">#{tag}</span>
              ))
            ) : (
              <span className="px-2 py-1 bg-[#E8F7F3] rounded-md text-xs text-[#59616E]">No Available Tags</span>
            )}
          </div>
        </>
      )}
</div>



      {/* Progress Bar */}
      <div className="relative w-[220px] h-[3px] bg-[#D9DEE7] flex-shrink-0 mt-3 mx-auto">
        <div
          className="absolute h-full bg-[#4ED2BF]"
          style={{
            width: `${(folder.items / 30) * 100}%`, // Dynamic width based on folder items
          }}
        ></div>
      </div>

     {/* Files and Folder Count (Left-Aligned) */}
<div className="inline-flex items-center h-[30px] gap-2 flex-shrink-0 mt-3 justify-start">
  <span
    className="px-2 py-1 font-semibold text-[#768090] bg-[#F3F4F6] rounded-full"
    style={{ fontSize: '14px',fontweight: '600'
    }}
  >
    {folder?.folderCount} Folders
  </span>
  <span
    className="px-2 py-1 font-semibold text-[#768090] bg-[#F3F4F6] rounded-full"
    style={{ fontSize: '14px' }}
  >
    {folder?.items} Files
  </span>
</div>



 {/* Conditional Icon */}
 <div
  className="absolute bottom-[18px] right-[14px] w-[24px] h-[24px] flex justify-center items-center rounded-full"
  style={{
    backgroundColor: 'rgba(141, 242, 229, 0.50)', // Apply the desired RGBA background color
    boxShadow: folder.folderPermission === 'modify' 
      ? '0px 2px 6px 0px rgba(65, 234, 212, 0.50)'
      : '0px 2px 6px 0px rgba(174, 184, 202, 0.50)',
  }}
>

        {folder.folderPermission === 'modify' ? (
          <EditOutlined style={{ fontSize: '14px', color: '#000000' }} />
        ) : (
          <EyeOutlined style={{ fontSize: '14px', color: '#000000' }} />
        )}
      </div>



<EditDetails folder={folder} isEditModalVisible={isEditModalVisible} setEditModalVisible={setEditModalVisible} />
            <EditPermissions folder={folder} isPermissionModalVisible={isPermissionModalVisible} setPermissionModalVisible={setPermissionModalVisible} />
            <AddDocInsideFolderModal
  isVisible={isAddDocumentModalVisible}
  handleCancel={(event) => {
    if (event?.stopPropagation) {
      event.stopPropagation(); // Prevent any navigation
    }
    setAddDocumentModalVisible(false); // Close the modal
  }}
  userId={userId}
  folderId={folder._id}
/>
      

    </div>
  );
};


const FolderCardSkeleton = () => (
    <div className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm max-w-xs h-60 flex justify-center items-center">
        {/* Skeleton Placeholder */}
    </div>
);

export { FolderCardSkeleton };



export default FolderCard;

