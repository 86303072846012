

import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";
import axios from "axios";

const TeamTable = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [moreToCome, setMoreToCome] = useState(true);
  const tableRef = useRef(null);

  // Fetch teams from API
  const fetchTeams = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(
        page === 0
          ? `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?viewAll=true&archive=true`
          : `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?viewAll=true&archive=true&page=${page}`
      );

      const { teamsList, moreToCome } = response.data.data;

      setTeams((prevTeams) =>
        page === 0 ? teamsList : [...prevTeams, ...teamsList]
      );
      setMoreToCome(moreToCome);
    } catch (error) {
      console.error("Error fetching teams:", error);
      message.error("Failed to fetch teams. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams(0);
  }, []);

  // Restore a team
  const handleRestore = async (teamId) => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}/restore`
      );
      message.success("Team restored successfully.");

      // Refresh data after restore
      fetchTeams(0);
      setCurrentPage(0);
    } catch (error) {
      console.error("Error restoring team:", error);
      message.error("Failed to restore the team. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Handle infinite scroll pagination (inside the table body)
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      moreToCome &&
      !loading
    ) {
      fetchTeams(currentPage + 1);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div className="team-deletion-log flex flex-col max-w-[1430px] p-4 gap-4 rounded-lg bg-white shadow-md">
      <h2 className="text-lg font-semibold text-gray-800">Team Deletion Log</h2>
      <div
        className="overflow-auto"
        style={{ maxHeight: "352px" }}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <table
          className="table-auto w-full border-separate"
          style={{ borderSpacing: "0 10px" }}
        >
          <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
            <tr>
              <th className="px-4 py-2">Team Name</th>
              <th className="px-4 py-2">Deleted At</th>
              <th className="px-4 py-2">Deleted By</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team) => (
              <tr
                key={team._id}
                className="bg-white shadow-sm rounded-lg"
                style={{
                  borderBottom: "1px solid #e2e8f0", 
                }}
              >
                <td className="px-4 py-2">{team.name}</td>
                <td className="px-4 py-2">
                  {new Date(team.modifiedAt).toLocaleString()}
                </td>
                <td className="px-4 py-2">{team.managerName}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleRestore(team._id)}
                    disabled={loading}
                    className="px-4 py-2 w-32 rounded-lg flex items-center justify-center bg-[#41EAD4] text-[#192028] shadow-sm focus:outline-none disabled:opacity-50"
                  >
                    {loading ? "Restoring..." : "Restore Team"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamTable;
