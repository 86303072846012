import React from "react";
import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const DeleteEmptyTrashModal = ({ visible, onClose, onConfirmDelete }) => {
  return (
    <Modal open={visible} onCancel={onClose} footer={null} centered>
      <div className="text-center">
        <div className="flex items-center justify-center gap-2 text-[#1B2028]">
          <h2 className="text-lg font-semibold text-[#59616E] mb-0">
            Empty Trash
          </h2>
          <DeleteOutlined
            className="text-[#59616E]"
            style={{ width: 16, height: 16 }}
          />
        </div>

        <p className=" mt-6 text-[#798897] text-center font-sans text-sm font-normal leading-[22px] self-stretch mb-6">
          Are you sure you want to permanently delete the document(s)?
           This action cannot be undone.
        </p>
        
        <div className="flex justify-end gap-4">
          <button
            className="flex justify-center items-center h-8 px-4 bg-[#C4CBD8] text-[#1B2028] rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="flex justify-center items-center h-8 px-4 bg-[#FF412E] text-white rounded-lg "
            onClick={onConfirmDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteEmptyTrashModal;
