import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import AddNestedFolderModal from '../../Services/Modal/AddNestedFolderModal';
import NestedFolderCard from '../../Services/UI/NestedFolderCard';
import { useAuth } from '../../authcontext';
import NestedFolderHeader from './NestedFolderHeader';
import axios from 'axios';
import NestedFoldersEmptyState from '../../Services/UI/EmptyState/NestedFoldersEmptyState';

const NestedFolders = () => {
  const { folderId } = useParams(); // Current folderId from the route
  const { userId } = useAuth(); // Current userId from AuthContext
  const navigate = useNavigate(); // Navigation hook

  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [folders, setFolders] = useState([]); // Holds the fetched folders
  const [loading, setLoading] = useState(true); // Loading state
  const [filter, setFilter] = useState(null); // Filter criteria
  const [sortOrder, setSortOrder] = useState({ sortBy: 'name', sortOrder: 1 }); // Sort order (ascending by name initially)
  const [parentId, setParentId] = useState(null); // Parent folder ID
  const [permission, setPermission] = useState(null); // Folder permissions
  const [folderType, setFolderType] = useState(null); // Folder type
  const [folderName, setFolderName] = useState(null); // Folder name
  const [description, setDescription] = useState(''); // Folder description

  // Fetch folder details
  useEffect(() => {
    const fetchFolderDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folderId}`);
        const { folder } = response.data.data;
        console.log('Fetching folders for folderId:', folderId);
        console.log('API Response:', response.data);
        
        setParentId(folder.parentId || folder._id);
        setPermission(folder.folderPermission);
        setFolderType(folder.folderType);
        setFolderName(folder.name);
        setDescription(folder.description);
      } catch (error) {
        message.error('Failed to load folder details.');
      }
    };
    if (folderId) {
      fetchFolderDetails();
    }
  }, [folderId]);


  // Fetch nested folders
  const fetchFolders = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/?userId=${userId}`;
      if (folderId) url += `&immediateParentId=${folderId}`;
      if (filter) url += `&filter=${filter}`;
      if (sortOrder) url += `&sortBy=${sortOrder.sortBy}&sortOrder=${sortOrder.sortOrder}`;

      const response = await axios.get(url);
      const data = response.data.data.folders;

      if (data) {
        setFolders(data);
      } else {
        setFolders([]);
        message.warning('No folders found.');
      }
    } catch (error) {
      message.error('Error fetching folders.');
    } finally {
      setLoading(false);
    }
  };

  // Trigger folder fetching on component mount or dependencies change
  useEffect(() => {
    fetchFolders();
  }, [folderId, filter, sortOrder]);

  // Show the modal to add a nested folder
  const showAddFolderModal = () => {
    setIsModalVisible(true);
  };

  // Close the modal
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Callback for folder creation
  const onFolderCreated = () => {
    fetchFolders(); // Refresh the folder list
    setIsModalVisible(false); // Hide the modal
  };

  // Navigate to the selected folder
  const handleFolderClick = (folderId) => {
    navigate(`/documents/folders/${folderId}`);
  };

  

  return (
    <div style={{ padding: '24px 16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <NestedFolderHeader
        showAddFolderModal={showAddFolderModal}
        description={description}
        folderName={folderName}
        setSortOrder={(sortOrder, sortBy) => setSortOrder({ sortOrder, sortBy })}
        setFilter={setFilter}
        currentSortOption={sortOrder.sortBy}
      />
      {loading ? (
        <p>Loading folders...</p>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {folders.length > 0 ? (
            folders.map((folder) => (
              <NestedFolderCard
                key={folder._id}
                folder={folder}
                userId={userId}
                permission={permission}
                folderName={folder.name}
                folderType={folderType}
                handleFolderClick={() => handleFolderClick(folder._id)}
                style={{ flex: '0 0 auto' }}
              />
            ))
          ) : (
            <div className="ml-80 px-8">
              <NestedFoldersEmptyState />
            </div>
          )}
        </div>
      )}
      {isModalVisible && (
        <AddNestedFolderModal
          visible={isModalVisible}
          handleCancel={handleModalCancel}
          folderId={folderId}
          userId={userId}
          parentId={parentId}
          onFolderCreated={onFolderCreated} // Pass the callback
        />
      )}
    </div>
  );
};

export default NestedFolders;
