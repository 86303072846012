import React from "react";
import { Modal, Button } from "antd";
import { UndoOutlined, CloseOutlined } from "@ant-design/icons";

const RestoreTrashDocumentModal = ({ visible, onClose, onConfirmRestore }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      closeIcon={<CloseOutlined className="text-[#798897]" />}
    >
      <div className="text-center">
        {/* Title Section */}
        <div className="flex items-center justify-center gap-2 text-[#59616E]">
          <h2 className="text-base font-semibold leading-6">
            Restore Document
          </h2>
          <UndoOutlined style={{ width: 16, height: 16 }} />
        </div>

        {/* Description */}
        <p className="mt-2 text-[#798897] text-center font-sans text-sm font-normal leading-[22px]">
          Are you sure you want to restore the document(s) from the trash?
           It will be moved back to its original location.
        </p>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4 mt-4">
          <button
            onClick={onClose}
            className="flex justify-center items-center h-8 px-4 bg-[#C4CBD8] text-[#1B2028] rounded-lg"
          >
            Close
          </button>
          <button
            onClick={onConfirmRestore}
            className="flex justify-center items-center h-8 px-4 bg-[#41EAD4] text-[#1B2028] rounded-lg"
          >
            Restore
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RestoreTrashDocumentModal;
