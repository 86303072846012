
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Spin, notification, message } from "antd";
import FolderCard from "../../Services/UI/DocCard";
import DocHeader from "./DocHeader";
import { useAuth } from "../../authcontext";
import FolderEmptyState from "../../Services/UI/EmptyState/DocEmptyState";

const FolderDoc = () => {
  const [data, setData] = useState([]);
  const { userId } = useAuth();
  const { folderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [moreToCome, setMoreToCome] = useState(true);
  const navigate = useNavigate();
  const observer = useRef();

  const allowedSortOptions = ["docName", "docSize", "uploadedAt"];
  const [sortOption, setSortOption] = useState("uploadedAt");
  const [sortOrder, setSortOrder] = useState(-1);
  const [filterOn, setFilterOn] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [usePagination, setUsePagination] = useState(false);


  useEffect(()=>{ const fetchData = async (pageNumber) => {
    if (!moreToCome || loading) return;
    setLoading(true);
    try {
      const params = {
        sortBy: allowedSortOptions.includes(sortOption) ? sortOption : "uploadedAt",
        sortOrder: sortOrder || 1,
      };

      if (usePagination) {
        params.page = pageNumber;
      }

      if (filterOn && filterValue) {
        params.filterOn = filterOn;
        params.filterValue = filterValue;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/?folder=${folderId}`,
        { params }
      );

      setData((prevData) => (pageNumber === 0 ? response.data.data.finalList : [...prevData, ...response.data.data.finalList]));
      setMoreToCome(response.data.data.moreToCome);
    } catch (error) {
      console.error("Error fetching folder content", error);
      notification.error({ message: "Error", description: "Failed to fetch folder content." });
    } finally {
      setLoading(false);
    }
  };
  fetchData();
})
  
  const fetchData = async (pageNumber) => {
    if (!moreToCome || loading) return;
    setLoading(true);
    try {
      const params = {
        sortBy: allowedSortOptions.includes(sortOption) ? sortOption : "uploadedAt",
        sortOrder: sortOrder || 1,
      };

      if (usePagination) {
        params.page = pageNumber;
      }

      if (filterOn && filterValue) {
        params.filterOn = filterOn;
        params.filterValue = filterValue;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/?folder=${folderId}`,
        { params }
      );

      setData((prevData) => (pageNumber === 0 ? response.data.data.finalList : [...prevData, ...response.data.data.finalList]));
      setMoreToCome(response.data.data.moreToCome);
    } catch (error) {
      console.error("Error fetching folder content", error);
      notification.error({ message: "Error", description: "Failed to fetch folder content." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([]);
    setPage(0);
    setMoreToCome(true);
    setUsePagination(false);
    fetchData(1);
  }, [folderId, sortOption, sortOrder, filterOn, filterValue]);

  useEffect(() => {
    if (page > 0) fetchData(page);
  }, [page]);

  const lastDocRef = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && moreToCome) {
        setUsePagination(true);
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  };

  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  return (
    <div className="p-5">
     <DocHeader
  folderId={folderId}
  filterDocuments={(fOn, fVal) => {
    setFilterOn(fOn);
    setFilterValue(fVal);
    setUsePagination(false);
  }}
  sortDocuments={(newOrder, option) => {
    if (allowedSortOptions.includes(option)) {
      setSortOrder(newOrder);
      setSortOption(option);
      setUsePagination(false);
    } else {
      notification.error({ message: "Invalid Sort Option", description: "Please select a valid sort option." });
    }
  }}
  currentSortOption={sortOption}
  sortOrder={sortOrder}
  onUploadSuccess={() => fetchData(0)} // ✅ Refresh API on upload success
/>


      <div className="mb-5">
        {data.length > 0 ? (
          <div className="space-y-4 mt-4">
            {data.map((doc, index) => (
              <FolderCard
                key={doc._id}
                doc={doc}
                handleDocumentClick={handleDocumentClick}
              />
            ))}
            <div ref={lastDocRef} style={{ height: "20px" }} />
          </div>
        ) : !loading ? (
          <FolderEmptyState />
        ) : null}
      </div>

      {loading && (
        <div className="flex justify-center items-center py-4">
          <Spin tip="Loading more documents..." />
        </div>
      )}
    </div>
  );
};

export default FolderDoc;