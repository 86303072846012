
import React, { useState } from 'react';
import { MoreOutlined, UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import EditTeamModal from '../../Services/EditModal/EditTeamModal';
import DeleteTeamModal from '../../Services/DeleteModal/DeleteTeamModal';
import LeaveTeamModal from '../../Services/DeleteModal/LeaveTeamModal';
import { useAuth } from '../../authcontext';
import { useNavigate } from 'react-router-dom'; 

const TeamCard = ({ team, onEditTeam, onDeleteTeam, onLeaveTeam, viewAll, onDeleteSuccess }) => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const isCoManager = team.comanagerId.includes(userId);

  const handleCardClick = () => {
   if(viewAll){ 
    // navigate(`/app/teams/${team._id}`);
    }// Navigate to the team page using the team ID
  };

  const handleMenuClick = (e) => {
    if (e.key === 'edit') {
      setIsEditModalVisible(true);
      setVisibleDropdown(null);
    } else if (e.key === 'delete') {
      setIsDeleteModalVisible(true);
      setVisibleDropdown(null);
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteSuccess = () => {
    onDeleteTeam(team._id);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
        padding: '4px',
      }}
    >
      <Menu.Item
        key="edit"
        icon={<EditOutlined />}
        onMouseEnter={() => setHoveredItem('edit')}
        onMouseLeave={() => setHoveredItem(null)}
        style={{
          backgroundColor: hoveredItem === 'edit' ? '#D1F8F0' : '#FFF',
          padding: '8px 16px',
          color: hoveredItem === 'edit' ? '#1B2028' : '#192028',
        }}
      >
        Edit
      </Menu.Item>
      {team.managerId === userId && (
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onMouseEnter={() => setHoveredItem('delete')}
          onMouseLeave={() => setHoveredItem(null)}
          style={{
            backgroundColor: hoveredItem === 'delete' ? '#D1F8F0' : '#FFF',
            padding: '8px 16px',
            color: hoveredItem === 'delete' ? '#1B2028' : '#59616E',
          }}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div
      key={team._id}
      className="rounded-md shadow-md bg-white border p-4"
      style={{
        width: '364px', // Fixed width for the card
        border: '1px solid #E8EAF6', // Light border for visibility
        borderRadius: '8px',
        overflow: 'hidden', // Ensure nothing spills out of the card
        scrollSnapAlign: 'start', // Snap to this card when scrolling
    
      }}

      onClick={() => { handleCardClick({team})
    }}
    >
      {/* Team Card Header */}
      <div className="flex justify-between items-center">
        {/* Centered User Icon */}
        <div
          className="flex justify-center items-center mx-auto"
          style={{
            background: '#FFFFFF',
            borderRadius: '8px',
            width: '48px',
            height: '48px',
            border: '1px solid #99EDDE',
          }}
        >
          <UserOutlined style={{ fontSize: '24px', color: '#4ED2BF' }} />
        </div>

        {/* Conditionally render the 3-dot menu for manager or co-manager, and leave button for regular members */}
        {(team.managerId === userId || isCoManager) ? (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            onVisibleChange={(flag) => setVisibleDropdown(flag ? team._id : null)}
            visible={visibleDropdown === team._id && !isEditModalVisible && !isDeleteModalVisible}
            placement="bottomRight"
          >
            <MoreOutlined className="text-gray-400 cursor-pointer" />
          </Dropdown>
        ) : (
          <Button
            className="text-red-500 border border-red-500"
            onClick={() => setIsLeaveModalVisible(true)}
          >
            Leave
          </Button>
        )}
      </div>

      {/* Team Information */}
      <div className="text-center mt-4">
        <h3 className="font-semibold text-lg text-[#192028]">{team.name}</h3>
        <p className="text-sm text-[#59616E]">{team.description || 'No description available'}</p>
        <p className="text-sm text-[#59616E]">
          Owner: <span className="text-[#192028]">{team.managerName}</span>
        </p>
      </div>

      {/* Members Section */}
      <div className="flex justify-center items-center mt-4 gap-4">
        <p className="font-semibold text-sm text-[#192028]">Team Size: {team.members.length}</p>
      </div>

      {/* Footer with Date and Department */}
      <div className="flex justify-between text-sm text-[#192028] mt-4">
        <p>Date: <span className="text-[#59616E]">{new Date(team.createdAt).toLocaleDateString()}</span></p>
        <p>Department: <span className="text-[#59616E]">{team?.department||"N/A"}</span></p>
      </div>


<LeaveTeamModal
  visible={isLeaveModalVisible}
  onCancel={() => setIsLeaveModalVisible(false)}
  onConfirm={() => {
    setIsLeaveModalVisible(false);
    if (onLeaveTeam) {
      onLeaveTeam(); // Trigger the refresh function
    }
  }}
  teamId={team._id}
  teamName={team.name}
/>




<EditTeamModal
  visible={isEditModalVisible}
  onClose={handleCloseEditModal}
  teamId={team._id}
  teamName={team.name}
  teamDescription={team.description}
  teamMembers={team.members}
  onEditSuccess={onEditTeam} // Pass the refresh callback
  comanagerStatus={isCoManager}
  
/>


      <DeleteTeamModal
        visible={isDeleteModalVisible}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={onDeleteSuccess}
        teamId={team._id}
      />
    </div>
  );
};

export default TeamCard;
