// import React, { useState } from "react";
// import { PushpinOutlined } from "@ant-design/icons";
// import { Avatar, message } from "antd";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { useAuth } from "../../authcontext";
// import axiosDocInstance from "../../ApiServices/AxiosDocInstance";
// import axiosVaultInstance from "../../ApiServices/AxiosVaultInstance";
// import Image from "../../../assets/SVG/JPG.svg";
// import Png from "../../../assets/SVG/PNG.svg";
// import Docs from "../../../assets/SVG/Docs.svg";
// import Pdf from "../../../assets/SVG/PDFIcon.svg";
// import Html from "../../../assets/SVG/Html.svg";
// import Excel from "../../../assets/SVG/XLSX.svg";
// import Ppt from "../../../assets/SVG/PPT.svg";
// import Misc from "../../../assets/SVG/MiscIcon.svg";

// // File type mapping to determine the appropriate icon for each document type
// const fileTypeMapping = {
//   jpg: Image,
//   jpeg: Image,
//   png: Png,
//   pdf: Pdf,
//   xls: Excel,
//   xlsx: Excel,
//   doc: Docs,
//   docx: Docs,
//   ppt: Ppt,
//   pptx: Ppt,
//   txt: Html,
//   csv: Excel,
// };

// // Function to get the appropriate icon based on the document type
// const getDocumentIcon = (docType) => {
//   const type = docType?.toLowerCase() || "";
//   return fileTypeMapping[type] || Misc; // Return the corresponding icon or a default icon if the type is not found
// };

// // DocCard Component
// const DocCard = ({ doc = {}, vault }) => {
//   const navigate = useNavigate(); // Hook for navigation
//   const { userId } = useAuth(); // Get the authenticated user's ID
//   const [bookmarkStatus, setBookmarkStatus] = useState(
//     doc?.bookmarkStatus || false
//   ); // Local state for bookmark status

//   // Function to handle clicking on a document
//   const handleDocumentClick = (docId, type) => {
//     if ( vault === "true") {
//       navigate(`/vaultdocuments/${docId}/versions`); // Navigate to the document's versions page
//     } else {
//       navigate(`/documents/${docId}/versions`);
//     }
//   };

//   // Function to format file size in KB or MB
//   const formatFileSize = (sizeInBytes) => {
//     if (!sizeInBytes) return "0 KB"; // Handle undefined size
//     if (sizeInBytes < 1024 * 1024) {
//       return `${(sizeInBytes / 1024).toFixed(2)} KB`; // Less than 1 MB, show in KB
//     }
//     return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`; // Show in MB
//   };

//   // Handle pin toggle for regular documents
//   const handlePinToggle = async (docId, currentStatus) => {
//     try {
//       const newStatus = !currentStatus;
//       await axios.patch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}&userId=${userId}`
//       );
//       setBookmarkStatus(newStatus); // Update local state
//       message.success(
//         newStatus
//           ? "The document has been successfully pinned!"
//           : "The document has been successfully unpinned!"
//       );
//     } catch (error) {
//       console.error("Error updating bookmark status", error);
//       message.error("Failed to update the bookmark status.");
//     }
//   };

//   // Handle pin toggle for vault documents
//   const handleVaultPinToggle = async (docId, currentStatus) => {
//     try {
//       const newStatus = !currentStatus;
//       await axiosDocInstance.patch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}`,
//         null,
//         {
//           headers: {
//             vaultToken: localStorage.getItem("vaultToken"),
//             SecureVault: true,
//           },
//         }
//       );
//       setBookmarkStatus(newStatus); // Update local state
//       message.success(
//         newStatus
//           ? "The document has been successfully pinned!"
//           : "The document has been successfully unpinned!"
//       );
//     } catch (error) {
//       console.error("Error updating bookmark status", error);
//       message.error("Failed to update the bookmark status.");
//     }
//   };

//   // Ensure doc and its properties are defined
//   const docName = doc?.docName || "Untitled Document";
//   const docType = doc?.docType || "Unknown Type";
//   const docSize = doc?.docSize || 0;
//   const uploadedAt = doc?.uploadedAt
//     ? new Date(doc.uploadedAt).toLocaleDateString()
//     : "Unknown Date";
//   const totalVersions = doc?.totalVersions || 0;
//   const tags = doc?.tags || [];

//   return (
//     <div
//       className="flex justify-between items-center p-4 border rounded-lg shadow-sm bg-white w-full min-w-[900px] gap-4"
//       onClick={() => handleDocumentClick(doc._id)}
//     >
//       <div className="flex items-center gap-4 w-[5%]">
//         <div className="w-[49px] h-[47px] flex justify-center items-center">
//           <img
//             src={getDocumentIcon(doc.docType)}
//             alt={doc.docType}
//             className="max-w-full"
//           />
//         </div>
//       </div>

//       <div className="flex flex-col w-[25%]">
//         <h3 className="text-base font-semibold truncate max-w-[200px]">
//           {doc.docName || "Untitled Document"}
//         </h3>
//         <p className="text-sm text-gray-500">
//           Uploaded on{" "}
//           {doc.uploadedAt
//             ? new Date(doc.uploadedAt).toLocaleDateString()
//             : "Unknown Date"}
//         </p>
//       </div>

//       <div className="flex flex-col w-[20%]">
//         <p className="text-sm font-medium text-gray-700">
//           Type: <span className="font-normal">{doc.docType || "Unknown"}</span>
//         </p>
//         <p className="text-sm font-medium text-gray-700">
//           Versions: <span>{doc.totalVersions || 0}</span>
//         </p>
//       </div>

//       <div className="flex flex-col w-[20%]">
//         <p className="text-sm font-medium text-gray-700">
//           File size:{" "}
//           <span className="font-normal">{formatFileSize(doc.docSize)}</span>
//         </p>
//         <div className="flex items-center gap-2">
//           <p className="text-sm font-medium text-gray-500">Uploaded by:</p>
//           <Avatar src="https://randomuser.me/api/portraits/thumb/women/50.jpg" />
//         </div>
//       </div>

//       <div className="flex flex-col w-[20%]">
//         <p className="text-sm font-medium text-gray-500">Tags:</p>
//         <div className="flex items-center gap-2 overflow-hidden">
//           {doc.tags?.length > 0 ? (
//             doc.tags.slice(0, 2).map((tag, index) => (
//               <span
//                 key={index}
//                 className="bg-teal-100 text-teal-500 px-2 py-1 rounded-md text-sm"
//               >
//                 #{tag}
//               </span>
//             ))
//           ) : (
//             <p className="text-gray-500 text-sm">No available tags</p>
//           )}
//           {doc.tags?.length > 2 && <span className="text-teal-500">...</span>}
//         </div>
//       </div>

//       <div
//         className={`w-8 h-8 rounded-full ${
//           bookmarkStatus ? "bg-teal-400" : "bg-gray-300"
//         } flex justify-center items-center shadow-md cursor-pointer ,`}
//         onClick={(e) => {
//           e.stopPropagation();
//           handlePinToggle(doc._id, bookmarkStatus);
//         }}
//       >
//         <PushpinOutlined className="text-white text-lg" />
//       </div>
//     </div>
//   );
// };

// export default DocCard;











import React, { useState } from "react";
import { PushpinOutlined } from "@ant-design/icons";
import { Avatar, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../authcontext";
import axiosDocInstance from "../../ApiServices/AxiosDocInstance";
import axiosVaultInstance from "../../ApiServices/AxiosVaultInstance";
import Image from "../../../assets/SVG/JPG.svg";
import Png from "../../../assets/SVG/PNG.svg";
import Docs from "../../../assets/SVG/Docs.svg";
import Pdf from "../../../assets/SVG/PDFIcon.svg";
import Html from "../../../assets/SVG/Html.svg";
import Excel from "../../../assets/SVG/XLSX.svg";
import Ppt from "../../../assets/SVG/PPT.svg";
import Misc from "../../../assets/SVG/MiscIcon.svg";

// File type mapping to determine the appropriate icon for each document type
const fileTypeMapping = {
  jpg: Image,
  jpeg: Image,
  png: Png,
  pdf: Pdf,
  xls: Excel,
  xlsx: Excel,
  doc: Docs,
  docx: Docs,
  ppt: Ppt,
  pptx: Ppt,
  txt: Html,
  csv: Excel,
};

// Function to get the appropriate icon based on the document type
const getDocumentIcon = (docType) => {
  const type = docType?.toLowerCase() || "";
  return fileTypeMapping[type] || Misc; // Return the corresponding icon or a default icon if the type is not found
};

// DocCard Component
const DocCard = ({ doc = {}, vault, onUnpinSuccess  }) => {
  const navigate = useNavigate(); // Hook for navigation
  const { userId, vaultStatus } = useAuth(); // Get the authenticated user's ID
  const [bookmarkStatus, setBookmarkStatus] = useState(
    doc?.bookmarkStatus || false
  ); // Local state for bookmark status

  // Function to handle clicking on a document
  const handleDocumentClick = (docId, type) => {
    if ( vault === "true") {
      navigate(`/vaultdocuments/${docId}/versions`); // Navigate to the document's versions page
    } else {
      navigate(`/documents/${docId}/versions`);
    }
  };

  

  // Function to format file size in KB or MB
  const formatFileSize = (sizeInBytes) => {
    if (!sizeInBytes) return "0 KB"; // Handle undefined size
    if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`; // Less than 1 MB, show in KB
    }
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`; // Show in MB
  };

 // Handle pin toggle for regular documents
const handlePinToggle = async (docId, currentStatus) => {
  try {
    const newStatus = !currentStatus;
    await axios.patch(
      `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}&userId=${userId}`
    );
    setBookmarkStatus(newStatus); // Update local state

    message.success(
      newStatus
        ? "The document has been successfully pinned!"
        : "The document has been successfully unpinned!"
    );

    // Trigger refresh if the document was unpinned
    if (!newStatus && typeof onUnpinSuccess === "function") {
      onUnpinSuccess();
    }
  } catch (error) {
    console.error("Error updating bookmark status", error);
    message.error("Failed to update the bookmark status.");
  }
};


  // Handle pin toggle for vault documents
  const handleVaultPinToggle = async (docId, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axiosDocInstance.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}`,
        null,
        {
          headers: {
            vaultToken: localStorage.getItem("vaultToken"),
            SecureVault: true,
          },
        }
      );
      setBookmarkStatus(newStatus); // Update local state
      message.success(
        newStatus
          ? "The document has been successfully pinned!"
          : "The document has been successfully unpinned!"
      );
      if (!newStatus && typeof onUnpinSuccess === "function") {
        onUnpinSuccess();
      }
    } catch (error) {
      console.error("Error updating bookmark status", error);
      message.error("Failed to update the bookmark status.");
    }
  };

  // Ensure doc and its properties are defined
  const docName = doc?.docName || "Untitled Document";
  const docType = doc?.docType || "Unknown Type";
  const docSize = doc?.docSize || 0;
  const uploadedAt = doc?.uploadedAt
    ? new Date(doc.uploadedAt).toLocaleDateString()
    : "Unknown Date";
  const totalVersions = doc?.totalVersions || 0;
  const tags = doc?.tags || [];

  return (
    <div
      className="flex justify-between items-center p-4 border rounded-lg shadow-sm bg-white w-full min-w-[900px] gap-4"
      onClick={() => handleDocumentClick(doc._id)}
    >
      <div className="flex items-center gap-4 w-[5%]">
        <div className="w-[49px] h-[47px] flex justify-center items-center">
          <img
            src={getDocumentIcon(doc.docType)}
            alt={doc.docType}
            className="max-w-full"
          />
        </div>
      </div>

      <div className="flex flex-col w-[25%]">
        <h3 className="text-base font-semibold truncate max-w-[200px]">
          {doc.docName || "Untitled Document"}
        </h3>
        <p className="text-sm text-gray-500">
          Uploaded on{" "}
          {doc.uploadedAt
            ? new Date(doc.uploadedAt).toLocaleDateString()
            : "Unknown Date"}
        </p>
      </div>

      <div className="flex flex-col w-[20%]">
        <p className="text-sm font-medium text-gray-700">
          Type: <span className="font-normal">{doc.docType || "Unknown"}</span>
        </p>
        <p className="text-sm font-medium text-gray-700">
          Versions: <span>{doc.totalVersions || 0}</span>
        </p>
      </div>

      <div className="flex flex-col w-[20%]">
        <p className="text-sm font-medium text-gray-700">
          File size:{" "}
          <span className="font-normal">{formatFileSize(doc.docSize)}</span>
        </p>
        <div className="flex items-center gap-2">
          <p className="text-sm font-medium text-gray-500">Uploaded by:</p>
          <Avatar src="https://randomuser.me/api/portraits/thumb/women/50.jpg" />
        </div>
      </div>

      <div className="flex flex-col w-[20%]">
        <p className="text-sm font-medium text-gray-500">Tags:</p>
        <div className="flex items-center gap-2 overflow-hidden">
          {doc.tags?.length > 0 ? (
            doc.tags.slice(0, 2).map((tag, index) => (
              <span
                key={index}
                className="bg-teal-100 text-teal-500 px-2 py-1 rounded-md text-sm"
              >
                #{tag}
              </span>
            ))
          ) : (
            <p className="text-gray-500 text-sm">No available tags</p>
          )}
          {doc.tags?.length > 2 && <span className="text-teal-500">...</span>}
        </div>
      </div>

      <div
        className={`w-8 h-8 rounded-full ${
          bookmarkStatus ? "bg-teal-400" : "bg-gray-300"
        } flex justify-center items-center shadow-md cursor-pointer ,`}
        onClick={(e) => {
          e.stopPropagation();
          vaultStatus?handleVaultPinToggle(doc._id, bookmarkStatus): handlePinToggle(doc._id, bookmarkStatus) ;
        }}
      >
        <PushpinOutlined className="text-white text-lg" />
      </div>
    </div>
  );
};

export default DocCard;
