import axios from 'axios';

const axiosVaultInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DOC_SERVICE_URL}`,
});

axiosVaultInstance.interceptors.request.use(
  (config) => {
    const vaultToken = localStorage.getItem('vaultToken')
 
    if (vaultToken) {
        config.headers.Authorization = `Bearer ${vaultToken}`; // Assuming the API expects it under this header key
      }
    if (config.baseURLOverride) {
      config.baseURL = config.baseURLOverride;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosVaultInstance.interceptors.response.use(
  (response) => response,
  (error) => {
   
    return Promise.reject(error);
  }
);

export default axiosVaultInstance;

