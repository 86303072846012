// import React, { useState, useEffect } from 'react';
// import { Modal, Form, Input, Button, notification } from 'antd';
// import { EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
// import axios from 'axios';

// const EditDetails = ({ folder, isEditModalVisible, setEditModalVisible, onUpdate }) => {
//   const [folderDetails, setFolderDetails] = useState({
//     name: folder.name || '',
//     description: folder.description || '',
//     tags: folder.tags || []
//   });
//   const [originalDetails, setOriginalDetails] = useState({});
//   const [newTag, setNewTag] = useState('');

//   useEffect(() => {
//     setFolderDetails({
//       name: folder.name || '',
//       description: folder.description || '',
//       tags: folder.tags || []
//     });
//     setOriginalDetails({
//       name: folder.name || '',
//       description: folder.description || '',
//       tags: folder.tags || []
//     });
//   }, [folder]);

//   const folderDetailsUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folder._id}`;

//   const handleEditDetails = async () => {
//     const updatedFields = {};

//     if (folderDetails.name !== originalDetails.name) {
//       updatedFields.name = folderDetails.name;
//     }
//     if (folderDetails.description !== originalDetails.description) {
//       updatedFields.description = folderDetails.description;
//     }
//     if (JSON.stringify(folderDetails.tags) !== JSON.stringify(originalDetails.tags)) {
//       updatedFields.tags = {
//         addTags: folderDetails.tags
//       };
//     }

//     if (Object.keys(updatedFields).length === 0) {
//       notification.info({ message: 'No changes made' });
//       return;
//     }

//     try {
//       await axios.patch(folderDetailsUrl, updatedFields);
//       notification.success({ message: 'Folder details updated successfully' });

//       // Pass the updated details back to FolderCard
//       onUpdate(updatedFields);

//       setEditModalVisible(false);
//     } catch (error) {
//       notification.error({ message: 'Failed to update folder details' });
//     }
//   };

//   const addTag = () => {
//     if (newTag.trim() && !folderDetails.tags.includes(newTag.trim())) {
//       setFolderDetails({ ...folderDetails, tags: [...folderDetails.tags, newTag.trim()] });
//       setNewTag('');
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     setFolderDetails({
//       ...folderDetails,
//       tags: folderDetails.tags.filter((tag) => tag !== tagToRemove),
//     });
//   };

//   return (
//     <div onClick={(e) => e.stopPropagation()}>
//       <Modal
//         title={
//           <div className="flex items-center text-2xl font-semibold">
//             {folderDetails.name || 'XYZ Folder'}
//             <EditOutlined className="ml-2 text-xl text-gray-400" />
//           </div>
//         }
//         open={isEditModalVisible}
//         onCancel={() => setEditModalVisible(false)}
//         footer={null}
//         className="edit-folder-modal"
//       >
//         <Form layout="vertical" onFinish={handleEditDetails}>
//           {/* Folder Name */}
//           <Form.Item label="Folder Name" className="text-sm text-gray-500">
//             <Input
//               value={folderDetails.name}
//               onChange={(e) => setFolderDetails({ ...folderDetails, name: e.target.value })}
//               maxLength={20}
//               className="rounded-lg border border-gray-300 p-2"
//             />
//             <div className="text-right text-xs text-gray-400">
//               {`${folderDetails.name?.length || 0}/20`}
//             </div>
//           </Form.Item>

//           {/* Description */}
//           <Form.Item label="Description (Optional)" className="text-sm text-gray-500">
//             <Input.TextArea
//               value={folderDetails.description}
//               onChange={(e) => setFolderDetails({ ...folderDetails, description: e.target.value })}
//               maxLength={50}
//               className="rounded-lg border border-gray-300 p-2"
//             />
//             <div className="text-right text-xs text-gray-400">
//               {`${folderDetails.description?.length || 0}/50`}
//             </div>
//           </Form.Item>

//           {/* Tags */}
//           <Form.Item label="Tags" className="text-sm text-gray-500">
//             <div className="flex gap-2">
//               <Input
//                 placeholder="Enter tag"
//                 value={newTag}
//                 onChange={(e) => setNewTag(e.target.value)}
//                 onPressEnter={addTag}
//                 className="rounded-lg border border-gray-300 p-2 flex-grow"
//               />
//               <Button type="primary" icon={<PlusOutlined />} onClick={addTag} className="bg-teal-500 text-white">
//                 Add Tag
//               </Button>
//             </div>

//             {folderDetails.tags.length > 0 && (
//               <div className="mt-2 flex flex-wrap gap-2">
//                 {folderDetails.tags.map((tag, index) => (
//                   <span
//                     key={index}
//                     className="bg-teal-100 text-teal-600 px-2 py-1 rounded-md flex items-center gap-1"
//                   >
//                     {`#${tag}`}
//                     <CloseOutlined
//                       className="cursor-pointer text-gray-500 text-xs"
//                       onClick={() => removeTag(tag)}
//                     />
//                   </span>
//                 ))}
//               </div>
//             )}
//           </Form.Item>

//           {/* Buttons */}
//           <div className="flex justify-end gap-2 mt-4">
//             <Button
//               onClick={() => setEditModalVisible(false)}
//               className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
//             >
//               Close
//             </Button>
//             <Button
//               type="primary"
//               htmlType="submit"
//               className="bg-teal-500 text-white px-4 py-2 rounded-md"
//             >
//               Save
//             </Button>
//           </div>
//         </Form>
//       </Modal>
//     </div>
//   );
// };

// export default EditDetails;








import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';


const EditDetails = ({ folder, isEditModalVisible, setEditModalVisible, onUpdate }) => {
  const [folderDetails, setFolderDetails] = useState({
    name: folder.name || '',
    description: folder.description || '',
    tags: folder.tags || []
  });
 
  const [originalDetails, setOriginalDetails] = useState({});
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    setFolderDetails({
      name: folder.name || '',
      description: folder.description || '',
      tags: folder.tags || []
    });
    setOriginalDetails({
      name: folder.name || '',
      description: folder.description || '',
      tags: folder.tags || []
    });
  }, [folder]);

  const folderDetailsUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folder._id}`;

  const handleEditDetails = async () => {
    const updatedFields = {};

    if (folderDetails.name !== originalDetails.name) {
      updatedFields.name = folderDetails.name;
    }
    if (folderDetails.description !== originalDetails.description) {
      updatedFields.description = folderDetails.description;
    }

    if (Object.keys(updatedFields).length === 0) {
      message.info('No changes made');
      return;
    }

    try {
      await axios.patch(folderDetailsUrl, updatedFields);
      
      message.success('Folder details updated successfully');

      onUpdate(updatedFields);

      setEditModalVisible(false);
    } catch (error) {
      message.error('Failed to update folder details');
    }
  };

  const addTag = () => {
    const trimmedTag = newTag.trim();
    if (trimmedTag && !folderDetails.tags.includes(trimmedTag)) {
      const updatedTags = [...folderDetails.tags, trimmedTag];
      setFolderDetails({ ...folderDetails, tags: updatedTags });

      axios
        .patch(folderDetailsUrl, { tags: { addTags: [trimmedTag] } })
        .then(() => {
          message.success(`Tag "${trimmedTag}" added successfully`);
        })
        .catch(() => {
          message.error(`Failed to add tag "${trimmedTag}"`);
          setFolderDetails({ ...folderDetails, tags: folderDetails.tags });
        });

      setNewTag('');
    }
  };

  const removeTag = async (tagToRemove) => {
    const updatedTags = folderDetails.tags.filter((tag) => tag !== tagToRemove);
    setFolderDetails({ ...folderDetails, tags: updatedTags });

    try {
      await axios.patch(folderDetailsUrl, { tags: { removeTags: [tagToRemove] } });
      message.success(`Tag "${tagToRemove}" removed successfully`);
      onUpdate({ tags: updatedTags });
    } catch (error) {
      message.error(`Failed to remove tag "${tagToRemove}"`);
      setFolderDetails({ ...folderDetails, tags: folderDetails.tags });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        title={
          <div className="flex items-center text-2xl font-semibold">
            {folderDetails.name || 'XYZ Folder'}
            <EditOutlined className="ml-2 text-xl text-gray-400" />
          </div>
        }
        open={isEditModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
        className="edit-folder-modal"
      >
        <Form layout="vertical" onFinish={handleEditDetails}>
          <Form.Item label="Folder Name" className="text-sm text-gray-500">
            <Input
              value={folderDetails.name}
              onChange={(e) => setFolderDetails({ ...folderDetails, name: e.target.value })}
              maxLength={20}
              className="rounded-lg border border-gray-300 p-2"
            />
            <div className="text-right text-xs text-gray-400">
              {`${folderDetails.name?.length || 0}/20`}
            </div>
          </Form.Item>

          <Form.Item label="Description (Optional)" className="text-sm text-gray-500">
            <Input.TextArea
              value={folderDetails.description}
              onChange={(e) => setFolderDetails({ ...folderDetails, description: e.target.value })}
              maxLength={50}
              className="rounded-lg border border-gray-300 p-2"
            />
            <div className="text-right text-xs text-gray-400">
              {`${folderDetails.description?.length || 0}/50`}
            </div>
          </Form.Item>

          <Form.Item label="Tags" className="text-sm text-gray-500">
            <div className="flex gap-2">
              <Input
                placeholder="Enter tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onPressEnter={addTag}
                className="rounded-lg border border-gray-300 p-2 flex-grow"
              />
              <Button type="primary" icon={<PlusOutlined />} onClick={addTag} className="bg-teal-500 text-white">
                Add Tag
              </Button>
            </div>

            {folderDetails.tags.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {folderDetails.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-teal-100 text-teal-600 px-2 py-1 rounded-md flex items-center gap-1"
                  >
                    {`#${tag}`}
                    <CloseOutlined
                      className="cursor-pointer text-gray-500 text-xs"
                      onClick={() => removeTag(tag)}
                    />
                  </span>
                ))}
              </div>
            )}
          </Form.Item>

          <div className="flex justify-end gap-2 mt-4">
            <Button onClick={() => setEditModalVisible(false)} className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md">
              Close
            </Button>
            <Button type="primary" htmlType="submit" className="bg-teal-500 text-white px-4 py-2 rounded-md">
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default EditDetails;
