import { useState, useEffect } from "react";
import axios from "axios";

/**
 * Custom hook for debouncing and fetching paginated results based on search term.
 * @param {string} term - The term to debounce and fetch data for.
 * @param {number} page - The current page number for pagination.
 * @param {function} setPage - Function to update the page number.
 * @param {number} delay - The debounce delay in milliseconds (default: 500ms).
 * @param {string} isTeam - Indicates if the search is for teams or generic users.
 * @returns {Object} - An object containing the debounced term, results, loading state, fetchMore function, and moreToCome flag.
 */



const useDebounceFetch = (term = "a", page, setPage, delay = 500, isTeam) => {
  const [debouncedTerm, setDebouncedTerm] = useState(term.trim());
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moreToCome, setMoreToCome] = useState(true);

  // Debounce logic for the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(term.trim());
    }, delay);

    return () => clearTimeout(handler);
  }, [term, delay]);

  // Fetch data when debounced term or page changes
  useEffect(() => {
    const fetchData = async () => {
      if (!debouncedTerm || loading) return;

      setLoading(true);
      try {
        const searchTerm = debouncedTerm === "" ? "a" : debouncedTerm;
        const apiUrl =
          isTeam === "teams"
            ? `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/search?term=${encodeURIComponent(searchTerm)}${page > 0 ? `&page=${page}` : ""}`
            : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/search?term=${encodeURIComponent(searchTerm)}${page > 0 ? `&page=${page}` : ""}`;

        const response = await axios.get(apiUrl);

        // Add entityType to results
        const newData = (isTeam === "teams" ? response.data?.data?.teams : response.data?.data?.users) || [];

        const enrichedData = newData.map((item) => ({
          ...item,
          entityType: isTeam === "teams" ? "Team" : "User", // Add entity type
        }));

        setResults((prevResults) => (page === 0 ? enrichedData : [...prevResults, ...enrichedData]));
        setMoreToCome(response.data?.data?.moreToCome ?? false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedTerm, page, isTeam]);

  // Reset results and pagination when term changes
  useEffect(() => {
    if (debouncedTerm !== term.trim()) {
      setResults([]);
      setPage(0);
      setMoreToCome(true);
    }
  }, [debouncedTerm, term, setPage]);

  // Function to fetch the next page of results
  const fetchMore = () => {
    if (!loading && moreToCome) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return { debouncedTerm, results, loading, fetchMore, moreToCome };
};

export default useDebounceFetch;
